import http from "../utils/request"



// 查看证书图片
export  function getCertificateImg(data = {}){
    return http({
         method:'GET',
         url:'/file/getCertificateImg/',
         params:data
     })
 }

// 批量查看证书图片
export  function getBatchCertificateImg(data = {}){
    return http({
         method:'POST',
         url:'/file/getBatchCertificateImgs/',
         data
     })
 }