<template>
  <div style="height: 100%;">
    <el-table
      :data="LeaveMessageList"
      style="width: 100%"
      height="90%"
      @selection-change="handleSelectionChange"
    >
    <el-table-column
      type="selection"
      width="45">
    </el-table-column>
      <el-table-column prop="name"  label="称呼" :formatter="nameFormat" width="100px" ></el-table-column>
      <el-table-column prop="userName"  label="系统名" :formatter="userNameFormat" width="100px"> </el-table-column>
      <el-table-column prop="contactInformation" label="联系方式" :formatter="contactFormat" width="220px"> </el-table-column>
      <el-table-column prop="email" label="邮箱" :formatter="emailFormat" width="220px">
      </el-table-column>
      <el-table-column prop="message" label="留言信息" width="480px">
        <template slot-scope="scope">
          <div class="message-cell">{{ scope.row.message }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" :sortable=true label="留言时间" width="220px">
      </el-table-column>
      <el-table-column  label="操作" width="270"  fixed="right">
        <!-- slot-scope="scope" 定义了插槽的作用域时 在插槽内部就可以通过 scope 对象来访问到父组件中的数据 这个scope 对象是vue传来的  -->
        <template slot-scope="scope">
          <el-button style="" size="mini" @click="handleCheck(scope.row)" >查看</el-button>
          <el-button type="warning" plain style="" size="mini" @click="handleSwitchState(scope.row)">{{ scope.row.userState === '1' ? '置顶' : '取消置顶' }}</el-button>
          <el-button type="danger" size="mini" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex;justify-content: flex-end;align-items: center;"> 
      <span>共{{total}}条</span>
      <!-- <el-pagination background layout="prev, pager, next" :current-page="currentPage" :page-size="pageSize" :total="total" @current-change="handerPage"> -->
    </el-pagination>
   </div>
   <!-- 查看留言详情弹窗 -->
   <el-dialog  title="查看留言" :visible.sync="LeaveMessageDialogVisible"  width="900px" >
            <!-- <div class="showLeaveMessage"> -->
              <!-- 留言信息 -->
              <ul class="showLeaveMessage">
                <span style="text-align: center">留言信息&emsp;&emsp;{{ LeaveMessageItem.createTime }}</span>
            <li>称呼：{{ LeaveMessageItem.name }}</li>
            <li>手机号：{{ LeaveMessageItem.contactInformation }}</li>
            <li>邮箱：{{ LeaveMessageItem.email }}</li>
            <li>留言信息：<br/>&emsp;&emsp;{{ LeaveMessageItem.message }}</li>
          </ul>
          <!-- 回复信息 -->
          <ul v-for="item in replyLeaveMessageList" :key="item.id" class="showReplyMessage">
                <span style="text-align: center">回复信息&emsp;&emsp;{{ item.createTime }}</span>
            <li>回复人：{{ item.userName }}</li>
            <li>回复主题：{{ item.subject }}</li>
            <li>回复信息：<br/>&emsp;&emsp;{{ item.message }}</li>
          </ul>
        <el-form :class="LeaveMessageItem.email ? '' : 'disabledState'" :model="sendMessageForm" ref="sendMessageForm" :rules="rules" class="sendMessageForm ">
          <span>若有客户邮箱信息，可在此回复留言</span>
          <el-form-item style="width: 100%;" label="邮件主题:">
            <el-input v-model="sendMessageForm.subject" style="width: 97%;font-size: 18px;" />
          </el-form-item>
          <el-form-item style="width: 100%;margin: 0" label="留言内容：" prop="replyMessage" >
            <textarea ref="sendMessageTextarea" class="leave-message-textarea" @blur="verifyMessage" v-model="sendMessageForm.replyMessage" placeholder="在此输入回复留言信息，将通过公司邮件形式发送给客户..."></textarea>
          </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelSend()">取 消</el-button>
        <el-button :class="LeaveMessageItem.email ? '' : 'disabledState'" type="primary" @click="sendMessage">发 送</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {queryLeaverMessageList,replyMessage,queryReplyMessageList} from "@/api/leaver_message"
export default {
  data(){
    return{
      LeaveMessageList:[],//留言列表
      replyLeaveMessageList:[],//回复留言列表
      total:'',//总条数
      pageSize:10,//每页显示的条数
      currentPage:1,//当前页数
      LeaveMessageDialogVisible:false,//查看留言详情弹窗
      LeaveMessageItem:{},
      replyLeaveMessage:'',//回复客户留言的信息
      hasEmail:false,//留言信息是否含有邮箱信息
      
      sendMessageForm:{
        subject:'【江西省华旭工程咨询有限公司-回复邮件】',//邮箱主题
        replyMessage:'',//向客户邮箱发送的信息
        email:'',//客户的邮箱
      },
      rules:{
				replyMessage: [{ required: true, message: '请输入留言内容',  trigger: 'blur' }],
			}
    }
  },

  mounted(){
    queryLeaverMessageList().then((response) =>{
      if(response.data.success){
        this.LeaveMessageList = response.data.data
      }
      console.log(response.data)
    })
  },
  watch:{
    // 监听数值变化，弹窗变化，即把这些属性回复默认
    LeaveMessageDialogVisible(){
      // this.$refs.sendMessageTextarea 先检查它是否存在。这样可以避免在组件生命周期早期访问未定义的引用而导致的错误。
      if (this.$refs.sendMessageTextarea) {
      this.sendMessageForm.replyMessage = ''
      this.sendMessageForm.subject = '【江西省华旭工程咨询有限公司-回复邮件】'
      this.$refs.sendMessageTextarea.style.border = '1px solid #000';
    }
    }
  },
  methods:{
    // table列表数据格式化
    nameFormat(row){
      return row.name ? row.name : '-'
  },
  userNameFormat(row){
      return row.userName ? row.userName : '-'
  },
  contactFormat(row){
      return row.contactInformation ? row.contactInformation : '-'
  },
  emailFormat(row){
      return row.email ? row.email : '-'
  },
    // },

    // 验证发送消息输入框是否输入信息
		verifyMessage(){
      console.log(this.sendMessageForm.replyMessage)
			if(this.sendMessageForm.replyMessage == ''){
				this.$refs.sendMessageTextarea.style.border = '1px solid red';
			}else{
				this.$refs.sendMessageTextarea.style.border = '1px solid #000';
			}
		},
   //根据留言id 查找回复留言列表
   queryReplyMessageListFun(id){
      console.log("回复信息列表")
      queryReplyMessageList({leaveMessageId:id}).then((response) =>{
        this.replyLeaveMessageList = response.data.data
        // console.log(response.data)
      })
    },
    // 取消按钮
    cancelSend(){
      this.LeaveMessageDialogVisible = false
      this.sendMessageForm.sendMessage = ''
      this.$refs.sendMessageTextarea.style.border = '1px solid #000'
    },
    // 发送回复信息
    sendMessage(){
      this.$refs.sendMessageForm.validate((valid) =>{
        if(valid){
          console.log(this.LeaveMessageItem)
          replyMessage({replyMessage:this.sendMessageForm.replyMessage,
                        leaveMessageId:this.LeaveMessageItem.id,
                        email:this.LeaveMessageItem.email,
                        subject:this.sendMessageForm.subject}).then((response) =>{
                          if(response.data.success){
                            this.$notify({
                                title: '成功!',
                                message: response.data.message,
                                type: 'success'
                              });
                              this.queryReplyMessageListFun(this.LeaveMessageItem.id)
                          }else{
                            this.$notify({
                              title: '失败!',
                              message: response.data.message,
                              type: 'warning'
                            });
                          }
                        })
        }
      })
    },

 

    // 查看留言详情
    handleCheck(row){
      this.LeaveMessageItem = row
      this.LeaveMessageDialogVisible = true
      console.log(this.LeaveMessageItem)
      this.queryReplyMessageListFun(this.LeaveMessageItem.id)
    },
    // 复选框点击时触发
    handleSelectionChange(){

    },
       // 分页点击时执行 val为页码
       handerPage(val) {
      // this.queryUsrtListFun(this.pageSize,val,this.selectForm.userName,this.selectForm.userRole,this.selectForm.userState);
      console.log(val);
    },
  }
}
</script>

<style>
.disabledState{
  opacity: 0.4;
  cursor:not-allowed;
  pointer-events: none;
}
/* .disabledState:hover{
  pointer-events: none;
} */
.message-cell {
  width: 100%;
  white-space: pre-line; /* 保留换行符 */
  overflow: hidden;
  text-overflow: ellipsis;/* 超出内容用省略号显示 */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 控制显示的行数 */
  -webkit-box-orient: vertical;
}

.showLeaveMessage{
  width: 96.5%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #efecec;
  
  border-radius: 10px;
  padding: 15px;
}

.showReplyMessage{
  width: 96.5%;
  margin-top: 40px;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #c9e7ff;
  
  border-radius: 10px;
  padding: 15px;
}
.showReplyMessage li{
  width: 100%;
  margin-top: 20px;
  font-size: 18px;
  text-align: left;
  line-height: 30px;
  /* background-color: aquamarine; */
}
.showLeaveMessage li{
  width: 100%;
  margin-top: 20px;
  font-size: 18px;
  text-align: left;
  line-height: 30px;
  /* background-color: aquamarine; */
}
.sendMessageForm{
	/* height: 100%; */
	padding-right: 10px;
  margin-top: 30px;
	width: 100%;
	background-color: #fff;
  /*   */
  
	/* display: flex;
	justify-content: center;
	flex-wrap: wrap; */
}

.leave-message-textarea::-webkit-scrollbar {
    width: 5px; /* 滚动条宽度 */
    height: 4px; /* 滚动条高度 */
  }
.leave-message-textarea{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
	width: 96%;
	min-height: 200px;
	line-height: 20px;
    /* border: none;  */
    padding: 5px; 
    resize: none; 
    overflow: auto;
	border-radius: 5px;
}
</style>