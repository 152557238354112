import http from "../utils/request"



// 查询当前用户的邮寄信息
export  function queryPostInfoRequest(data = {}){
    return http({
         method:'GET',
         url:'/post_info/getPostInfo/',
         params:data
     })
 }

 // 新增邮寄信息
export  function addPostInfoRequest(data){
    return http({
         method:'POST',
         url:'/post_info/addPostInfo/',
         data:data
     })
 }

  // 删除邮寄信息
export  function deletePostInfoRequest(data = {}){
    return http({
         method:'GET',
         url:'/post_info/deletePostInfo/',
         params:data
     })
 }