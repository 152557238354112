import http from "../utils/request"


// 获取前台首页数据
export function getHomeData(data = {}){
    return http({
        method:'GET',
        url:'/client/getHomeData',
        params:data
    })

}
