<template>
  <div class="article-box">
    <div class="left-navigation">
      <ul>
        <!-- 侧边栏第一个标签不需要绑定点击事件 -->
        <li v-for="(item,index) in navigationList" :key="item.labelId" 
         @click="index !== 0 ? showAsideContant(item,index) : null"
         :class="{ 'clicked': selectedIndex == item.labelId }">{{ item.labelName }} </li>
      </ul>
    </div>
    <div class="right-article-box">
      <div  v-if="navigationList.length > 0" class="right-box-header"><span class="right-title">{{headLabelName}}</span></div>
      <keep-alive>
      <router-view></router-view>
    </keep-alive>
    </div>
  </div>
</template>

<script>
import {queryLabels,queryLabelDetail} from "@/api/aside_label"
export default {
  data() {
    return {
      cateId: this.$route.query.cateId,
      navigationList: [
      ],
      headLabelName:'',
      selectedIndex: null,
      // navigation:[{labelId:1,labelName:'a'},{labelId:2,labelName:'b'},{labelId:3,labelName:'c'}]
    };
  },
  watch: {
    '$route.query': {
      handler: 'fetchAsideData',
      deep: true
    }
  },
  methods:{
    // 监听$route.query参数发生变化触发的函数
    fetchAsideData(){
      this.initLabels()
    },
    initLabels(){
      let labelType = this.$route.query.labelType;
      let labelId = this.$route.query.labelId;
      // 根据labelType查询侧边标签
    queryLabels({labelType:labelType}).then((response) =>{
      if(response.data.success){
       this.navigationList = response.data.data
       const foundItem = this.navigationList.find(item => item.labelId === labelId)
      this.headLabelName = '详细信息'
      if(foundItem.labelName){
        this.headLabelName = foundItem.labelName
      }
    this.selectedIndex = this.$route.query.labelId
      }

    })

    },
    // 侧边栏点击 展示相应内容======================
    // showAsideContant(item){
    //   this.headLabelName = item.labelName
    //   this.selectedIndex = item.labelId;
      // 进行判断，如果srote里面有当前记录则不发请求，直接从businessArr取值给businessitem就行，然后前端再调用businessitem
      // queryLabelDetail({labelType:item.labelType,labelId:item.labelId}).then((response) =>{
      //   console.log("发出请求")
        // 将请求来的数据保存到store，以便子路由使用
    //     if(response.data.success){
    //       switch(item.labelType){
    //         case 1:
    //         this.$store.commit("setBusinessArrData",response.data.data)
    //           break;
    //         case 2:
    //           break;
    //         case 3:
    //         case 4:
    //         this.$store.commit("setArticleArrData",response.data.data)
    //           break;
    //         default:
    //       }
    //     }
    //     console.log("侧边栏点击 展示相应内容"+item.labelId)
    //     console.log(response.data.data)
    //   })
    // }
    
    // 侧边栏点击 展示相应内容======================
    showAsideContant(item){
      this.headLabelName = item.labelName
      this.selectedIndex = item.labelId;
      let routePath = {}
      switch(item.labelType){
        case 1:
            routePath = { name: 'clientBusinessDetail', query: { labelType:item.labelType,labelId:item.labelId } }
            this.$router.replace(routePath);
         break;
        case 2:
            routePath = { name: 'clientCaseArticleList', query: { labelType:item.labelType,labelId:item.labelId } }
            this.$router.replace(routePath);
          break;
        case 3:
        case 4:
          routePath = { name: 'clientArticleList', query: { labelType:item.labelType,labelId:item.labelId } }
          this.$router.replace(routePath);
         break;
      }
      // if(labelId){
      //   // this.headLabelName = item.labelName
      // this.selectedIndex = labelId;
      //       // 进行判断，如果srote里面有当前记录则不发请求，直接从businessArr取值给businessitem就行，然后前端再调用businessitem
      //       queryLabelDetail({labelType:labelType,labelId:labelId}).then((response) =>{
      //   console.log("发出请求")
      //   // 将请求来的数据保存到store，以便子路由使用
      //   if(response.data.success){
      //     switch(labelType){
      //       case 1:
      //       this.$store.commit("setBusinessArrData",response.data.data)
      //       localStorage.setItem("BusinessArrData",JSON.stringify(response.data.data))
      //         break;
      //       case 2:
      //         break;
      //       case 3:
      //       case 4:
      //       this.$store.commit("setArticleArrData",response.data.data)
      //       localStorage.setItem("ArticleArrData",JSON.stringify(response.data.data))
      //         break;
      //       default:
      //     }
      //   }
      // })
      // }else{
      //         // 进行判断，如果srote里面有当前记录则不发请求，直接从businessArr取值给businessitem就行，然后前端再调用businessitem
      // queryLabelDetail({labelType:labelType}).then((response) =>{
      //   console.log("发出请求")
      //   // 将请求来的数据保存到store，以便子路由使用
      //   if(response.data.success){
      //     switch(labelType){
      //       case 1:
      //       this.$store.commit("setBusinessArrData",response.data.data)
      //       localStorage.setItem("BusinessArrData",JSON.stringify(response.data.data))
      //         break;
      //       case 2:
      //         break;
      //       case 3:
      //       case 4:
      //       this.$store.commit("setArticleArrData",response.data.data)
      //       localStorage.setItem("ArticleArrData",JSON.stringify(response.data.data))
      //         break;
      //       default:
      //     }
      //   }
      // })
      // }


    }
  },
   // 父路由使用了<keep-alive></keep-alive> 所以必须使用activated钩子当前数据才会更新数据
  // 当组件被<keep-alive>激活时，会触发activated生命周期钩子
    activated(){
      this.initLabels()
  },
  mounted(){
    this.selectedIndex = this.$route.query.labelId
  }
};
</script>

<style>
.article-box {
  margin-top: 10px;
  width: 100%;
  display: flex;
}
.left-navigation ul{
  width: 220px;
  border: 1px solid #375eab;
}
.left-navigation li {
  transition: background-color 0.3s;
  width: 100%;
  /* text-align: center; */
  border-top:1px solid #74a1f946;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.clicked{
  background-color: #95b6f869;
}
.left-navigation li:hover{
  background-color: #95b6f838;
}
.left-navigation li:nth-child(1) {
  background-color: #375eab;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}
.right-article-box {
  margin-left: 30px;
  width: 100%;
  min-height: 500px;
}
.right-box-header {
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-bottom:1px solid #375eabc2;
}
.right-title{
  color: #375eab;
}
</style>