import http from "../utils/request"

    // 查根据文章类型询文章列表
    export function queryArticleListByCateId(data){
        return http({
            method:'GET',
            url:'/client_article/queryArticleListByCateId',
            params:data
    })
}
      // 查根据文章id查询文章详情
      export function queryArticleInfoById(data){
        return http({
            method:'GET',
            url:'/client_article/queryArticleInfo',
            params:data
    })
}



 // 查根据文章id查询案例文章详情
 export function queryCaseArticleInfoById(data){
    return http({
        method:'GET',
        url:'/client_article/queryCaseArticleInfo',
        params:data
})
}

// 分页查询文章列表
export function queryArticleList(){
    
}