<template>
  <div style="height: 100%; width: 100%">
    <el-table :data="applyData" style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="props">
          <div class="apply-detail-info">
            <div class="section audit-section">
              <!-- <div style="height: 100%;"> -->
              <div class="section-title">审核信息</div>
              <div
                class="audit-item"
                v-for="processPath in props.row.processPathList"
                :key="processPath.processPathId"
              >
                <div>
                  <div class="audit-unit">
                    <span>{{ processPath.approval.approvalUnit }}</span>
                  </div>
                  <div
                    class="audit-persion"
                    v-if="processPath.approval.approvalState !== '0'"
                  >
                    <p>
                      <span>审核人：</span>{{ processPath.approval.userName }}
                    </p>
                    <p>
                      <span>审核时间：</span
                      >{{ processPath.approval.createTime }}
                    </p>
                    <p>
                      <span>审核意见：</span>{{ processPath.approval.opinion }}
                    </p>
                  </div>
                </div>

                <div class="aduit-stateimg">
                  <img :src="getStateimg(processPath.approval.approvalState)" />
                </div>
              </div>
              <!-- </div> -->
            </div>
            <!-- <span class="getMore"
              >展开更多&nbsp;<i class="el-icon-arrow-down"></i
            ></span> -->

            <div class="section">
              <div class="section-title" style="display: inline">收件信息</div>
              <span class="copy-btn" @click="copyAddress(props.row.postInfo)"
                ><i class="el-icon-document-copy"></i>&nbsp;复制地址</span
              >
              <div class="info-item">
                <i class="el-icon-user"></i>
                收件人: {{ props.row.postInfo.userName }}
              </div>
              <div class="info-item">
                <i class="el-icon-mobile-phone"></i>
                手机号: {{ props.row.postInfo.userPhone }}
              </div>
              <div class="info-item">
                <i class="el-icon-location-outline"></i>
                所在地区: {{ props.row.postInfo.province }}
                {{ props.row.postInfo.city }} {{ props.row.postInfo.street }}
              </div>
              <div class="info-item">
                <i class="el-icon-office-building"></i>
                详细地址:
                {{ props.row.postInfo.detailAddress }}
              </div>
              <div class="info-item">
                <i class="el-icon-tickets"></i>
                备注: {{ props.row.remark }}
              </div>
            </div>
            <div class="section">
              <div class="section-title">证书信息</div>
              <div
                v-for="certificate in props.row.certificateList"
                :key="certificate.id"
                class="certificate-item"
              >
                <div class="certificate-info">
                  <div>证书编号: {{ certificate.certificateNumber }}</div>
                  <div>证书名称: {{ certificateInfo(certificate)[0] }}</div>
                  <div>持有者: {{ certificate.userName }}</div>
                  <div>归属公司:</div>
                </div>
                <div
                  v-for="file in certificate.fileInfoList"
                  :key="file.id"
                  class="certificate-images"
                >
                  <!-- <img :src="file.fileUrl" /> -->
                  <el-image
                    style="
                      width: 100px;
                      height: 100px;
                      margin-left: 15px;
                      border-radius: 6px;
                    "
                    :src="file.fileUrl"
                    :preview-src-list="[file.fileUrl]"
                  >
                  </el-image>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="申请编号" prop="applyId"> </el-table-column>
      <el-table-column width="270" label="证书名称" prop="name">
        <template slot-scope="scope">
          <span
            style="line-height: 28px"
            v-for="(name, index) in certificateInfo(scope.row.certificateList)"
            :key="index"
            >{{ name }}<br
          /></span>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="applyState">
        <template slot-scope="scope">
          <el-tooltip
            effect="dark"
            content="Top Left 提示文字"
            placement="top-start"
          >
            <el-tag :type="ststeTagType(scope.row.applyState)">{{
              stateFormat(scope.row.applyState)
            }}</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="申请时间" prop="createTime"> </el-table-column>
      <el-table-column
        label="归还时间"
        prop="returnTime"
        :formatter="timeformat"
      >
        <!-- <template slot-scope="scope">
          {{ timeformat(scope.row) }}
        </template> -->
      </el-table-column>
      <el-table-column prop="create_time" label="操作" width="270">
        <template slot-scope="scope">
          <el-button style="" size="mini" @click="delApply(scope.row)"
            >删除记录</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: flex-end; align-items: center">
      <span>共{{ total }}条</span>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        :total="total"
        @current-change="handerPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  findCertificateApply,
  delCertificateApply,
} from "@/api/certificate_apply";
import { findCertificateTypeList } from "@/api/certificateType";
import { findCertificateSpecialtyList } from "@/api/certificateSpecialty";
import { Message } from "element-ui";
import { mapState } from "vuex";

export default {
  data() {
    return {
      applyData: [],
      applyStateList: ["0", "1", "2", "3", "4", "5", "6", "7"],
      invalidStates: ["0", "3", "6", "7"],
      total: 0, //总条数

      page: {
        pageSize: 6,
        pageNum: 1,
      },
      certificateApply: {},
    };
  },
  methods: {
    ststeTagType(State) {
      switch (State) {
        case "0":
          return "info";
        case "1":
          return "warning";
        case "2":
          return "success";
        case "3":
          return "danger";
        case "4":
          return "warning";
        case "5":
          return "success";
        case "6":
          return "success";
        case "7":
          return "info";
        default:
          return "";
      }
    },
    stateFormat(applyState) {
      switch (applyState) {
        case "0":
          return "未审核";
        case "1":
          return "审核中";
        case "2":
          return "审核通过 等待邮寄";
        case "3":
          return "审核不通过";
        case "4":
          return "已邮寄,待退还";
        case "5":
          return "已退还";
        case "6":
          return "已完成";
        case "7":
          return "已撤销";
      }
    },
    timeformat(row, column) {
      if (!row.returnTime) {
        return "-";
      }
      return row.returnTime;
      // console.log('row',row)
    },
    delCertificateApplyFun(id) {
      delCertificateApply({ id }).then((response) => {
        if (response.data.success) {
          Message.success(response.data.message);
          this.findCertificateApplyFun(
            this.page,
            this.applyStateList,
            this.certificateApply
          );
        } else {
          Message.error(response.data.message);
        }
      });
    },
    handleMouseOver() {},
    // 撤销申请 OR 删除申请
    delApply(row) {
      const State = row.applyState;
      if (!this.invalidStates.includes(State)) {
        Message.error("当前状态不可删除");
        return;
      }
      if (State == "0") {
        this.$confirm("该申请未审核，要撤销并删除该申请吗？", "未审核申请", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            // 在用户点击确定后执行删除操作
            this.delCertificateApplyFun(row.id);
          })
          .catch(() => {
            // 用户取消操作时，不执行删除操作
            return;
          });
      }
      this.delCertificateApplyFun(row.id);
    },
    getStateimg(state) {
      switch (state) {
        case "0":
          return "https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/0_status.png";
        case "1":
          return "https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/1_status.png";
        case "2":
          return "https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/2_status.png";
      }
    },
    // 分页按钮点击 val为页码
    handerPage(val) {
      this.page.pageNum = val;
      this.findCertificateApplyFun(
        this.page,
        this.applyStateList,
        this.certificateApply
      );
    },

    // 复制邮寄信息
    copyAddress(postInfo) {
      console.log("postInfo", postInfo);
      const copyPostInfo =
        "收件人：" +
        postInfo.userName +
        "\n手机号码：" +
        postInfo.userPhone +
        "\n所在地区：" +
        postInfo.province +
        postInfo.city +
        postInfo.street +
        "\n详细地址：" +
        postInfo.detailAddress;
      if (navigator.clipboard) {
        // 使用 Clipboard API 复制文字到剪贴板
        navigator.clipboard
          .writeText(copyPostInfo)
          .then(() => {
            this.$notify({
              title: "成功!",
              message: "已复制地址到剪贴板",
              type: "success",
            });
          })
          .catch((err) => {
            console.error("复制失败:", err);
            this.$notify({
              title: "失败!",
              message: "复制地址失败",
              type: "warning",
            });
            // this.$message.error('复制失败');
          });
      } else {
        // 兼容处理：使用 textarea 和 document.execCommand('copy')
        const textArea = document.createElement("textarea");
        textArea.value = copyPostInfo;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand("copy");
          // this.$message.success('地址已复制到剪贴板');
        } catch (err) {
          console.error("复制失败:", err);
          // this.$message.error('复制失败');
        }
        document.body.removeChild(textArea);
      }
    },
    // 初始化申请列表
    findCertificateApplyFun(page, applyStateList, certificateApply) {
      findCertificateApply({
        page,
        applyStateList,
        certificateApply,
      }).then((response) => {
        if (response.data.success) {
          console.log("data", response.data.data);
          this.applyData = response.data.data.resultList;
          this.total = response.data.data.totalNum;
        }
      });
    },
    // certificateInfo(certificateList){
    // }
  },
  mounted() {
    // 初始化申请列表
    this.findCertificateApplyFun(
      this.page,
      this.applyStateList,
      this.certificateApply
    );

    if (this.certificateTypeList.length == 0) {
      findCertificateSpecialtyList().then((response) => {
        if (response.data.success) {
          this.$store.commit("setCertificateSpecialtyData", response.data.data);
        } else {
          Message.error(response.data.message);
        }
      });
      // 查询证书类别列表
      findCertificateTypeList().then((response) => {
        if (response.data.success) {
          this.$store.commit("setCertificateTypeData", response.data.data);
        } else {
          Message.error(response.data.message);
        }
      });
    }
  },
  computed: {
    ...mapState({
      //证书类型列表
      certificateTypeList: (state) => state.label_data.certificateTypeList,
      //证书专业列表postInfo
      certificateSpecialtyList: (state) =>
        state.label_data.certificateSpecialtyList,
    }),

    // 计算属性，certificateList可能为数组或certificate对象
    // 这个函数根据certificate的certificateSpecialtyId和certificateTypeId返回对应的类型和专业名称
    certificateInfo() {
      return function (certificateList) {
        const result = [];

        const processCertificate = (certificate) => {
          const specialty = this.certificateSpecialtyList.find(
            (item) =>
              item.certificateSpecialtyId == certificate.certificateSpecialtyId
          );
          const type = this.certificateTypeList.find(
            (item) => item.certificateTypeId == certificate.certificateTypeId
          );
          const level =
            certificate.certificateLevel == ""
              ? ""
              : certificate.certificateLevel == "1"
              ? "一级"
              : "二级";
          if (specialty && type) {
            result.push(
              `${level} ${type.typeName} [${specialty.specialtyName}]`
            );
          }
        };

        if (!Array.isArray(certificateList)) {
          processCertificate.call(this, certificateList);
        } else {
          certificateList.forEach(processCertificate.bind(this));
        }

        return result;
      };
    },
  },
};
</script>

<style scoped>
.apply-detail-info td {
  border: none !important;
  padding: 0 !important;
  background-color: #f0f6fc;
}
.getMore {
  margin: 10px 0;
  cursor: pointer;
}
.apply-detail-info tr {
  border: none !important;
  background-color: #f0f6fc;
}
.apply-detail-info {
  width: 100%;
  padding: 20px 5%;
  border-radius: 10px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* background-color: #f0f6fc; */
}
.section {
  width: 100%;
  background-color: #f3f1f1;
  padding: 20px 50px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.section-title {
  font-size: 18px;
  color: #409eff;
  margin-bottom: 10px;
}
/* .section-title:first-of-type {
  display: block;
} */
.copy-btn {
  font-size: 16px;
  cursor: pointer;
  color: #409eff;
  margin-left: 80px;
}
.info-item {
  margin: 6px 0;
  font-size: 16px;
  line-height: 1.4;
  display: flex;
  align-items: center;
}
.info-item i {
  margin-right: 8px;
}
.certificate-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ffffff;
}
.certificate-item:last-child {
  border-bottom: none;
}
.certificate-info {
  flex: 1;
  padding-right: 15px;
}
.certificate-info div {
  margin-bottom: 8px;
  font-size: 14px;
}
.certificate-images {
  height: 100%;
  display: flex;
  gap: 8px;
}
/* .certificate-images img {
  height: 80px;
  width: 100px;
  border-radius: 5px;
  margin-left: 15px;
  object-fit: cover;
} */
.certificate-image {
  width: 80px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #eee;
  transition: transform 0.3s;
}
.certificate-image:hover {
  transform: scale(1.1);
}
.audit-unit {
  /* margin: 10px 0 0 0; */
  /* display: inline; */
  box-sizing: border-box;
}
.audit-unit span {
  padding: 0 10px 15px 10px;
  font-size: 22px;

  border-bottom: 3px solid #f99436;
}

.audit-section {
}
.audit-persion {
  /* background-color: #409eff; */
  /* width: 10px; */
  line-height: 30px;
  margin-left: 250px;
}
.audit-persion span {
  font-weight: bold;
}
.audit-item {
  width: 100%;
  /* background-color: #409eff; */
  /* height: 100px; */
  padding: 10px 0;
  /* margin: 10px 0; */
  /* border-top: 1px dashed #333; */
  display: flex;
  justify-content: space-between;
}
/* 除了最后一个 audit-item，其他的audit-item都有效果*/
.audit-item:not(:last-child) {
  border-bottom: 0.5px dashed #cecdcd;
}
/* .audit-item:not(:first-child) {
  border-bottom: 1px dashed #333;
} */
.aduit-stateimg {
  height: 100%;
}
.aduit-stateimg img {
  height: 100%;
  object-fit: cover;
}
</style>