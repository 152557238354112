<template>
  <!-- 我的证书列表 -->
  <div class="certificateList">
    <div class="mange-header">
      <div style="width: 500px">
        <el-button type="primary" @click="batchApply">批量申请</el-button>
      </div>
      <!-- form搜索区 -->
      <el-form style="display: flex; align-items: center" :model="selectForm">
        <!-- <el-form-item> -->
        <el-form-item style="width: 150px; margin-right: 20px">
          <el-select
            v-model="selectForm.certificateLevel"
            clearable
            placeholder="选择证书等级"
          >
            <!-- <el-option label="空" value=""></el-option> -->
            <el-option label="一级" value="1"></el-option>
            <el-option label="二级" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 150px; margin-right: 20px">
          <el-select
            v-model="selectForm.certificateTypeId"
            clearable
            placeholder="选择证书类别"
            @change="updateTypeInSpecialty(selectForm.certificateTypeId)"
          >
            <el-option
              v-for="item in certificateTypeList"
              :key="item.certificateTypeId"
              :label="item.typeName"
              :value="item.certificateTypeId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 150px; margin-right: 20px">
          <el-select
            v-model="selectForm.certificateSpecialtyId"
            clearable
            placeholder="选择证书专业"
          >
            <el-option
              v-for="item in typeInSpecialty"
              :key="item.certificateSpecialtyId"
              :label="item.specialtyName"
              :value="item.certificateSpecialtyId"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="onSubmit()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- el-table会帮我们自动遍历展示tableData数据，但要注意 每列的prop=""要与tableData相应字段对应   -->
    <el-table
      v-if="dataIsSuccess"
      ref="multipleTable"
      :data="certificateListData"
      style="width: 100%"
      height="90%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="45" :selectable="checkState">
      </el-table-column>
      <el-table-column
        prop="userName"
        label="姓名"
        style="width: 10%"
      ></el-table-column>
      <el-table-column prop="fileUrl" label="证书图片" width="100">
        <!-- fileUrl -->
        <template slot-scope="scope">
          <img
            class="certificate-img"
            :src="
              scope.row.fileUrl
                ? scope.row.fileUrl
                : 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'
            "
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="certificateName"
        :sortable="true"
        label="名称"
        style="width: 10%"
      ></el-table-column>
      <el-table-column
        prop="specialtyName"
        :sortable="true"
        label="专业"
        style="width: 10%"
      >
      </el-table-column>
      <el-table-column
        prop="certificateNumber"
        label="编号"
        style="width: 10%"
      ></el-table-column>
      <el-table-column
        prop="certificateState"
        :sortable="true"
        label="证书状态"
        style="width: 10%"
        :formatter="certificateFormat"
      >
        <template slot-scope="scope">
          <el-tag :type="tagType(scope.row.certificateState)">{{
            certificateFormat(scope.row)
          }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="validityTime" label="有效期" style="width: 10%">
      </el-table-column>
      <el-table-column prop="addr" label="操作" width="270" fixed="right">
        <template slot-scope="scope">
          <el-button style="" size="mini" @click="handleCheckImg(scope.row.id)"
            >查看</el-button
          >

          <el-button
            type="primary"
            size="mini"
            @click="applyTransfer(scope.row)"
            >申请调动</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: flex-end; align-items: center">
      <span>共{{ total }}条</span>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="page.currentPage"
        :page-size="page.pageSize"
        :total="total"
        @current-change="handerPage"
      >
      </el-pagination>
    </div>

    <!-- ========================= 查看大图提示框========================= -->
    <el-dialog :visible.sync="dialogVisible">
      <div class="certificateDialog">
        <div style="display: flex">
          <el-pagination
            layout="prev, pager, next"
            :total="showFileList.length"
            :current-page="checkPage"
            :page-size="1"
            @current-change="handerPageByCertificate"
          >
          </el-pagination>
          <el-link
            type="primary"
            style="margin-left: 50px"
            @click.native="download()"
            >下载当前页</el-link
          >
        </div>
        <img width="100%" :src="dialogImageUrl" alt="" />
      </div>
    </el-dialog>
    <!-- ========================= 查看大图提示框========================= -->
    <!-- ========================= 申请调动提示框========================= -->
    <el-dialog
      title="申请调动证书"
      :visible.sync="applyTransferDialogVisible"
      width="50%"
    >
      <el-steps :active="active" finish-status="success" align-center>
        <el-step title="确认调动证书"></el-step>
        <el-step title="填写邮寄信息"></el-step>
      </el-steps>
      <div class="at_dialogBody">
        <!-- 调动证书信息 -->
        <div class="certificateListInfo" v-if="active == 0">
          <el-empty
            description="还没有选择证书"
            v-if="applyTransferListArr.length == 0"
          ></el-empty>
          <el-card
            shadow="hover"
            class="certificateCard"
            v-for="certificate in applyTransferListArr"
            :key="certificate.id"
          >
            <div>
              &emsp;{{ certificate.userName }}&emsp;{{
                certificate.certificateName
              }}&emsp;
              <el-tag effect="plain"> {{ certificate.specialtyName }} </el-tag>
            </div>
            <ul>
              <li v-for="file in certificate.files" :key="file.fileId">
                <img :src="file.fileUrl" />
              </li>
            </ul>
          </el-card>
        </div>
        <!-- 邮寄信息 -->
        <div class="applypostInfo" v-if="active !== 0">
          <div class="addressInfo">
            <h1
              class="changeAddress"
              @click="addressDrawer = true"
              v-if="postInfoList.length == 0"
            >
              请选择邮寄信息
              <i
                class="el-icon-arrow-right right-arrow"
                @click="addressDrawer = true"
              ></i>
            </h1>
            <template v-if="!postInfoList.length == 0">
              <h1 class="changeAddress" @click="addressDrawer = true">
                {{ postInfoList[selectIndex].detailAddress
                }}<i
                  class="el-icon-arrow-right right-arrow"
                  @click="addressDrawer = true"
                ></i>
              </h1>
              <p>
                {{ postInfoList[selectIndex].province }}&nbsp;{{
                  postInfoList[selectIndex].city
                }}&nbsp;{{ postInfoList[selectIndex].district }}&nbsp;{{
                  postInfoList[selectIndex].street
                }}
              </p>
              <p>
                {{ postInfoList[selectIndex].userName }}&nbsp;{{
                  postInfoList[selectIndex].userPhone
                }}
              </p>
            </template>
          </div>
          <!-- <el-form>
          <el-form-item label="备注"> -->
          <div class="applyRemark">
            <!-- <span>备注：</span> -->
            <el-form>
              <el-form-item label="备注：">
                <el-input type="textarea" v-model="applyForm.remark"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <!-- </el-form-item>
        </el-form> -->
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="active = 0" v-if="active == 1">上一步</el-button>
        <el-button @click="applyTransferDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :disabled="
            active === 0
              ? SelectionChangeArr.length === 0
              : postInfoList.length === 0
          "
          @click="submitApply"
          >{{ active === 0 ? `下一步` : `提交申请` }}</el-button
        >
      </span>
    </el-dialog>
    <!-- ========================= 申请调动提示框========================= -->
    <!-- ========================= 选择地址侧边框========================= -->
    <el-drawer :visible.sync="addressDrawer" style="position: absolute">
      <template slot="title">
        <div class="custom-drawer-title">
          <p v-show="!isAddPostInfo"></p>
          <el-button
            v-show="isAddPostInfo"
            @click="isAddPostInfo = false"
            type="text"
            class="back-button"
            ><i class="el-icon-arrow-left"></i
          ></el-button>
          <span>{{ isAddPostInfo ? "新增收货信息" : "选择邮寄信息" }}</span>
          <p></p>
        </div>
      </template>
      <!-- <el-form>
        <el-form-item> -->
      <div class="postInfo-container">
        <div
          v-if="isAddPostInfo"
          style="
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <el-form
            :model="postInfoForm"
            :rules="rules"
            label-width="80px"
            ref="postInfoForm"
          >
            <el-form-item prop="address" label="所在地区" style="width: 90%">
              <el-cascader
                placeholder="省、市、区、街道"
                separator="  "
                :props="props"
                clearable
                @change="handleChange"
                v-model="postInfoForm.address"
                style="width: 100%; border: none"
              ></el-cascader>
            </el-form-item>
            <el-form-item
              prop="detailAddress"
              label="详细地址"
              style="width: 90%"
            >
              <el-input
                placeholder="小区、写字楼、门牌号等"
                v-model="postInfoForm.detailAddress"
              ></el-input>
            </el-form-item>
            <el-form-item prop="userName" label="收件人" style="width: 90%">
              <el-input
                placeholder="收件人名字"
                v-model="postInfoForm.userName"
              ></el-input>
            </el-form-item>
            <el-form-item prop="userPhone" label="手机号" style="width: 90%">
              <el-input
                placeholder="手机号"
                v-model="postInfoForm.userPhone"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button
            type="primary"
            style="width: 90%; margin-bottom: 50px"
            round
            @click="savePostInfo"
            >保存</el-button
          >
        </div>
        <el-empty
          description="请新建收货信息"
          v-if="postInfoList.length == 0 && !isAddPostInfo"
        ></el-empty>
        <!-- 地址单选列表 -->
        <div class="address-container" v-if="!isAddPostInfo">
          <el-radio-group v-if="!isAddPostInfo" v-model="selectIndex">
            <!-- <el-radio label="1">
              <h1>饶商总部基地7栋16楼</h1>
              <p>江西省&nbsp;上饶市&nbsp;信州区&nbsp;灵溪街道凤凰东大道2号</p>
              <p>刘袁&nbsp;13766398476</p>
            </el-radio> -->
            <el-radio
              class="address-item"
              v-for="(item, index) in postInfoList"
              :key="item.id"
              :label="index"
              @click.native="addressDrawer = false"
            >
              <div class="address-details">
                <h1>{{ item.detailAddress }}</h1>
                <p>
                  {{ item.province }}&nbsp;{{ item.city }}&nbsp;{{
                    item.district
                  }}&nbsp;{{ item.street }}
                </p>
                <p>{{ item.userName }}&nbsp;{{ item.userPhone }}</p>
              </div>
              <!-- 地址操作按钮 -->
              <div class="address-operation">
                <!-- <el-button class="default-btn"  circle  @click.stop plain ><span>默认</span></el-button> -->
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-edit"
                  circle
                  @click.stop
                ></el-button>
                <el-button
                  type="danger"
                  plain
                  icon="el-icon-delete"
                  circle
                  @click.stop="deletePostInfo(item.id)"
                ></el-button>
              </div>
            </el-radio>
          </el-radio-group>
        </div>
        <div v-if="!isAddPostInfo" class="btm-addPostBtn">
          <el-button
            @click="isAddPostInfo = true"
            class="addPostBtn"
            plain
            round
            >新增收货信息</el-button
          >
        </div>
      </div>

      <!-- </el-form-item>
      </el-form> -->
    </el-drawer>
    <!-- ========================= 选择地址侧边框========================= -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  queryAllCertificate,
  addCertificate,
  switchCertificateState,
} from "@/api/certificate";
import { findCertificateTypeList } from "@/api/certificateType";
import { findCertificateSpecialtyList } from "@/api/certificateSpecialty";
import { getCertificateImg, getBatchCertificateImg } from "@/api/file";
import {
  queryPostInfoRequest,
  addPostInfoRequest,
  deletePostInfoRequest,
} from "@/api/postInfo";
import { uploadFileFun } from "@/utils/downloadFileUtil";
import { createCertificateApply } from "@/api/certificate_apply";
import { Message, Row } from "element-ui";
import axios from "axios";
// 使用elementui的from 配置输入规则：
// 1.from组件为rules传入规则 liuyuan@huaxu
// 2.在要配置规则的form-item项 加上属性：prop="region"
export default {
  data() {
    return {
      dataIsSuccess: false, //数据是否加载好
      dialogVisible: false, //查看大图提示框
      dialogImageUrl: "",
      addCertificateDialogVisible: false, //添加证书弹窗
      updateUserDialogVisible: false, //更改用户角色弹窗
      applyTransferDialogVisible: false, //申请调动证书弹窗
      addressDrawer: false, //收件地址右边栏
      applyTransferListArr: [], //申请调动证书列表，其中包括证书信息和证书图片信息
      downloadFile: {}, //当前下载file
      fileList: [], //用于保存上传证书图片列表
      showFileList: [], //用于保存展示证书图片列表
      checkPage: 1, //查看证书图片当前的页面
      isLoading: true,
      fileList: [],
      postInfoList: [], //邮寄信息
      active: 0,
      selectForm: {
        certificateLevel: "",
        certificateTypeId: "",
        certificateSpecialtyId: "",
      }, //搜索条件表单
      selectIndex: 0, //用户选中的邮寄信息下标
      // selectPostInfoForm: {
      //用户选中的邮寄信息表单
      // resource: 0,
      // },
      applyForm: {
        //申请表单
        remark: "",
        certificateIds: [],
        postIdConsignee: "",
      },
      postInfoForm: {
        //新增邮寄信息表单currentPage
        address: "",
        province: "",
        city: "",
        district: "",
        street: "",
        detailAddress: "",
        userName: "",
        userPhone: "",
      },
      isAddPostInfo: false, //是否新增邮寄信息
      total: 0, //总条数
      pageSize: 6, //每页显示的条数
      currentPage: 1, //当前页数
      selectCertificateArr: [], //用于保存当前选中证书信息
      // certificateTypeList: [], //证书类型列表
      // certificateSpecialtyList: [], //证书专业列表
      typeInSpecialty: [], //下拉类型框时，对应的专业列表
      SelectionChangeArr: [], //多选框列表
      isDisabled: false, //按钮是否禁用

      page: {
        pageSize: 2,
        pageNum: 1,
      },
      certificate: {
        certificateLevel: "",
        certificateTypeId: "",
        certificateSpecialtyId: "",
      },

      form: {
        certificateLevel: "",
        certificateTypeId: "",
        certificateSpecialtyId: "",
        certificateNumber: "",
        validityTime: "",
        startTime: "",
        endTime: "",
        certificateFileList: [],
      },
      certificateListData: {},
      rules: {
        address: [
          // required: true 是否必填
          { required: true, message: "请选择地区", trigger: "change" },
        ],
        detailAddress: [
          // required: true 是否必填
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        userPhone: [
          // required: true 是否必填
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
        userName: [
          // required: true 是否必填
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
      },
      props: {
        lazy: true,
        lazyLoad: this.lazyRegionData, // 使用定义在 methods 中的 lazyLoad 函数
      },
    };
  },
  methods: {
    handleChange(value) {
      this.postInfoForm.province = value[0];
      this.postInfoForm.city = value[1];
      this.postInfoForm.district = value[2];
      this.postInfoForm.street = value[3];
      console.log("最终的信息表单", this.postInfoForm);

      // 你可以在这里处理选择的数据
    },

    // 保存收获信息按钮
    savePostInfo() {
      this.$refs["postInfoForm"].validate((valid) => {
        if (valid) {
          addPostInfoRequest(this.postInfoForm).then((response) => {
            if (response.data.success) {
              this.PostInfoRequestFun();
              // Message.success(response.data.message)
              this.$notify({
                title: "成功!",
                message: response.data.message,
                type: "success",
              });
              this.isAddPostInfo = false;
            } else {
              // Message.error(response.data.message)

              this.$notify({
                title: "失败!",
                message: response.data.message,
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 删除邮寄信息
    deletePostInfo(id) {
      deletePostInfoRequest({ id }).then((response) => {
        if (response.data.success) {
          this.postInfoList = this.postInfoList.filter(
            (item) => item.id !== id
          );
          this.$notify({
            title: "成功!",
            message: response.data.message,
            type: "success",
          });
        } else {
          this.$notify({
            title: "失败!",
            message: response.data.message,
            type: "warning",
          });
        }
      });
    },
    // 动态获取地区数据
    async lazyRegionData(node, resolve) {
      try {
        const response = await axios.get("/api_region_search/v1/", {
          params: {
            // node.label为空时默认值为中国
            keyword: node.label || "中国",
            sub_admin: "1",
            ak: "lGftMMdKchxt98GCtXje3h18LtNvgWnW",
          },
        });
        console.log("nodes");
        // 请求成功后的数据
        const districts = response.data.districts[0].districts;
        console.log(districts);

        // 将请求成功后的数据进行格式转换
        const nodes = districts.map((district) => ({
          value: district.name,
          label: district.name,
          // leaf为叶节点层数
          leaf: district.level >= 4,
        }));

        resolve(nodes);
      } catch (error) {
        Message.error("地区数据获取失败，请稍后再试");
        console.error("地区数据获取失败:", error);
      }
    },

    // 下一步或提交申请调动按钮
    submitApply() {
      const timestamp = new Date().getTime();
      console.log(timestamp);
      // 如果 active 是 0，则执行下一步逻辑
      if (this.active === 0) {
        // 更新 isDisabled 的值
        this.isDisabled = this.SelectionChangeArr.length === 0;
        // 如果 isDisabled 为 false，则将 active 设置为 1
        if (!this.isDisabled) {
          this.active = 1;
        }
      } else {
        // 否则执行提交申请逻辑
        this.isDisabled = this.postInfoList.length === 0;
        // 如果 isDisabled 为 false，则执行提交申请
        if (!this.isDisabled) {
          // 为applyForm组装信息
          this.applyForm.postIdConsignee =
            this.postInfoList[this.selectIndex].id;
          this.applyForm.certificateIds = this.getSelectionChangeArrId;
          this.active = 2;
          console.log("准备提交证书申请，form", this.applyForm);
          createCertificateApply(this.applyForm).then((response) => {
            if (response.data.success) {
              Message.success(response.data.message);
            } else {
              Message.error(response.data.message);
            }
          });
          setTimeout(() => {
            this.applyTransferDialogVisible = false;
          }, 1500);
        }
      }
    },

    //获取城市数据
    // async getRegionData(){
    //   console.log("获取城市数据")
    //   try{
    //     const response = await axios.get('/api_region_search/v1/',{
    //       params:{
    //         keyword:'中国',
    //         sub_admin:'1',
    //         ak:'lGftMMdKchxt98GCtXje3h18LtNvgWnW'
    //       }
    //     }
    //     )
    //     console.log(response.data.districts[0].districts)
    //   }catch(error){

    //   }
    // },

    certificateFormat(row) {
      // 0审核中 1正常可申请 2已寄出（不可申请） 3已禁用（不可申请）
      switch (row.certificateState) {
        case "0":
          return "审核中";
        case "1":
          return "可申请";
        case "2":
          return "待寄出 " + row.borrowerName;
        case "3":
          return "已寄出 " + row.borrowerName;
          case "4":
          return "待入库 " + row.borrowerName;
          case "5":
          return "已禁用";
      }
    },

    // 检查证书状态，状态不可用时将不能被选中
    checkState(row, index) {
      switch (row.certificateState) {
        case "0":
          return false;
        case "1":
          return true;
        case "2":
          return false;
        case "3":
          return false;
      }
    },

    // 将证书信息数组与证书图片数组结合,让图片称为证书的属性
    conformityArr(certificateList, fileList) {
      // 创建一个 Map 以便快速查找 certificateList 中的对象，并为每个证书对象添加一个空的 files 属性
      const certificateMap = new Map(
        certificateList.map((cert) => {
          cert.files = []; // 确保每个证书对象都有一个空的 files 属性
          return [String(cert.id), cert];
        })
      );

      // 遍历 fileList 数组，将每个文件对象添加到相应的 certificateList 对象中
      fileList.forEach((fileObj) => {
        const certId = String(fileObj.certificateId);
        if (certificateMap.has(certId)) {
          certificateMap.get(certId).files.push(fileObj);
        }
      });

      console.log("-----------------");
      console.log(certificateList);
    },

    PostInfoRequestFun() {
      queryPostInfoRequest().then((response) => {
        if (response.data.success) {
          this.postInfoList = response.data.data;
        }
        console.log("邮寄信息");
        console.log(response.data);
      });
    },

    // 申请调动按钮被点击(只申请一个)
    async applyTransfer(row) {
      switch (row.certificateState) {
        case "0":
          Message.warning("当前证书审核中，暂时不能调动");
          return;
        case "2":
          Message.warning("当前证书已被调动");
          return;
        case "3":
          Message.warning("当前证书已被禁用，请联系管理员");
          return;
      }

      //  当申请调动按钮被点击，需把多选框选中全部移除，再设为当前点击行为选中状态
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row);
      this.applyTransferListArr = [row];
      console.log(this.SelectionChangeArr);
      let fileList = [];
      try {
        const response = await getCertificateImg({ certificateId: row.id });
        if (response.data.success) {
          fileList = response.data.data;
        } else {
          Message.error(response.data.message);
        }
      } catch (error) {
        // console.error("请求证书图片出错：", error);
        Message.error("请求证书图片出错，请稍后重试");
        return;
      }

      this.conformityArr(this.applyTransferListArr, fileList);
      this.applyTransferDialogVisible = true;
      // 查询邮寄信息
      this.PostInfoRequestFun();
      console.log(this.applyTransferListArr);
    },

    // 批量申请
    async batchApply() {
      this.applyTransferDialogVisible = true;
      if (this.SelectionChangeArr.length === 0) {
        return;
      }
      let fileList = [];
      try {
        const response = await getBatchCertificateImg(
          this.getSelectionChangeArrId
        );
        if (response.data.success) {
          fileList = response.data.data;
        } else {
          Message.error(response.data.message);
        }
      } catch (error) {
        Message.error("请求证书图片出错，请稍后重试");
        return;
      }
      this.conformityArr(
        (this.applyTransferListArr = this.SelectionChangeArr),
        fileList
      );
      // 查询邮寄信息
      this.PostInfoRequestFun();
    },

    findCertificateTypeRequest() {
      // 查询证书类别列表
      findCertificateTypeList()
        .then((response) => {
          if (response.data.success) {
            // this.certificateTypeList = response.data.data;
            this.$store.commit("setCertificateTypeData", response.data.data);
          } else {
            Message.error(response.data.message);
          }
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
    },

    // 查询证书专业列表
    findCertificateSpecialtyRequest() {
      findCertificateSpecialtyList().then((response) => {
        if (response.data.success) {
          // this.certificateSpecialtyList = response.data.data;
          this.$store.commit("setCertificateSpecialtyData", response.data.data);
          // console.log(this.certificateSpecialtyList)
        } else {
          Message.error(response.data.message);
        }
      });
    },

    // 当证书类型下拉框改变时触发
    updateTypeInSpecialty(selectedTypeId) {
      // 清除证书专业下拉框的选中元素
      if (this.selectForm.certificateSpecialtyId) {
        this.selectForm.certificateSpecialtyId = "";
      }
      if (this.form.certificateSpecialtyId) {
        this.form.certificateSpecialtyId = "";
      }

      // this.selectForm.certificateSpecialtyId = "";
      // 证书类型菜单选中元素时，根据证书类型id来确定证书专业列表
      this.typeInSpecialty = this.certificateSpecialtyList.filter(
        (item) => item.certificateTypeId === selectedTypeId + ""
      );
    },
    // 查看图片
    handlePictureCardPreview(fileUrl) {
      this.dialogImageUrl = fileUrl;
      this.dialogVisible = true;
    },

    // 查看按钮点击时执行
    handleCheckImg(id) {
      getCertificateImg({ certificateId: id }).then((response) => {
        if (response.data.success) {
          this.showFileList = response.data.data;
          console.log("当前证书图片信息");
          console.log(this.showFileList);
          this.handlePictureCardPreview(response.data.data[0].fileUrl);
          this.downloadFile = response.data.data[0];
          // Message.success(response.data.message);
        } else {
          Message.error(response.data.message);
        }
      });
    },

    // 复选框选中时触发
    handleSelectionChange(val) {
      this.SelectionChangeArr = val;

      console.log(this.SelectionChangeArr);
    },

    // 用户提交表单，先判断是否全部填写完,如果通过规则valid = true
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 验证成功，关闭弹窗
          this.addCertificateDialogVisible = false;
          addCertificate(this.form)
            .then((response) => {
              if (response.data.success) {
                Message.success(response.data.message);
                this.queryAllCertificateFun(
                  this.page,
      this.certificate
                );
              } else {
                Message.error(response.data.message);
              }
            })
            .catch((error) => {
              console.error(
                "There has been a problem with your fetch operation:",
                error
              );
            });
        }
      });
    },

    queryAllCertificateFun(
      page,
      certificate
    ) {
      // 分页搜索用户列表操作
      queryAllCertificate({
        // pageSize,
        // pageNum,
        // certificateLevel,
        // certificateTypeId,
        // certificateSpecialtyId,
        page,
      certificate
      }).then((response) => {
        if (response.data.success) {
          console.log("证书列表");
          console.log(response.data);
          this.dataIsSuccess = true;
          this.total = response.data.data.totalNum;
          this.certificateListData = response.data.data.resultList;

          this.certificateListData.forEach((item) => {
            // 把0或1变为布尔值用于switch开关的控制
            // item.certificateState === '1' ? item.certificateState = true : item.certificateState = false
            item.validityTime = item.startTime + "至" + item.endTime;
            // 把证书级别和证书类型整合成证书名字(未完成代码)
            item.certificateName =
              item.certificateLevel == "1"
                ? "一级" + item.typeName
                : item.certificateLevel == "2"
                ? "二级" + item.typeName
                : "" + item.typeName;
          });
        }
      });
    },

    // 分页点击时执行 val为页码
    handerPage(val) {
      this.page.pageNum = val
      this.queryAllCertificateFun(
        // this.pageSize,
        // val,
        // this.selectForm.certificateLevel,
        // this.selectForm.certificateTypeId,
        // this.selectForm.certificateSpecialtyId
        this.page,
      this.certificate
      );
    },
    // 搜索
    onSubmit() {
      this.queryAllCertificateFun(
        // this.pageSize,
        // this.currentPage,
        // this.selectForm.certificateLevel,
        // this.selectForm.certificateTypeId,
        // this.selectForm.certificateSpecialtyId
        this.page,
      this.certificate
      );
    },
    // 证书图片展示弹出框被点击分页
    handerPageByCertificate(val) {
      this.dialogImageUrl = this.showFileList[val - 1].fileUrl;
      this.downloadFile = this.showFileList[val - 1];
      this.checkPage = 1;
    },

    // 下载当前证书图片
    download() {
      uploadFileFun(this.downloadFile);
      console.log(this.downloadFile);
    },
    // 通过日期对象来构建日期字符串
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从 0 开始，需要加 1，并且保证两位数
      const day = String(date.getDate()).padStart(2, "0"); // 保证两位数

      return `${year}-${month}-${day}`;
    },
    tagType(certificateState) {
      switch (certificateState) {
        case "0":
          return "info";
        case "1":
          return "success";
        case "2":
          return "warning";
        case "3":
          return "danger";
        default:
          return "";
      }
    },
  },
  mounted() {
    // this.getRegionData()
    this.queryAllCertificateFun(
      // this.pageSize,
      // this.currentPage,
      // this.selectForm.certificateLevel,
      // this.selectForm.certificateTypeId,
      // this.selectForm.certificateSpecialtyId
      this.page,
      this.certificate
    );
    if (this.certificateTypeList.length == 0) {
      // 查询证书类型列表
      this.findCertificateTypeRequest();
      // 查询证书专业列表
      this.findCertificateSpecialtyRequest();
    }
  },
  computed: {
    // 多选框选中保存的为每一列对象，我们只需要对象的id
    getSelectionChangeArrId() {
      var certificateIds = this.SelectionChangeArr.map((item) => {
        return item.id;
      });
      return certificateIds;
    },
    ...mapState({
      //证书类型列表
      certificateTypeList: (state) => state.label_data.certificateTypeList,
      //证书专业列表
      certificateSpecialtyList: (state) =>
        state.label_data.certificateSpecialtyList,
    }),
  },
  watch: {
    addCertificateDialogVisible(newVal, oldVal) {
      // 当 addCertificateDialogVisible 属性的值发生变化时，会触发这个方法
      // 当添加证书弹窗关闭时，要进行一些数据清除操作
      if (!newVal) {
        this.form = {};
        this.typeInSpecialty = [];
        this.selectForm = {};
      }
      // 当添加证书弹窗开启时，要进行一些数据清除操作
      if (newVal) {
        this.typeInSpecialty = [];
        this.selectForm = {};
      }
    },
    addressDrawer(newVal, oldVal) {
      // 当右边选择邮寄信息侧边栏关闭，执行一些初始化操作
      if (!newVal) {
        this.isAddPostInfo = false;
      }
    },
    // 当添加地址侧边栏关闭时，执行一些初始化操作
    isAddPostInfo(newVal, oldVal) {
      this.postInfoForm = {};
    },

    // 监听申请调动弹窗变化，当弹窗关闭时，侧边多选框将取消选中
    applyTransferDialogVisible(newVal, oldVal) {
      if (!newVal) {
        // 清除多选框
        this.$refs.multipleTable.clearSelection();
        this.applyTransferListArr = [];
        this.active = 0;
        this.applyForm = {};
      }
    },
    // "form.certificateFileList": function (newVal, oldVal) {
    //   console.log("文件列表发生变化" + newVal.length);
    // },

    // 监听form.validityTime属性，为这个有效期对象格式化后拆分为开始日期和结束日期
    "form.validityTime": function (newVal, oldVal) {
      if (newVal !== undefined && newVal !== null && newVal.length === 2) {
        this.form.startTime = this.formatDate(newVal[0]);
        this.form.endTime = this.formatDate(newVal[1]);
      }
    },
  },
};
</script>
  
  <style scoped>
.certificate-img {
  width: 100%; /* 图片宽度为容器的100% */
  height: 75px;
  object-fit: cover; /* 图片内容将覆盖整个容器，同时保持其宽高比 */
}
.certificateList {
  height: 90%;
}
.at_dialogBody {
  height: 380px;
  overflow: auto; /* 当内容溢出时显示滚动条 */
  /* Firefox 隐藏滚动条 */
  scrollbar-width: none;
  /* IE 和 Edge 隐藏滚动条 */
  -ms-overflow-style: none;
}
.at_dialogBody::-webkit-scrollbar {
  /* Chrome, Safari 和 Opera 隐藏滚动条 */
  display: none;
}
.at_dialogBody .el-card__body {
  padding: 0 20px !important;
}
.back-button {
  color: #666;
  font-size: 22px;
  margin-left: 15px;
}
.certificateListInfo {
  width: 96%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: center;
  /* background-color: aquamarine; */
}
.certificateListInfo .el-card {
  border: 1px solid #c6c6c68e !important;
}
.certificateCard {
  width: 93%;
  height: 130px;
}
.certificateListInfo ul {
  display: flex;
  margin-top: 10px;
}
/* .default-btn{
  padding: 12px 8px !important;
}
.default-btn span{
  font-size: 11px;
} */
.certificateListInfo li {
  height: 80px;
  width: 80px;
  list-style: none;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 20px;
}
.certificateListInfo img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.btm-addPostBtn {
  width: 100%;
  padding-top: 15px;
  /* height: 80px; */
  margin-bottom: 50px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.applypostInfo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.addressInfo {
  margin-top: 30px;
  position: absolute;
  cursor: pointer;
}
.addressInfo h1 {
  font-size: 2em;
}
.addPostBtn {
  width: 90%;
  /* margin-bottom: 30px; */
  /* position: relative;
  bottom: 0; */
  /* position: fixed;
  bottom: 30px; */
}
.custom-drawer-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.address-container {
  width: 100%;
  height: 90%;
  /* overflow: hidden; */
  /* background-color: #409eff; */
  padding: 20px;
  overflow: auto; /* 当内容溢出时显示滚动条 */
  /* Firefox 隐藏滚动条 */
  scrollbar-width: none;
  /* IE 和 Edge 隐藏滚动条 */
  -ms-overflow-style: none;
  /* position: absolute; */
}
.address-container::-webkit-scrollbar {
  /* Chrome, Safari 和 Opera 隐藏滚动条 */
  display: none;
}
.address-container .el-radio__label {
  width: 100%;
}
.address-container .el-radio-group {
  width: 100%;
}
.certificateList .el-drawer__body {
  /* overflow: hidden !important; */
  overflow: hidden; /* 当内容溢出时显示滚动条 */
  /* Firefox 隐藏滚动条 */
  scrollbar-width: none;
  /* IE 和 Edge 隐藏滚动条 */
  -ms-overflow-style: none;
}
.certificateList .el-drawer__body::-webkit-scrollbar {
  /* Chrome, Safari 和 Opera 隐藏滚动条 */
  display: none;
}
.address-container .el-radio__label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.address-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
  /* 设置这个，padding 和 border 的大小将包含在元素的总宽度和高度中 */
  box-sizing: border-box;
  /* margin-bottom: 10px; */
  transition: border-color 0.3s;
}
.address-details {
  width: 72%;
  margin-left: 10px;
  flex-grow: 1;
  padding: 10px 0;
}
.address-operation {
  width: 28%;

  display: flex;
  justify-content: space-between;
  display: none;
  /* margin-right: 20px; */
  /* height: 10px; */
  /* background-color: #409eff; */
}
/* .address-operation :nth-child(1){
  padding: 6px;
} */

.address-container .el-radio {
  border-bottom: 1px solid #e6e6e6ae;
}
.address-container .el-radio:hover {
  background-color: #047ef70e;
}
.address-container .el-radio:hover .address-operation {
  display: block;
}
.address-item h1 {
  font-size: 1.2em;
  margin: 0;
  font-weight: bold;
}
.address-item p {
  margin: 5px 0;
  color: #666;
}

.applypostInfo p {
  line-height: 30px;
  font-size: 14px;
  /* width: 100%; */
}
.right-arrow {
  position: absolute;
  top: 6px;
  /* right: -35px; */
  font-size: 30px;
  margin-left: 15px;
}
.changeAddress {
  width: auto;
}

.changeAddress:hover {
  color: #409eff;
}
.right-arrow:hover {
  color: #409eff;
}
.postInfo-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.postInfo-container .el-input__inner,
.postInfo-container .el-cascader__label,
.postInfo-container .el-cascader__input {
  border: none !important;
  box-shadow: none !important;
  background-color: #a7a7a713;
  border-radius: 10px;
}
.postInfo-container .el-input__inner:focus,
.postInfo-container .el-cascader__label:focus,
.postInfo-container .el-cascader__input:focus {
  border: 1px solid #409eff !important;
}
.applyRemark {
  width: 100%;
  margin-top: 120px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* display: block; */
  /* flex-basis: 100%; */
}
.applyRemark span {
  /* width: 100%; */
}
.applyRemark .el-textarea {
  width: 400px !important;
  height: 150px;
}
.applyRemark .el-textarea__inner {
  width: 400px !important;
  height: 150px;
}

.certificateList .el-drawer__header > :first-child {
  text-align: center;
  font-weight: bold;
  color: #000;
}

.mange-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.el-table .warning-row {
  background: rgb(254, 249, 249);
}
.el-table .success-row {
  background: #929690;
}
.el-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.el-form-item {
  width: 48%;
  margin-top: 10px;
}
.el-table__row {
  font-size: 16px;
}
.el-pagination {
  margin-left: 50px;
  text-align: right;
}
.cell {
  text-align: center;
}
.el-form-item {
  width: auto;
}
.uploadCertificate {
  width: 100%;
}
.uploadCertificate .el-form-item__label {
  padding: 0 0 0 37px;
}
</style>