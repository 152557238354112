<template>
  <div>
    <router-view></router-view>
    <div id="main2"></div>
  </div>
</template>

<script>
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
// import HelloWorld from './components/HelloWorld.vue';

@Component({
  components: {
    // HelloWorld,
  },
})

export default class App extends Vue {

}
</script>

<style>
html,body{
  margin: 0;
  padding: 0;
}

/* @media (max-width: 1920px) and (min-width: 768px) {
  html {
    zoom: 90%;
  }
  @-ms-viewport {
    width: 1920px;
  }
} */
</style>
