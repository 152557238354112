import http from "../utils/request"

// 查询证书专业列表
export function findCertificateSpecialtyList(data = {}){
    return http({
        method:'GET',
        url:'/certificateSpecialty/queryAllList',
        data
    })
}
