import OSS from 'ali-oss';
import{getOSSInfo} from "../api/ossinfo"
import { Message } from "element-ui";

// 截取字符串，获得到对下载有用的信息
function extractSubstring(url){
        // 通过'//'进行开始截取
        const startSubstring = url.split('//')[1];
        // 通过'.'结束截取
        const endSubstring = startSubstring.split('.')[0];
        return endSubstring;
}

const OSSInfo = {
    region:'',
    accessKeyId:'',
    accessKeySecret:'',
    bucket:'',
}


 // 向后端请求oss信息
 export  function uploadFileFun(file){
 getOSSInfo().then((response) => {
    if(response.data.success){
        console.log("获取到后端的oss信息")
        console.log(response.data)
        OSSInfo.region = extractSubstring(response.data.data.endPoint)
        OSSInfo.bucket = extractSubstring(response.data.data.ali_DOMAIN)
        OSSInfo.accessKeyId = response.data.data.accessKeyId
        OSSInfo.accessKeySecret = response.data.data.accessKeySecret
        console.log(OSSInfo)
        // OSSInfo.isActive = true
        // 获取到oss信息后调用oss进行下载
        uploadFile(file)
    //   this.roleList = response.data.data
    }else{
      Message.error("下载失败！");
    }
  })
  .catch((error) => {
    console.error(
      "There has been a problem with your fetch operation:",
      error
    );
  })
}

function uploadFile(file){
        const client = new OSS({
            // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
            // region: "oss-cn-shenzhen",
            region:OSSInfo.region,
            // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
            // accessKeyId: "LTAI5tAMRPFoJcL7uPFAN4Pr",
            accessKeyId:OSSInfo.accessKeyId,
            // accessKeySecret: "fKnRoBu8gaCCrIz9jcXYb0ZYCGeNYg",
            accessKeySecret:OSSInfo.accessKeySecret,
            // 从STS服务获取的安全令牌（SecurityToken）。
            // stsToken: "yoursecurityToken",
            // 填写Bucket名称。
            // bucket: "yuan-house",
            bucket:OSSInfo.bucket
          });
    
          // 配置响应头实现通过URL访问时自动下载文件，并设置下载后的文件名 file.oldName。

          const response = {
            "content-disposition": `attachment; filename=${encodeURIComponent(
              file.oldName
            )}`,
          };
          // 填写Object完整路径。Object完整路径中不能包含Bucket名称  根据file.newName, 找到oss服务器的文件。
          const url = client.signatureUrl(file.newName, { response });
           // 创建隐藏的链接元素
          const link = document.createElement("a");
          link.style.display = "none";
          document.body.appendChild(link);
          
          // 设置链接的href属性为OSS URL
          link.setAttribute("href", url);
    
        //   link.setAttribute("download", encodeURIComponent(file.oldName));
           // 触发点击事件进行下载
           link.click();
    
          // 移除链接元素
          setTimeout(function() {
        document.body.removeChild(link);
      }, 1500);
    }

