<template>
  <div style="height: 100%; width: 100%">
    <el-table :data="applyData" style="width: 100%">
      <el-table-column label="申请编号" prop="applyId"> </el-table-column>
      <el-table-column label="申请人" prop="userName"> </el-table-column>
      <el-table-column width="270" label="证书名称" prop="name">
        <template slot-scope="scope">
          <span
            style="line-height: 28px"
            v-for="(name, index) in getCerName(scope.row.certificateList)"
            :key="index"
            >{{ name }}<br
          /></span>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="approvalState">
        <template slot-scope="scope">
          <el-tooltip
            effect="dark"
            content="Top Left 提示文字"
            placement="top-start"
          >
            <el-tag :type="ststeTagType(scope.row.approvalState)">{{
              stateFormat(scope.row.applyState)
            }}</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="申请时间" prop="createTime"> </el-table-column>
      <el-table-column prop="create_time" label="操作" width="270">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            :disabled="scope.row.applyState !== '5'"
            @click="sendOff(scope.row.applyId)"
            >入库</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: flex-end; align-items: center">
      <span>共{{ certificateApplyRequest.page.total }}条</span>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="certificateApplyRequest.page.pageNum"
        :page-size="certificateApplyRequest.page.pageSize"
        :total="certificateApplyRequest.page.total"
        @current-change="handerPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { findMyCerApplyPendingOperations } from "@/api/certificate_apply.js";

export default {
  data() {
    return {
      applyData: [],
      // applyStateList: ["0"],
      certificateApplyRequest: {
        page: {
          pageSize: 12,
          pageNum: 1,
          total: 0, //总条数
        },
        applyStateList: ["4","5"],
        certificateApply: {
          userAccount: "",
        },
      },
    };
  },
  methods: {
    ststeTagType(State) {
      switch (State) {
        case "0":
          return "info";
        case "1":
          return "warning";
        case "2":
          return "success";
        case "3":
          return "danger";
        case "4":
          return "warning";
        case "5":
          return "success";
        case "6":
          return "success";
        case "7":
          return "info";
        default:
          return "";
      }
    },
    stateFormat(applyState) {
      switch (applyState) {
        case "0":
          return "未审核";
        case "1":
          return "审核中";
        case "2":
          return "审核通过 等待邮寄";
        case "3":
          return "审核不通过";
        case "4":
          return "已邮寄,待退还";
        case "5":
          return "已退还";
        case "6":
          return "已完成";
        case "7":
          return "已撤销";
      }
    },

    // 获取待寄出列表
    getPendingOperations() {
      findMyCerApplyPendingOperations(this.certificateApplyRequest).then(
        (res) => {
          if (res.data.success) {
            console.log("res", res.data.data.resultList);
            this.applyData = res.data.data.resultList;
          }
        }
      );
    },
    // 分页
    handerPage(val) {
      // this.page.pageNum = val
      // this.findCertificateApplyBriefFun(this.page, this.applyStateList,this.certificateApply);
    },

    // 寄出按钮
    sendOff(applyId){
      const routePath = {
        name: "certificateRSDetail",
        query: { applyId: applyId,state: "1"},
      };
      this.$router.push(routePath);
    }
  },
  mounted() {
    this.getPendingOperations();
  },
  computed: {
    // 组装证书名称
    getCerName() {
      return function (certificateList) {
        console.log("new certificateList", certificateList);
        const certificateName = [];
        certificateList.forEach((item) => {
          certificateName.push(
            (item.levelName || "") +
              (item.typeName || "") +
              (item.specialtyName || "")
          );
        });
        return certificateName;
      };
    },
  },
};
</script>

<style>
</style>