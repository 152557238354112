<template>
 <div>
  <div>
    <table style="width: 1098px"><colgroup><col style="width: 504px"><col style="width: 594px"></colgroup><tbody><tr><th colspan="1" rowspan="1" colwidth="504"><p>3</p></th><th colspan="1" rowspan="1" colwidth="594"><p>3</p></th></tr></tbody></table><p>AiEditor 是一个面向 AI 的开源富文本编辑器。</p>
    <button @click="getHtmlContent">获取HTML内容</button>
  </div>
  <div ref="divRef" style="height: 600px">
    <table style="width: 1098px"><colgroup><col style="width: 504px"><col style="width: 594px"></colgroup><tbody><tr><th colspan="1" rowspan="1" colwidth="504"><p>3</p></th><th colspan="1" rowspan="1" colwidth="594"><p>3</p></th></tr></tbody></table><p>AiEditor 是一个面向 AI 的开源富文本编辑器。</p>
  </div>
  
 </div>
</template>

<script setup lang="ts">
import {AiEditor} from "aieditor";
import "aieditor/dist/style.css"
import {onMounted, onUnmounted, ref} from "vue";

const divRef = ref();
let aiEditor: AiEditor | null = null;

onMounted(() => {
  aiEditor = new AiEditor({
    element: divRef.value as Element,
    placeholder: "点击输入内容...",
    content: '',
  })
})

onUnmounted(() => {
  aiEditor && aiEditor.destroy();
})

const getHtmlContent = () => {
  if (aiEditor) {
    const htmlContent = aiEditor.getHtml();
    console.log(htmlContent);
    // console.log(aiEditor.getMarkdown())
  } else {
    console.error("Editor not initialized");
  }
};

</script>