var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.business.length > 0)?_c('div',[_c('el-carousel',{attrs:{"interval":4000,"type":"card","height":"300px"}},[_c('el-carousel-item',[_c('img',{staticClass:"slideshow",attrs:{"src":"https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E8%BD%AE%E6%92%AD%E5%9B%BE1.png"}})]),_c('el-carousel-item',[_c('img',{staticClass:"slideshow",attrs:{"src":"https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E8%BD%AE%E6%92%AD%E5%9B%BE2.png"}})]),_c('el-carousel-item',[_c('img',{staticClass:"slideshow",attrs:{"src":"https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E8%BD%AE%E6%92%AD%E5%9B%BE1.png"}})])],1),_vm._m(0),_c('div',{staticClass:"project"},[_c('div',{staticClass:"project-title"},[_vm._m(1),_c('p',{staticStyle:{"font-size":"17px","color":"#333"}},[_vm._v(" 业务体系涵盖多个方面，其核心目标是确保工程项目的成本控制、质量管理和进度执行，以实现项目的高效、经济、质量可控。 ")]),_c('div',{staticClass:"more-public top-more"},[_c('router-link',{attrs:{"to":{
              name: 'clientBusinessDetail',
              query: { labelType: 1, labelId: _vm.business[0].labelId },
            }}},[_vm._v(" 更多 "),_c('i',{staticClass:"iconfont more-font"},[_vm._v("")])])],1)]),_c('ul',_vm._l((_vm.business),function(item){return _c('li',{key:item.businessId,on:{"click":function($event){return _vm.showBusinessDetail(item)}}},[_c('div',{staticClass:"business-title"},[_c('i',{staticClass:"iconfont business-font",domProps:{"innerHTML":_vm._s(item.icon)}}),_c('span',[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"business-describe"},[_vm._v(" "+_vm._s(item.describeInfo)+" ")])])}),0)]),_c('div',{staticClass:"classic-case"},[_c('div',{staticClass:"classic-title"},[_vm._m(2),_c('p',{}),_c('div',{staticClass:"more-public top-more"},[_c('router-link',{attrs:{"to":{
                name: 'clientCaseArticleList',
                query: { labelType: 2, labelId: _vm.caseArr[0].labelId },
              }}},[_vm._v(" 更多 "),_c('i',{staticClass:"iconfont more-font"},[_vm._v("")])])],1)]),_c('div',{staticClass:"caseList"},_vm._l((_vm.caseArr),function(item,index){return _c('div',{key:item.id,class:['caseItem', _vm.caseClass(index)],on:{"click":function($event){return _vm.showCaseDetail(item)}}},[_c('img',{attrs:{"src":item.coverImg}}),_c('span',{staticClass:"case_name"},[_vm._v(_vm._s(item.titleBreviary))])])}),0)]),_c('div',{staticClass:"news-and-conpany"},[_c('div',{staticClass:"conpany-dynamic"},[_c('div',{staticClass:"buttom-title"},[_vm._m(3),_c('div',{staticClass:"more-public top-more"},[_c('router-link',{attrs:{"to":{
                name: 'clientArticleList',
                query: { labelType: 3, labelId: _vm.news[0].labelId },
              }}},[_vm._v(" 更多 "),_c('i',{staticClass:"iconfont more-font"},[_vm._v("")])])],1)]),_c('ul',_vm._l((_vm.news),function(item,index){return _c('li',{key:item.id,on:{"click":function($event){return _vm.showArticleDetail(item)}}},[_c('div',{staticClass:"serial-number"},[_vm._v(_vm._s(index + 1))]),_c('div',{staticClass:"serial-text"},[_c('span',[_vm._v(_vm._s(item.title))]),_c('span',[_vm._v(_vm._s(item.createTime))])])])}),0)]),_c('div',{staticClass:"news"},[_c('div',{staticClass:"buttom-title"},[_vm._m(4),_c('div',{staticClass:"more-public buttom-more"},[_c('router-link',{attrs:{"to":{
                name: 'clientArticleList',
                query: { labelType: 4, labelId: _vm.knowledge[0].labelId },
              }}},[_vm._v(" 更多 "),_c('i',{staticClass:"iconfont more-font"},[_vm._v("")])])],1)]),_c('ul',_vm._l((_vm.knowledge),function(item,index){return _c('li',{key:item.id,on:{"click":function($event){return _vm.showArticleDetail(item)}}},[_c('div',{staticClass:"serial-number"},[_vm._v(_vm._s(index + 1))]),_c('div',{staticClass:"serial-text"},[_c('span',[_vm._v(_vm._s(item.title))]),_c('span',[_vm._v(_vm._s(item.createTime))])])])}),0)])])],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutOurBox",attrs:{"id":"aboutOur"}},[_c('h2',[_c('i',{staticClass:"iconfont h2-font"},[_vm._v("")]),_vm._v("关于我们")]),_c('div',{staticClass:"aboutOur"},[_c('div',{staticClass:"aboutOurText"},[_c('span',{staticClass:"aboutOurTitle"},[_vm._v("江西省华旭工程咨询有限公司")]),_c('p',{staticClass:"aboutOurDescribe"},[_vm._v("   本公司成立于2019年，是一家专注于为客户提供全方位工程咨询服务的领先企业。业务范围包括编制项目建议书与可行性研究报告，以及投资估算、工程预算等，项目实施过程中可以完成全过程跟踪服务与工程质量监理服务，以及工程结算的评审与项目经济评价等。我们致力于通过专业的咨询和技术解决方案，帮助客户实现项目目标，提升项目价值，以及降低风险。我们的团队由经验丰富、充满创新精神的工程团队组成，具备跨行业、跨领域的专业知识和丰富的项目实践经验。 ")]),_c('div',{staticClass:"downloadBrochure-box"},[_c('div',{staticClass:"downloadBrochure"},[_c('img',{attrs:{"src":"https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/pdf%E6%96%87%E4%BB%B6.png"}}),_c('a',{attrs:{"href":"https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/%E5%8D%8E%E6%97%AD%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E5%86%8C.pdf"}},[_vm._v("华旭企业宣传册.pdf  下载")])])])]),_c('div',{staticClass:"aboutOurImg"},[_c('img',{attrs:{"src":require("../../assets/imgs/manageImg.jpg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('i',{staticClass:"iconfont h2-font"},[_vm._v("")]),_vm._v("业务体系")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('i',{staticClass:"iconfont h2-font"},[_vm._v("")]),_vm._v("案例推荐")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('i',{staticClass:"iconfont h2-font"},[_vm._v("")]),_vm._v("新闻动态")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('i',{staticClass:"iconfont h2-font"},[_vm._v("")]),_vm._v("知识分享")])
}]

export { render, staticRenderFns }