// 留言信息提交

import http from "../utils/request"


// 创建留言信息
export function addLeaverMessage(data = {}){
    return http({
        method:'GET',
        url:'/leaver_message/add_message',
        params:data
})
}

// 回复留言信息
export function replyMessage(data = {}){
    return http({
        method:'GET',
        url:'/leaver_message/reply_message',
        params:data
})
}

// 根据留言id 查找回复留言列表
export function queryReplyMessageList(data = {}){
    return http({
        method:'GET',
        url:'/leaver_message/query_replyMessage',
        params:data
})
}

// 查询留言列表
export function queryLeaverMessageList(data = {}){
    return http({
        method:'GET',
        url:'/leaver_message/query_message',
        params:data
})
}