import http from "../utils/request"

    // 根据类型id查询侧边标签列表
    export function queryLabels(data){
        return http({
            method:'POST',
            url:'/client_label/queryLabelsByLabelType',
            params:data
    })
}

// 根据侧边标签类型id查询右侧展示数据
export function queryLabelDetail(data){
    return http({
        method:'POST',
        url:'/client_label/queryLabelDetail',
        params:data
})
}
  