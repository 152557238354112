export default{
    state: {
        // asideDeatilData:[],
        // articleArr:[],
        asideDeatilData:{
            articleArr:[],
            businessArr:[],
            businessitem:{}
        },
        


    },
    mutations: {
        
        // 设置articleArr数据
        setArticleArrData(state,val){
            state.asideDeatilData.articleArr = val
        },

         // 设置business数据
         setBusinessArrData(state,val){
            // 这里用了push为了防止数据爆满，这里要进行判断，如果数组中有数据，则不进行push
             // 检查 businessArr 中是否已经存在相同 businessId 的对象
        const existingIndex = state.asideDeatilData.businessArr.findIndex(item => item.businessId === val.businessId);
        
        // 如果不存在相同 businessId 的对象，则进行 push 操作
        if (existingIndex === -1) {
            state.asideDeatilData.businessArr.push(val);
        }
        state.asideDeatilData.businessitem = val
            // state.asideDeatilData.businessArr.push(val)
        }
        
    }
}