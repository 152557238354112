import http from "../utils/request"

// 查询证书类型列表
export function findCertificateTypeList(data = {}){
    return http({
        method:'GET',
        url:'/certificateType/certificateTypeList',
        data
    })
}
