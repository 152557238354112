// 证书标签
export default{
    state: {
        certificateTypeList:[],
        certificateSpecialtyList:[]
    },
    mutations: {
        
        // 设置userinfo数据
        setCertificateTypeData(state,val){
            state.certificateTypeList = val
        },
        setCertificateSpecialtyData(state,val){
            state.certificateSpecialtyList = val
        }
        
    }
}