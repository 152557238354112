// 获取oss配置文件
import http from "../utils/request"


// 获取oss信息
export function getOSSInfo(data = {}){
    return http({
        method:'POST',
        url:'/file/getOSSInfo',
        data
    })

}