export default{
    state: {
        current:{
            userId:'',
            userName:'',
            userSex:'',
			userAccount:'',
			companyName:'',
			departmentName:'',
			userJob:'',
			userPhone:'',
			userIdNumber:'',
			userState:'',
			userType:'',
			userBankCardId:'',
			entryTime:'',
			headImg:'',
			email:''
        }
    },
    mutations: {
        
        // 设置userinfo数据
        setUserData(state,value){
            // state.current.userId = val.userId
            // state.current.userName = val.userName
            // state.current.userAccount = val.userAccount


            state.current.userId = value.usId ?? state.current.userId;
			    state.current.userName = value.userName ?? state.current.userName;
			    state.current.userSex = value.userSex ?? state.current.userSex;
			    state.current.userAccount = value.userAccount ?? state.current.userAccount;
			    state.current.companyName = value.companyName ?? state.current.companyName;
			    state.current.departmentName = value.departmentName ?? state.current.departmentName;
			    state.current.userJob = value.userJob ?? state.current.userJob;
			    state.current.userPhone = value.userPhone ?? state.current.userPhone;
			    state.current.userIdNumber = value.userIdNumber ?? state.current.userIdNumber;
			    state.current.userState = value.userState ?? state.current.userState;
			    state.current.userType = value.userType ?? state.current.userType;
			    state.current.userBankCardId = value.userBankCardId ?? state.current.userBankCardId;
			    state.current.entryTime = value.entryTime ?? state.current.entryTime;
			    state.current.email = value.email ?? state.current.email;
			    state.current.headImg = value.headImg ?? state.current.headImg;
        }
        
    }
}