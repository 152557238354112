import http from "../utils/request"

// export  function getUserinfo(id){
//    return http({
//         method:'GET',
//         url:`/user/getuserinfo/${id}`
//     })
// }


// 分页查询公司列表
export function queryCompanyList(data){
    return http({
        method:'POST',
        url:'/company/company_list_page',
        data:data
    })
}

// 查询分公司信息
export function queryAllCompany(data){
    return http({
        method:'POST',
        url:'/company/company_list',
        data:data
    })
}

// // 添加用户
// export function addUser(data){
//     return http({
//         method:'POST',
//         url:'/user/addUser',
//         data:data
//     })
// }

// // 切换用户状态
// export function switchState(data){
//     return http({
//         method:'POST',
//         url:'/staff/switchState',
//         params:data
//     })
// }

// // 更改用户角色
// export function updateUserRole(data = {}){
//     return http({
//         method:'GET',
//         url:'/user/updateUserRole',
//         params:data
//     })
// }

// // 删除单个用户
// export function deleteUser(data = {}){
//     return http({
//         method:'GET',
//         url:'/staff/deleteUser',
//         params:data
//     })
// }

// // 批量删除用户
// export function batchDeleteUser(data = {}){
//     return http({
//         method:'POST',
//         url:'/staff/batchDeleteUser',
//         data
//     })
// }