<template>
  <div class="login-contain">
    <div class="login-box">
      <div class="left-animation">
      
      <img src="../../src/assets/imgs/c38d1266-d4dd-478c-947a-1468a95b0f9a.png"/>
    </div>
    <el-form
      ref="form"
      label-width="90px"
      :inline="true"
      class="login-form"
      :model="form"
      :rules="rules"
    >
      <div class="login-company-title">
        <img src="/logo.png" />
        <h3 class="login-title">华旭后台系统登录</h3>
      </div>
      <el-form-item label="用户名" prop="userAccount">
        <el-input
          v-model="form.userAccount"
          placeholder="请输入账号"
          @keyup.enter.native="submit()"
        ></el-input>
      </el-form-item>
      <el-form-item label="密  码" prop="userPwd">
        <el-input
          type="password"
          data="passworedtype"
          v-model="form.userPwd"
          @keyup.enter.native="submit()"
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <div class="verificationCode">
        <el-form-item
          style="display: flex; align-items: center; margin: 0"
          label="验证码"
          prop="verificationCode"
        >
          <el-input
            type="text"
            v-model="form.verificationCode"
            style="width: 125px"
            @keyup.enter.native="submit()"
            placeholder="验证码"
          ></el-input>
        </el-form-item>
        <img
          @click="fetchCaptchaImage"
          :src="captchaImageUrl"
          
        />
      </div>
      <el-form-item>
        <el-button @click="submit" class="submit" type="primary"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
    </div>

  </div>
</template>

<script>
import { getCaptchaImage, login } from "../api/login";
import Cookie from "js-cookie";
import { Message } from "element-ui";
export default {
  data() {
    return {
      captchaImageUrl: "",

      form: {
        userAccount: "",
        userPwd: "",
        verificationCode: "",
      },
      rules: {
        userAccount: [
          // { required: true, message: "请输入用户名", trigger: "blur" },
          {
            required: true,
            min: 6,
            max: 12,
            message: "长度在 6 到 12 个字符",
            trigger: "blur",
          },
        ],
        userPwd: [
          // { required: true, message: "请输入密码", trigger: "blur" },
          {
            required: true,
            min: 0,
            max: 12,
            message: "长度在 6 到 12 个字符",
            trigger: "blur",
          },
        ],
        verificationCode: [
          // { required: true, message: "请输入密码", trigger: "blur" },
          {
            required: true,
            min: 4,
            max: 4,
            message: "长度在4个字符",
            trigger: "blur",
          },
        ],
      },
      alert: {
        type: "success",
        show: true,
      },
      dialogVisible: true,
    };
  },
  mounted() {
    // this.fetchCaptchaImage();
    this.fetchCaptchaImage();
  },
  methods: {
    // 登录按钮
    submit() {
      // 用户提交表单，先判断是否全部填写完,如果通过规则valid = true
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 验证成功向后端提交数据===========================================

          login(this.form)
            .then((response) => {
              console.log(response.data);
              if (response.data.success) {
                console.log("登陆成功！！！");
                const token = response.data.data;
                const tokenDate = response.data.tokenDate / 86400;
                // 将后端返回的token信息存入cookie用于不同页面间通信
                Cookie.set("token", token, { expires: tokenDate });
                this.$router.push("/home");
              } else {
                Message.error(response.data.message);
                this.fetchCaptchaImage();
              }
            })
            .catch((error) => {
              console.error(
                "There has been a problem with your fetch operation:",
                error
              );
            });

          // 一般使用cookie保存token数据=================================================

          // Cookie.set("token", token);
          // 根据用户的权限获取菜单数据 存入store
        }
      });
    },
    // 刷新验证码图片
    fetchCaptchaImage() {
      getCaptchaImage()
        .then((response) => {
          // 创建一个URL对象来表示图片
          const imageUrl = URL.createObjectURL(response.data);
          // 将图片URL绑定到Vue数据上，以在前端显示
          this.captchaImageUrl = imageUrl;
          console.log(response.data);
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
    },
  },
};
</script>

<style lang="less" scoped>
.login-contain{
position: fixed;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
//background-image: url('../../src/assets/imgs/4ratliszkp1.jpg');
background-size: cover;
background-repeat: no-repeat;
}
.login-box{
  width: 950px;
  display: flex;
  justify-content: center;
  // border: 1px solid #000000;
  // margin: 180px auto;
  padding: 35px;
  // background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 25px #cac6c6;
  box-sizing: border-box;

  background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(30px);
    // border-radius: 10px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // color: white;
    // font-size: 24px;
    // text-align: center;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.left-animation{
  width: 350px;
  // background-color: red;
  box-sizing: border-box;
}
@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px); /* 上浮10像素，可以根据需要调整 */
  }
}
.left-animation img{
  width: 110%;
  animation: floatAnimation 1.5s ease-in-out infinite alternate;
}
.login-company-title {
  display: flex;
  align-items: center;
  // background-color: red;
}
.login-company-title img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}
.login-title {
    text-align: center;

    // margin-bottom: 20px;
    color: #505458;
  }
.login-form {
  width: 350px;
  // box-sizing: border-box;

  .el-input {
    width: 198px;
  }
}
.submit {
  margin-left: 65px;
  width: 250px;
  margin-top: 20px;
}
// .el-form-item__content{
//   display: flex !important;
//   background-color: red;
// }

.verificationCode {
  width: 80%;
  display: flex;
  // background-color: red;
  justify-content: space-between;
  align-items: center;
}
.verificationCode img{
  margin-right: 11px;
  width: 80px; 
  height: 40px;
}

.login-form {
  width: 450px !important;
  display: flex;
  flex-direction: column;
}
.loginAlert {
  width: 350px;
  margin: 0 auto;
  height: 60px;
}
.login-form .el-input{
  width: 260px;
}
</style>
<style>
.login-form{
  .el-form-item__label{
    color: #000;
  }
}
.verificationCode{
  .el-input__inner{
    width: 130%;
  }
}

</style>