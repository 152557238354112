// axios的二次封装

import axios from 'axios'
import Cookie from "js-cookie";
import { Message } from "element-ui";
import router from '@/router';
const http = axios.create({
    // 请求地址前缀
    baseURL:'https://huaxugczx.com:8666/huaxu-company',
    // baseURL:'http://localhost:8666/huaxu-company',
    // 超时时s
    timeout:15000
})


// 添加请求拦截器
http.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么  所有请求头都带上token
    console.log("触发请求拦截器")
    config.headers['Token'] = Cookie.get('token')
    config.headers['Client-Type'] = 'Web'
    return config;
  }, function (error) {
    // 对请求错误做些什么
      // this.$router.push('/login')
    return Promise.reject(error);
  });

// 添加响应拦截器
http.interceptors.response.use(function (response) {
    // 对响应数据做点什么


    return response;
  }, function (error){
    // 对响应错误做点什么
    console.log("响应错误")
    
    // router.push('/login')
    // Cookie.remove('token')

    return Promise.reject(error);
  });
export default http
