<template>
  <div>
    <el-container>
      <el-aside width="auto">
        <common-aside/>
      </el-aside>
      <el-container>
        <el-header>
            <common-header/>
        </el-header>
        <el-main style="overflow-y: auto;height: calc(100vh - 230px);">
          <!-- <keep-alive> -->
            <router-view></router-view>
          <!-- </keep-alive> -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
  
  <script>
import CommonAside from '@/components/CommonAside.vue';
import CommonHeader from '@/components/CommonHeader.vue';
export default {
    components:{
        CommonAside,
        CommonHeader
    }
};
</script>
  
<style>
.el-header{
    padding: 0;
}
.el-menu{
  border: none;
}
</style>./Main.vue