<template>
  <div>
    <div class="mange-header">
      <div style="width: 500px;">
        <!-- <el-button type="primary"  @click="addUser"><i class="el-icon-plus">&nbsp;</i>新增</el-button> -->
       <el-button type="warning"><i class="el-icon-download">&nbsp;</i>导出</el-button>
       <!-- <div> -->
        <el-button type="danger" @click="batchDeleteUserDialog()"><i class="el-icon-delete">&nbsp;</i>批量删除</el-button>
       <!-- </div> -->
      </div>

      <!-- form搜索区 -->
      <el-form style="width: 700px;display: flex;align-items: center;"  :model="selectForm">
        <!-- <el-form-item> -->
          <el-form-item style="width: 150px;margin-right: 20px;">
          <el-select v-model="selectForm.caseType"  placeholder="选择项目类型">
            <!-- <el-option label="空" value=""></el-option> -->
            <el-option label="甲方" value="1"></el-option>
            <el-option label="乙方" value="2"></el-option>
          </el-select>
        </el-form-item>
          <el-form-item style="width: 150px;margin-right: 20px;">
          <el-select v-model="selectForm.serviceType"  placeholder="选择服务类型">
            <!-- <el-option label="空" value=""></el-option> -->
            <el-option label="土建" value="1"></el-option>
          <el-option label="安装" value="2"></el-option>
          <el-option label="市政" value="3"></el-option>
          <el-option label="公路" value="4"></el-option>
          <el-option label="水利" value="5"></el-option>
          <el-option label="其他" value="6"></el-option>
            </el-select>
          </el-form-item>
        <!-- </el-form-item> -->
        <el-form-item>
          <el-input style="width: 190px;margin-right: 20px;" placeholder="请输入项目名(模糊搜索)" v-model="selectForm.caseName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    
    
    <el-table
    v-if="dataIsSuccess"
      :data="caseListData"
      height="90%"
      @selection-change="handleSelectionChange"
    >
    <el-table-column
      type="selection"
      style="width: 2%;"
      >
    </el-table-column>
      <el-table-column prop="caseName"  label="项目名" width="380px" ></el-table-column>
      <el-table-column prop="caseType" :sortable=true label="项目类型" width="120px" :formatter="caseFormat"> </el-table-column>
      <el-table-column prop="serviceType" :sortable=true label="服务类型" width="110px" :formatter="serviceFormat">
      </el-table-column>
      <el-table-column prop="userState" :sortable=true label="状态" width="110px">
        <template slot-scope="scope">
          <el-tag :type="scope.row.caseState === '1' ? '' : scope.row.caseState === '2' ?'info' :'success'">{{scope.row.caseState === '1' ? '进行中' : scope.row.caseState === '2' ?'已暂停' :'已完成'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="项目地址" style="width: 12%;"> </el-table-column>
      <el-table-column prop="dataScopedText" label="项目周期" style="width: 25%;">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" style="width: 10%;">
      </el-table-column>


      <el-table-column prop="addr" label="操作" style="width: 15%;" fixed="right">
        <!-- slot-scope="scope" 定义了插槽的作用域时 在插槽内部就可以通过 scope 对象来访问到父组件中的数据 这个scope 对象是vue传来的  -->
        <template slot-scope="scope">
          <el-button style="" size="mini" @click="handleEdit(scope.row)" >详情</el-button>
          <el-button style="" size="mini" @click="selectProcess(scope.row)" >过程</el-button>
          <!-- <el-button type="warning" plain style="" size="mini" @click="handleSwitchState(scope.row)">{{ scope.row.userState === '1' ? '禁用' : '启用' }}</el-button> -->
          <el-button type="danger" size="mini" @click="handleDelete(scope.row)">删除</el-button>
          <!-- <el-button type="primary" size="mini" @click="updateUserRow(scope.row)" icon="el-icon-user"></el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex;justify-content: flex-end;align-items: center;"> 
      <span>共{{total}}条</span>
      <el-pagination background layout="prev, pager, next" :current-page="currentPage" :page-size="pageSize" :total="total" @current-change="handerPage">
    </el-pagination>
   </div>
   <!-- ========================= 查看过程 提示框========================= -->
   <el-dialog title="查看过程" :visible.sync="handleScheduleDialogVisible" width="80%" >
    <el-empty description="暂无内容，添加一些内容吧" v-if="processData.length==0"></el-empty>

    <el-button type="primary" style="position: absolute;top: 10px;left: 160px;" @click=" caeateProcess()" >创建过程&nbsp;<i class="el-icon-plus"></i></el-button>
<div class="block">
  <el-timeline >
    <!-- item.createTime.split(' ')[0]  通过空格截取日期前部分  -->
    <el-timeline-item v-for="item in processData" :key="item.processId" :timestamp="item.createTime.split(' ')[0]" placement="top">
      <el-card style="padding: 0 20px;">
        <template v-if="!item.caeateProcessVisible">
          <!-- <i class="el-icon-edit" style="position: absolute;top: 41px;left: 75px;font-size: 22px;"></i> -->
        <h2>{{ item.processTitle }}</h2>
        <h4>{{ item.processDescribe }}</h4>
        <!-- <div class=""></div> -->
        <!-- 过程详情文件列表的展示 -->
        <ul class="show-files">
          <li v-for="file in item.fileInfoList" :key="file.fileId"  @mouseover="file.showDiv = true" @mouseout="file.showDiv = false" class="show-file-item">
            <div class="show-file-box">
              <!-- 'http://192.168.102.100/'+file.groupName+'/'+file.remoteFilePath -->
              <!-- <img :src="getFileImg(file.groupName,file.remoteFilePath)"/> -->
              <img :src="getFileImg(file.fileUrl)"/>
              <div v-show="file.showDiv" class="show">
                <i @click="showGigImg(file)" class="el-icon-zoom-in"></i>
                <i @click="downloadFile(file)" class="el-icon-download"></i>
                <!-- <i class="el-icon-delete"></i> -->
              </div>
            </div>
            <div class="show-file-name">{{ file.oldName }}</div>
          </li>
        </ul>
        <p>{{ item.userName }}&nbsp;&nbsp;提交于{{ item.createTime }}</p>
        </template>
        <template v-if="item.caeateProcessVisible">

        
        <el-form style="width: 600px;" :model="processFrom" :rules="rules">
          <el-form-item prop="processTitle">
          
            <span style="color: red;">*</span>标题：<el-input style="margin-top: 10px;width: 500px;" v-model="processFrom.processTitle" placeholder="请输入标题" ></el-input>
          </el-form-item>
          <el-form-item>
            
           详情： <el-input style="margin-top: 10px;width: 500px;" v-model="processFrom.processDescribe" placeholder="请输入详情" ></el-input>
          </el-form-item>
          </el-form>
<el-upload  
  class="upload-demo"  
  ref="upload"
  style="margin: 15px 0 0 30px;"  
  action="http://localhost:8666/huaxu-company/file/upload_file"  
  list-type="picture-card"
  :on-success="uploadSucess"
  :file-list="fileList"  
  :auto-upload="true"  
  :multiple="true"  
  >  
  <i slot="default" class="el-icon-plus"></i>  
  <div slot="file" slot-scope="{file}">
      <div class="image-container">
        <img :src="file.url" alt=""/> 
      </div>  
    <span class="el-upload-list__item-name">{{ file.name }}</span>  
    <span class="el-upload-list__item-actions">  
      
      <span  
        class="el-upload-list__item-preview"  
        @click="handlePictureCardPreview(file)"  
      >  
        <i class="el-icon-zoom-in"></i>  
      </span>  
      
      <span  
        v-if="!disabled"  
        class="el-upload-list__item-delete"  
        @click="handleRemove(file,fileList)"  
      >  
        <i class="el-icon-delete"></i>  
      </span>  
    </span>  
  </div>  
</el-upload>
<div style="width: 100%;display: flex;justify-content: end;">
<el-button type="info" @click="cancelProcess()">取消</el-button>
<el-button type="primary" @click="addProcessFun()">添加</el-button>

</div>
</template>
      </el-card>
    </el-timeline-item>
  </el-timeline>
</div>


      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleScheduleDialogVisible = false">确 定</el-button>
        <!-- <el-button type="primary" @click="submitUserRole()">确 定</el-button> -->
      </span>
    </el-dialog>
    <!-- ========================= 查看过程提示框========================= -->
    <!-- ========================= 查看大图提示框========================= -->
    <el-dialog :visible.sync="dialogVisible">
  <img width="100%" :src="dialogImageUrl" alt="">
</el-dialog>
<!-- ========================= 查看大图提示框========================= -->
  </div>
</template>

<script>
import {searchCase,delCaseInfo} from "../api/case"
import { nanoid } from 'nanoid';
import { Message } from "element-ui";
import {queryProcessList,addProcess} from "../api/process"
import OSS from 'ali-oss';
import {uploadFileFun} from "../utils/downloadFileUtil"
export default {
  data(){
    return{
      SelectionChangeArr:[],//复选框
      dialogImageUrl: '',
      fileList:[],
      dialogVisible: false,
      disabled: false,
      isedit:false,//是否在编辑状态
      selectForm:{//条件搜索
        caseName:"",
        caseType:"",
        serviceType:"",
        dateScoped:""
      },
      processFrom:{//过程信息
        caseId:'',
        processTitle:'',
        processDescribe:'',
        fileNameList:[],
        fileIdList:[]
      },
      processData:[
      ],//项目过程信息
      caseListData:{},
      dataIsSuccess:false,//数据是否加载完成
      total:0, //总条数
      pageSize:10,//每页显示的条数
      currentPage:1,//当前页数
      handleScheduleDialogVisible:false,//查看过程弹窗
      showDiv:false,//展示文件蒙版div
      rules: {
        processTitle: [
          { required: true, message: "请输入过程标题",},
        ],
      },
    }
  },
  methods:{

  

    changeFile(file,fileList){
      console.log('文件改变')
      console.log(this.$refs.upload)
    },

    // 下载文件
    downloadFile(file){
      //调用util工具类下载文件，传入文件对象
      uploadFileFun(file)
    },
        // 移除文件
    //     removeFile(file, fileList){
      
    //   // this.fileList = fileList
    //   console.log(fileList)

    //   console.log("移除文件")
    //   console.log(file)
    // },

    // searchCase({pageSize,pageNum,caseName,caseType,serviceType,dateScoped}).then((response) => {
    //         // HTMLFormControlsCollection.log()
    //   if(response.data.success){
    //     this.dataIsSuccess = true
    //     this.total = response.data.data.totalNum
    //   }else{
    //     Cookie.remove("token");
    //     Message.error("失败，请刷新浏览器");
    //   }
    //   this.caseListData = response.data.data.resultList
    //   })

    // 加载过程图片详情
    showGigImg(file){
      this.dialogVisible = true
      this.dialogImageUrl = file.fileUrl
    },

    // 加载过程的图片文件，根据图片类型来加载预览
    getFileImg(fileUrl){
      const extension = fileUrl.split('.').pop();  //移除数组最后一个元素
      const fileType = extension.toLowerCase();  //toLowerCase()将字符转为小写形式

      switch (fileType) {  
  case 'docx':
  case 'doc':  
      return "http://192.168.102.100/group1/M00/00/02/wKhmZGYKvjiAEXmpAAAVR1rTto4142.png"
  case 'pptx':
  case 'ppt':  
  return "http://192.168.102.100/group1/M00/00/02/wKhmZGYKvXuALl8YAAAPtkZNvi0624.png" 
      break;
  case 'pdf':  
  return "http://192.168.102.100/group1/M00/00/02/wKhmZGYKvbaAABjMAAASLI4ewds879.png"   
      break;   
  case 'xlsx':
  case 'xls':  
      return "http://192.168.102.100/group1/M00/00/02/wKhmZGYKvtaAPz7lAAAZennX2dk189.png"
      break; 
  case 'zip':
  case 'rar':   
     return "http://192.168.102.100/group1/M00/00/02/wKhmZGYKvDCAJNuoAAAL1iCzwhk855.png"  
      break; 
  case 'png': 
  case 'jpg':
  case 'jpeg':
  case 'bmp':
  case 'gif':
    return  fileUrl
  default:  
      return "http://192.168.102.100/group1/M00/00/02/wKhmZGYKvl-AYAc2AAAMqe_wjUg099.png" 
}
    },
    queryProcessRequest(){
      queryProcessList({caseId:this.processFrom.caseId}).then((response) =>{
      if(response.data.success){
        console.log("查询到过程列表")
        console.log(response.data)



        this.processData = response.data.data.map(item => {
  const updatedFileInfoList = item.fileInfoList.map(fileInfo => ({
    ...fileInfo,
    showDiv: false // 添加一个名为 show 的属性控制每个图片的蒙版div，并设置为 true
  }));
  
  return {
    ...item,
    fileInfoList: updatedFileInfoList
  };
});
console.log("最新的this.processData")
console.log(this.processData)


        this.handleScheduleDialogVisible = true
      }else{
        Message.error("查询当前项目过程失败！");
      }
    })
    },
  // 点击查看项目过程列表
  selectProcess(row){
    this.processFrom.caseId = row.caseId
    this.queryProcessRequest();
  },

  // 获取当前日期方法
 getCurrentDate() {
  // 创建一个新的 Date 对象，它将包含当前日期和时间
  const currentDate = new Date();

  // 获取当前年份
  const year = currentDate.getFullYear();

  // 获取当前月份（注意：月份是从 0 开始计数的，所以需要加 1）
  const month = currentDate.getMonth() + 1;

  // 获取当前日期
  const day = currentDate.getDate();

  // 将年、月、日拼接成所需格式
  const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;

  return formattedDate;
},

    // 点击创建过程按钮
    caeateProcess(){
      if(this.isedit){
        Message.error("请完成当前过程的创建！");
      }else{
        this.isedit = true
      this.processData.unshift({
      processId: nanoid(),
      title: '新的标题',
      describe: '新的描述',
      createTime: this.getCurrentDate(),
      caeateProcessVisible: true 
     });
    }
    },

    // 取消创建过程
    cancelProcess(){
      this.isedit = false
      this.processData.shift();
    },

    // 提交创建过程信息  1.提交过程表单 2.修改过程文件信息
    addProcessFun(){
      //  this.processFrom.fileNameList = this.fileList.map(item =>{
      //   return item.response.data
      // })
      console.log(this.processFrom)
      addProcess(this.processFrom).then((response) =>{
      if(response.data.success){
        Message.success(response.data.message)
        
      }else{
        Message.error(response.data.message);
      }
    this.queryProcessRequest();
    })
    this.isedit = false
    this.processData.shift();
    this.processFrom.processTitle = ''
    this.processFrom.processDescribe = ''
    this.processFrom.fileNameList = []
    this.processFrom.fileIdList = []
    this.fileList = []
    // this.processData
    },

    // 移除文件（待检验）
    handleRemove(file,fileList) {
      // 移除按钮被点击，从fileList中移除当前文件（这个数组将是影响当前展示列表）
      this.fileList = this.fileList.filter(item => item.response.data !== file.response.data)

      // 从this.processFrom.fileNameList移除（这个数组将是影响数据库）
      this.processFrom.fileNameList = this.processFrom.fileNameList.filter(item =>item !== file.response.data)
      console.log("fileNameList移除")
      // const uploadInstance = this.$refs.upload;
      console.log(this.processFrom.fileNameList)



            
      // this.fileList = fileList
      console.log("file")
      // const uploadInstance = this.$refs.upload;
      console.log(file)
     
      // console.log(this.processFrom.fileNameList)
      // console.log(file)
      },
      // 查看图片
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
        // 复选框选中时触发
        handleSelectionChange(val){
      this.SelectionChangeArr = val;
      

    },
    // 展示过程
    handleSchedule(row){
      this.handleScheduleDialogVisible = true
    },
    caseFormat(row){
      return row.caseType === '1' ? '甲方' : '乙方';
    },
    serviceFormat(row){
      if(row.serviceType === '1'){
        return '土建'
      }
      if(row.serviceType === '2'){
        return '安装'
      }
      if(row.serviceType === '3'){
        return '市政'
      }
      if(row.serviceType === '4'){
        return '公路'
      }
      if(row.serviceType === '5'){
        return '水利'
      }
      if(row.serviceType === '6'){
        return '其他'
      }
      
    },
    queryCaseListFun(pageSize,pageNum,caseName,caseType,serviceType,dateScoped){
          // 分页搜索用户列表操作
          searchCase({pageSize,pageNum,caseName,caseType,serviceType,dateScoped}).then((response) => {
            // HTMLFormControlsCollection.log()
      if(response.data.success){
        this.dataIsSuccess = true
        this.total = response.data.data.totalNum
      }else{
        Cookie.remove("token");
        Message.error("失败，请刷新浏览器");
      }
      this.caseListData = response.data.data.resultList
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
    },

      // 删除项目
      handleDelete(caseInfo){
      console.log("待删除的项目",caseInfo)
      delCaseInfo({caseId:caseInfo.caseId}).then((res)=>{
        if(res.data.success){

          Message.success(res.data.message)
          this.queryCaseListFun(this.pageSize,this.currentPage,this.selectForm.caseName,this.selectForm.caseType,this.selectForm.serviceType,this.selectForm.dateScoped)
        }
        Message.error(res.data.message);
      })

    },

      // 分页点击时执行 val为页码
    handerPage(val){
      this.queryCaseListFun(this.pageSize,val,this.selectForm.caseName,this.selectForm.caseType,this.selectForm.serviceType,this.selectForm.dateScoped)
    },
    onSubmit(){
      this.queryCaseListFun(this.pageSize,this.currentPage,this.selectForm.caseName,this.selectForm.caseType,this.selectForm.serviceType,this.selectForm.dateScoped)
    },
        // 获取文件名
   getFileType(filename) {  
    const extension = filename.split('.').pop();  //移除数组最后一个元素
    return extension.toLowerCase();  //toLowerCase()将字符转为小写形式
},
        // 上传成功后
  uploadSucess(response, file, fileList){
    this.fileList = fileList
    // 每次文件上传后将文件的uuid保存
    this.processFrom.fileIdList.push(response.data)
    console.log('上传成功this.processFrom.fileIdList')
    console.log(this.processFrom.fileIdList)
// 要根据文件类型，展示预览文件
const fileType = this.getFileType(file.name);
switch (fileType) {  
  case 'docx':
  case 'doc':  
      file.url = "http://192.168.102.100/group1/M00/00/02/wKhmZGYKvjiAEXmpAAAVR1rTto4142.png"
      break;  
  case 'pptx':
  case 'ppt':  
      file.url="http://192.168.102.100/group1/M00/00/02/wKhmZGYKvXuALl8YAAAPtkZNvi0624.png" 
      break;
  case 'pdf':  
      file.url="http://192.168.102.100/group1/M00/00/02/wKhmZGYKvbaAABjMAAASLI4ewds879.png"   
      break;   
  case 'xlsx':
  case 'xls':  
      file.url="http://192.168.102.100/group1/M00/00/02/wKhmZGYKvtaAPz7lAAAZennX2dk189.png"
      break; 
  case 'zip':
  case 'rar':   
  file.url = "http://192.168.102.100/group1/M00/00/02/wKhmZGYKvDCAJNuoAAAL1iCzwhk855.png"  
      break; 
  case 'png': 
  case 'jpg':
  case 'jpeg':
  case 'bmp':
  case 'gif':
      break;  
  default:  
      file.url = "http://192.168.102.100/group1/M00/00/02/wKhmZGYKvl-AYAc2AAAMqe_wjUg099.png" 
}
// file.url = 
},
  },
  mounted(){
    this.queryCaseListFun(this.pageSize,this.currentPage,this.selectForm.caseName,this.selectForm.caseType,this.selectForm.serviceType,this.selectForm.dateScoped)
  },
  watch:{
    handleScheduleDialogVisible(newval,oldval){
      if(newval == false){
        this.isedit = false
      }
    }
  }
}
</script>

<style>
.el-upload-list__item-name{
  z-index: 9999;
  width: 100%;
  text-align: center;
  margin-top: 5px;
  /* position: relative; */
  /* bottom: 0px; */
  /* left: 0px; */
}
.image-container img {  
  width: 100%; /* 图片宽度为容器的100% */  
  height: auto; /* 图片高度自动，以保持原始宽高比 */  
  object-fit: cover; /* 图片内容将覆盖整个容器，同时保持其宽高比 */  
}
.el-upload-list--picture-card .el-upload-list__item {
  overflow: visible !important;
}
.el-upload-list--picture-card .el-upload-list__item-name {
  display: block !important;
}
.upload-demo{
  display: flex;
  flex-wrap: wrap;
}
.el-upload-list{
  display: flex;
  flex-wrap: wrap;
}
.image-container{
  width: 100%;
  height: 146px;
  overflow: hidden;
  display: flex;
  /* flex-direction: column; */
  justify-content: end;
  flex-direction: row !important;
}
.el-upload-list__item{
  margin-bottom: 40px !important;
}
.show-files{
  width: 100%;
  display: flex;
	flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0;
}
.show-file-box{
  width: 146px;
  height: 146px;
  overflow: hidden;
}

.show-files li{
  /* margin-left: 15px; */
  margin: 0 10px 15px 0;
  border-radius: 5px;
  overflow: hidden;
  /* flex: 1; */
  list-style-type: none; 
}
.show-files img {  
  width: 100%; /* 图片宽度为容器的100% */  
  height: auto; /* 图片高度自动，以保持原始宽高比 */  
  object-fit: cover; /* 图片内容将覆盖整个容器，同时保持其宽高比 */  
  border-radius: 5px;
}
.show-file-name{
  width:146px;
  margin-top: 8px;
  white-space: nowrap; /* 防止文本换行 */  
  overflow: hidden; /* 隐藏超出部分 */  
  text-overflow: ellipsis; /* 用省略号表示溢出文本 */  
  text-align: center;
}
.show-file-item {
  position: relative;
}
.show {  
  width: 146px;
  height: 146px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content:space-evenly;
  align-items: center;
  font-size: 22px;
  color: white;
  background-color: #3333337c;
  border-radius: 5px;
}
.show i{
  cursor: pointer;
}


</style>