<template>
  <div style="display: flex;min-height: 600px;width: 100%;padding: 20px;">

    <div class="contant-detail" style="line-height: 45px;width: 100%;" v-html="businessitem.content"></div>
    <!-- <div style="width: 40%;">

    </div> -->
  </div>
</template>

<script>
// import { mapState } from 'vuex'
import {queryLabels,queryLabelDetail} from "@/api/aside_label"
export default {
  data(){
    return{
      businessitem:{}
    }
  },
  // computed: {
  // // ...mapState
  // // ...mapState
  //   ...mapState({
  //     // tags: (state) => state.tab.tabsList,
  //     // userinfo: (state) => state.userinfo.current,
  //     businessitem:(state) => state.client_aside.asideDeatilData.businessitem
  //   }),
  // }

  activated(){
    this.fetchData()
  //  let labelType = this.$route.query.labelType
  //  let labelId = this.$route.query.labelId
  //   queryLabelDetail({labelType:labelType,labelId:labelId}).then((response) =>{
  //     })
  },
  // 使用watch来监听地址栏参数是否发生变化
  watch: {
    '$route.query': {
      handler: 'fetchData',
      deep: true
    }
  },
  methods:{
    fetchData() {
      const labelType = this.$route.query.labelType;
      const labelId = this.$route.query.labelId;
      queryLabelDetail({labelType:labelType,labelId:labelId}).then((response) =>{
        this.businessitem = response.data.data
      })
      // 调用你的方法或API
      // queryLabelDetail({ labelType, labelId })
      //   .then(response => {
      //     // 处理响应数据
      //   })
      //   .catch(error => {
      //     console.error('Error fetching data:', error);
      //   });
    }
  }
}
</script>

<style>
.contant-detail{
  position: relative; 
  min-height: 730px;
}
/* 使用伪类来设置背景图片透明度 */
.contant-detail::after {
    content: ''; /* 必须设置 content 属性才能使伪元素生成 */
    position: absolute; /* 将 ::after 伪元素绝对定位在父元素内 */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://yuan-house.oss-cn-shenzhen.aliyuncs.com/base.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain !important;
    opacity: 0.25; /* 设置 ::after 伪元素的透明度 */
}
</style>