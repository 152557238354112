<template>
  <div class="approval-box" style="width: 100%">
    <div class="header">
      <el-page-header @back="goBack" :content="state == 0 ? '等待证书寄出' : '等待证书入库'"></el-page-header>
    <el-button
            type="primary"
            size="mini"
            class="sendBtn"
            @click="sendOff()"
            :disabled="approvalData.applyState !== '5'"
            >{{ state == 0 ? '确认寄出' : '确认入库' }}
            
            </el-button>
    </div>

    <div class="apply-detail-info">
      <div class="section audit-section">
        <div class="section-title">审核信息</div>
        <el-steps
          :space="200"
          :active="currentPath"
          align-center
        >
          <el-step
            class="approvalStep"
            v-for="(path, index) in approvalData.processPathList"
            :key="index"
            @click.native="changeApproval(index)"
            :title="path.processName"
            :description="path.approval.userName"
          ></el-step>
        </el-steps>
        <div class="audit-item">
            <div>
              <div class="audit-unit">
                <span>{{ selectApproval.processName }}</span>
              </div>
              <div class="audit-persion">
                <p><span>审核人：</span>{{ selectApproval.approval.userName }}</p>
                <p><span>审核时间：</span>{{ selectApproval.approval.createTime }}</p>
                <p><span>审核意见：</span>{{ selectApproval.approval.opinion }}</p>
              </div>
            </div>

            <div class="aduit-stateimg">
              <img :src="getStateimg(selectApproval.approval.approvalState)" />
            </div> 
        </div>
        <!-- </template> -->
      </div>

      <div class="section">
        <div class="addressInfo" v-if="true">
              <h1
                class="changeAddress"
                @click="addressDrawer = true"
                v-if="postInfoList.length == 0"
              >
                请选择邮寄信息
                <i class="el-icon-arrow-right right-arrow"></i>
              </h1>
              <template v-if="!postInfoList.length == 0">
                <h1 class="changeAddress" @click="addressDrawer = true">
                  {{ postInfoList[selectIndex].detailAddress }}
                  <i class="el-icon-arrow-right right-arrow"></i>
                </h1>
                <p>
                  {{ postInfoList[selectIndex].province }}&nbsp;{{
                    postInfoList[selectIndex].city
                  }}&nbsp;{{ postInfoList[selectIndex].district }}&nbsp;{{
                    postInfoList[selectIndex].street
                  }}
                </p>
                <p>
                  {{ postInfoList[selectIndex].userName }}&nbsp;{{
                    postInfoList[selectIndex].userPhone
                  }}
                </p>
              </template>
            </div>
      </div>

      <div class="section">
        <div class="section-title" style="display: inline">收件信息</div>
        <span class="copy-btn" @click="copyAddress(approvalData.postInfo)"
          ><i class="el-icon-document-copy"></i>&nbsp;复制地址</span
        >
        <div class="info-item">
          <i class="el-icon-user"></i>
          收件人: {{ approvalData.postInfo.userName }}
        </div>
        <div class="info-item">
          <i class="el-icon-mobile-phone"></i>
          手机号: {{ approvalData.postInfo.userPhone }}
        </div>
        <div class="info-item">
          <i class="el-icon-location-outline"></i>
          所在地区: {{ approvalData.postInfo.province }}
          {{ approvalData.postInfo.city }} {{ approvalData.postInfo.street }}
        </div>
        <div class="info-item">
          <i class="el-icon-office-building"></i>
          详细地址:
          {{ approvalData.postInfo.detailAddress }}
        </div>
        <div class="info-item">
          <i class="el-icon-tickets"></i>
          备注: {{ approvalData.remark }}
        </div>
      </div>
      <div class="section">
        <div class="section-title">证书信息</div>
        <div
          v-for="certificate in approvalData.certificateList"
          :key="certificate.id"
          class="certificate-item"
        >
          <div class="certificate-info">
            <div>证书编号: {{ certificate.certificateNumber }}</div>
            <div>证书名称: {{ certificate.levelName }}{{ certificate.typeName }}{{ certificate.specialtyName }}</div>
            <div>持有者: {{ certificate.userName }}</div>
            <div>归属公司:</div>
          </div>
          <div
            v-for="file in certificate.fileInfoList"
            :key="file.id"
            class="certificate-images"
          >
            <el-image
              style="
                width: 100px;
                height: 100px;
                margin-left: 15px;
                border-radius: 6px;
              "
              :src="file.fileUrl"
              :preview-src-list="[file.fileUrl]"
            >
            </el-image>
          </div>
        </div>
      </div>
    </div>
    <!-- =====地址侧边栏======== -->
    <el-drawer :visible.sync="addressDrawer" style="position: absolute">
      <template slot="title">
        <div class="custom-drawer-title">
          <p v-show="!isAddPostInfo"></p>
          <el-button
            v-show="isAddPostInfo"
            @click="isAddPostInfo = false"
            type="text"
            class="back-button"
            ><i class="el-icon-arrow-left"></i
          ></el-button>
          <span>{{ isAddPostInfo ? "新增收货信息" : "选择邮寄信息" }}</span>
          <p></p>
        </div>
      </template>
      <!-- <el-form>
        <el-form-item> -->
      <div class="postInfo-container">
        <div
          v-if="isAddPostInfo"
          style="
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <el-form
            :model="postInfoForm"
            :rules="rules"
            label-width="80px"
            ref="postInfoForm"
          >
            <el-form-item prop="address" label="所在地区" style="width: 90%">
              <el-cascader
                placeholder="省、市、区、街道"
                separator="  "
                :props="props"
                clearable
                @change="handleChange"
                v-model="postInfoForm.address"
                style="width: 100%; border: none"
              ></el-cascader>
            </el-form-item>
            <el-form-item
              prop="detailAddress"
              label="详细地址"
              style="width: 90%"
            >
              <el-input
                placeholder="小区、写字楼、门牌号等"
                v-model="postInfoForm.detailAddress"
              ></el-input>
            </el-form-item>
            <el-form-item prop="userName" label="收件人" style="width: 90%">
              <el-input
                placeholder="收件人名字"
                v-model="postInfoForm.userName"
              ></el-input>
            </el-form-item>
            <el-form-item prop="userPhone" label="手机号" style="width: 90%">
              <el-input
                placeholder="手机号"
                v-model="postInfoForm.userPhone"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button
            type="primary"
            style="width: 90%; margin-bottom: 50px"
            round
            @click="savePostInfo"
            >保存</el-button
          >
        </div>
        <el-empty
          description="请新建收货信息"
          v-if="postInfoList.length == 0 && !isAddPostInfo"
        ></el-empty>
        <!-- 地址单选列表 -->
        <div class="address-container" v-if="!isAddPostInfo">
          <el-radio-group v-if="!isAddPostInfo" v-model="selectIndex">

            <el-radio
              class="address-item"
              v-for="(item, index) in postInfoList"
              :key="item.id"
              :label="index"
              @click.native="addressDrawer = false"
            >
              <div class="address-details">
                <h1>{{ item.detailAddress }}</h1>
                <p>
                  {{ item.province }}&nbsp;{{ item.city }}&nbsp;{{
                    item.district
                  }}&nbsp;{{ item.street }}
                </p>
                <p>{{ item.userName }}&nbsp;{{ item.userPhone }}</p>
              </div>
              <!-- 地址操作按钮 -->
              <div class="address-operation">
                <!-- <el-button class="default-btn"  circle  @click.stop plain ><span>默认</span></el-button> -->
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-edit"
                  circle
                  @click.stop
                ></el-button>
                <el-button
                  type="danger"
                  plain
                  icon="el-icon-delete"
                  circle
                  @click.stop="deletePostInfo(item.id)"
                ></el-button>
              </div>
            </el-radio>
          </el-radio-group>
        </div>
        <div v-if="!isAddPostInfo" class="btm-addPostBtn">
          <el-button
            @click="isAddPostInfo = true"
            class="addPostBtn"
            plain
            round
            >新增收货信息</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- =====地址侧边栏======== -->
  </div>
</template>

<script>
import {getShipmentApplyDetails,changeApplyState} from '@/api/certificate_apply.js'
import {
  queryPostInfoRequest,
  addPostInfoRequest,
  deletePostInfoRequest,
} from "@/api/postInfo";
import { Message } from "element-ui";
import { mapState } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      approvalData: {},
      applyId: this.$route.query.applyId,
      // 根据state（state = 0 表示为证书寄出详情，=1表示证书入库详情）来设置applyState与certificateState
      state:this.$route.query.state,
      currentPath:0,//当前审核信息下标
      selectApprovalIndex:0,
      postInfoList: [], //邮寄信息
      selectIndex: 0, //用户选中的邮寄信息下标
      cerApplyReq:{
        postIdMailing:"",
		certificateIds:[],
		certificateState:'',
		applyId:'',
		applyState:''
      },
      // approvalFrom: {
      //   applyId: "",
      //   approvalId: "",
      //   opinion: "",
      //   approvalState: "0",
      //   postIdMailing: "",
      //   borrowerName: "",
      //   borrowerAccount: "",
      //   certificateIds: [],
      // },
      // isApproved: "0", //是否已经完成审核
      // ------------------
      // checkPage: 1, //查看证书图片当前的页面
      // selectForm: {
      //   certificateLevel: "",
      //   certificateTypeId: "",
      //   certificateSpecialtyId: "",
      // }, //搜索条件表单
      // selectPostInfoForm: {
      //用户选中的邮寄信息表单
      // resource: 0,
      // },
      // applyForm: {
      //   //申请表单
      //   remark: "",
      //   certificateIds: [],
      //   postId: "",
      // },
      // =============地址侧边栏=============
      postInfoForm: {
        //新增邮寄信息表单
        address: "",
        province: "",
        city: "",
        district: "",
        street: "",
        detailAddress: "",
        userName: "",
        userPhone: "",
      },
      addressDrawer: false,
      isAddPostInfo: false, //是否新增邮寄信息
      isDisabled: false, //按钮是否禁用
      rules: {
        address: [
          // required: true 是否必填
          { required: true, message: "请选择地区", trigger: "change" },
        ],
        detailAddress: [
          // required: true 是否必填
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        userPhone: [
          // required: true 是否必填
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
        userName: [
          // required: true 是否必填
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
      },
      props: {
        lazy: true,
        lazyLoad: this.lazyRegionData, // 使用定义在 methods 中的 lazyLoad 函数
      },
      // =============地址侧边栏=============
    };
  },
  methods:{
    // 返回
    goBack() {
      console.log("返回");
      this.$router.back();
    },
        // 复制邮寄信息
        copyAddress(postInfo) {
      console.log("postInfo", postInfo);
      const copyPostInfo =
        "收件人：" +
        postInfo.userName +
        "\n手机号码：" +
        postInfo.userPhone +
        "\n所在地区：" +
        postInfo.province +
        postInfo.city +
        postInfo.street +
        "\n详细地址：" +
        postInfo.detailAddress;
      if (navigator.clipboard) {
        // 使用 Clipboard API 复制文字到剪贴板
        navigator.clipboard
          .writeText(copyPostInfo)
          .then(() => {
            this.$notify({
              title: "成功!",
              message: "已复制地址到剪贴板",
              type: "success",
            });
          })
          .catch((err) => {
            console.error("复制失败:", err);
            this.$notify({
              title: "失败!",
              message: "复制地址失败",
              type: "warning",
            });
            // this.$message.error('复制失败');
          });
      } else {
        // 兼容处理：使用 textarea 和 document.execCommand('copy')
        const textArea = document.createElement("textarea");
        textArea.value = copyPostInfo;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand("copy");
          // this.$message.success('地址已复制到剪贴板');
        } catch (err) {
          console.error("复制失败:", err);
          // this.$message.error('复制失败');
        }
        document.body.removeChild(textArea);
      }
    },

    // 获取申请详情
    getShipmentDetailFun(){
      getShipmentApplyDetails({ applyId: this.applyId }).then((response) => {
        if(response.data.success){
          console.log("response.data.data",response.data.data)
          this.approvalData = response.data.data
          this.cerApplyReq.applyId = this.approvalData.applyId
          this.cerApplyReq.certificateIds = []
          this.approvalData.certificateList.forEach((cer) =>{
            this.cerApplyReq.certificateIds.push(cer.id)
          })
          this.approvalData.processPathList.forEach((path) => {
			// 初始化当前审核流程的进度条
			if (path.approval.approvalState != '0') {
				this.currentPath += 1;
			}
		});

    console.log("当前审核步骤条",this.currentPath)
        }
      })
    },

    // 获取邮寄信息
    PostInfoRequestFun() {
      queryPostInfoRequest().then((response) => {
        if (response.data.success) {
          this.postInfoList = response.data.data;
          console.log("post", this.postInfoList);
        }
        console.log("邮寄信息");
        console.log(response.data);
      });
    },
    // 删除邮寄信息
    deletePostInfo(id) {
      deletePostInfoRequest({ id }).then((response) => {
        if (response.data.success) {
          this.postInfoList = this.postInfoList.filter(
            (item) => item.id !== id
          );
          this.$notify({
            title: "成功!",
            message: response.data.message,
            type: "success",
          });
        } else {
          this.$notify({
            title: "失败!",
            message: response.data.message,
            type: "warning",
          });
        }
      });
    },
    // 保存收获信息按钮
    savePostInfo() {
      this.$refs["postInfoForm"].validate((valid) => {
        if (valid) {
          addPostInfoRequest(this.postInfoForm).then((response) => {
            if (response.data.success) {
              this.PostInfoRequestFun();
              // Message.success(response.data.message)
              this.$notify({
                title: "成功!",
                message: response.data.message,
                type: "success",
              });
              this.isAddPostInfo = false;
            } else {
              // Message.error(response.data.message)

              this.$notify({
                title: "失败!",
                message: response.data.message,
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    },

    // 证书寄出
    sendOff (){
		
      this.cerApplyReq.postIdMailing = this.postInfoList[this.selectIndex].id
		// cerApplyReq.postIdMailing = postInfoListData.postInfoList[postSelectedIndex.value].id+""
		console.log("cerApplyReq",this.cerApplyReq)
		changeApplyState(this.cerApplyReq).then((res) =>{
			console.log("证书提交",res.data)
      if(res.data.success){
        this.$notify({
                title: "成功!",
                message: res.data.message,
                type: "success",
                duration:1000,
                onClose:()=>{
                  // 路由返回
                  this.$router.back()
                }
              });
      }
			// toast.value.show({
			// 	duration: 800,
			// 	message:res.data.message,
			// 	complete:()=>{
			// 		uni.navigateBack({
			// 		});
			// 	}
			// })
			
		})
	},

      // 切换审核信息
      changeApproval(index) {
      this.selectApprovalIndex = index;
    },

    // 初始化审核进度条
    initCurrent (){
		
	},

  handleChange(value) {
      this.postInfoForm.province = value[0];
      this.postInfoForm.city = value[1];
      this.postInfoForm.district = value[2];
      this.postInfoForm.street = value[3];
      console.log("最终的信息表单", this.postInfoForm);

      // 你可以在这里处理选择的数据
    },
   // 动态获取地区数据
   async lazyRegionData(node, resolve) {
      try {
        const response = await axios.get("/api_region_search/v1/", {
          params: {
            // node.label为空时默认值为中国
            keyword: node.label || "中国",
            sub_admin: "1",
            ak: "lGftMMdKchxt98GCtXje3h18LtNvgWnW",
          },
        });
        console.log("nodes");
        // 请求成功后的数据
        const districts = response.data.districts[0].districts;
        console.log(districts);

        // 将请求成功后的数据进行格式转换
        const nodes = districts.map((district) => ({
          value: district.name,
          label: district.name,
          // leaf为叶节点层数
          leaf: district.level >= 4,
        }));

        resolve(nodes);
      } catch (error) {
        Message.error("地区数据获取失败，请稍后再试");
        console.error("地区数据获取失败:", error);
      }
    },
   // 获取状态图片
   getStateimg(state) {
      switch (state) {
        case "0":
          return "https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/0_status.png";
        case "1":
          return "https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/1_status.png";
        case "2":
          return "https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/2_status.png";
      }
    },

    // // 根据state（state = 0 表示为证书寄出详情，=1表示证书入库详情）来设置applyState与certificateState
    initState(){
      if(this.state == 0){
        this.cerApplyReq.applyState = '4'
			this.cerApplyReq.certificateState = '3'
      }
      if(this.state == 1){
			this.cerApplyReq.applyState = '6'
			this.cerApplyReq.certificateState = '1'
		}
    }
  },
  mounted(){
    this.getShipmentDetailFun();
    this.initCurrent()
    this.PostInfoRequestFun()
    this.initState()
  },
  computed:{
    
    selectApproval() {
      if (this.approvalData != null) {
        return this.approvalData.processPathList[this.selectApprovalIndex];
      } else {
        return 0; // 默认返回一个安全的步骤索引
      }
    }
  }
}
  
</script>


<style scoped lang="sass">
.approval-box {
  width: 100%;
  .header{
    display:flex;
    .sendBtn{
      margin-left: 60px
    }
  }
}
.approval-box .el-page-header {
  /* position: fixed;
  width: 100%;
  background-color: #fff; */
}
.approvalFrom {
  width: 55%;
  /* background-color: #409eff; */
  display: flex;
  justify-content: space-between;
}
.approvalFrom .el-form {
  /* width: 60%; */
  min-width: 450px;
}
.approvalStep {
  cursor: pointer;
  /* color: #f99436; */
}
.apply-detail-info td {
  border: none !important;
  padding: 0 !important;
  background-color: #f0f6fc;
}
.apply-detail-info tr {
  border: none !important;
  background-color: #f0f6fc;
}
.apply-detail-info {
  /* width: 100%; */
  padding: 20px 5%;
  border-radius: 10px;
  /* display: flex;
  justify-content: center; */
  /* background-color: #f0f6fc; */
}
.section {
  background-color: #f3f1f1;
  padding: 20px 50px;
  border-radius: 10px;
  margin-bottom: 20px;
  min-height:188px ;
}
.section-title {
  font-size: 18px;
  color: #409eff;
  margin-bottom: 10px;
}
/* .section-title:first-of-type {
  display: block;
} */
.copy-btn {
  font-size: 16px;
  cursor: pointer;
  color: #409eff;
  margin-left: 80px;
}
.info-item {
  margin: 6px 0;
  font-size: 16px;
  line-height: 1.4;
  display: flex;
  align-items: center;
}
.info-item i {
  margin-right: 8px;
}
.certificate-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ffffff;
}
.certificate-item:last-child {
  border-bottom: none;
}
.certificate-info {
  flex: 1;
  padding-right: 15px;
}
.certificate-info div {
  margin-bottom: 8px;
  font-size: 14px;
}
.certificate-images {
  height: 100%;
  display: flex;
  gap: 8px;
}
/* .certificate-images img {
  height: 80px;
  width: 100px;
  border-radius: 5px;
  margin-left: 15px;
  object-fit: cover;
} */
.certificate-image {
  width: 80px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #eee;
  transition: transform 0.3s;
}
.certificate-image:hover {
  transform: scale(1.1);
}
.audit-unit {
  /* margin: 10px 0 0 0; */
  /* display: inline; */
  box-sizing: border-box;
}
.audit-unit span {
  padding: 0 10px 15px 10px;
  font-size: 22px;

  border-bottom: 3px solid #f99436;
}

.audit-section {
}
.audit-persion {
  /* background-color: #409eff; */
  /* width: 10px; */
  line-height: 30px;
  margin-left: 250px;
}
.audit-persion span {
  font-weight: bold;
}
.audit-item {
  width: 100%;
  min-height: 160px;
  /* background-color: #409eff; */
  /* height: 100px; */
  border-top: 0.5px dashed #cecdcd;
  margin-top: 20px;
  padding: 10px 0;
  /* margin: 10px 0; */
  /* border-top: 1px dashed #333; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* 除了最后一个 audit-item，其他的audit-item都有效果*/
/* .audit-item:not(:last-child) {
  border-bottom: 0.5px dashed #cecdcd;
} */
/* .audit-item:not(:first-child) {
  border-bottom: 1px dashed #333;
} */
.aduit-stateimg {
  height: 100%;
}
.aduit-stateimg img {
  height: 100%;
  object-fit: cover;
}

/* -------------- */
</style>
<!-- 以下为侧边添加地址栏 -->
<style>
.address-container {
  width: 100%;
  height: 90%;
  padding: 20px;
  overflow: auto;
}
.address-container::-webkit-scrollbar {
  /* Chrome, Safari 和 Opera 隐藏滚动条 */
  display: none;
}
.address-container .el-radio__label {
  width: 100%;
}
.address-container .el-radio-group {
  width: 100%;
}
.address-container .el-radio__label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.address-container .el-radio {
  border-bottom: 1px solid #e6e6e6ae;
}
.address-container .el-radio:hover {
  background-color: #047ef70e;
}
</style>
<style scoped>
.radio-button:nth-child(1) {
  border-right: 0.5px solid #dedcdc;
}

.radio-group {
  display: flex;
  width: 60%;
  height: 40px;
  border: 0.5px solid #dedcdc;
  border-radius: 6px;
}

.radio-button {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 10px;
  margin: 0;
  border-radius: 5px;
  text-align: center;
  box-sizing: border-box;
  border: 0.5px solid transparent;
  /* border-left: 0.5px solid #cacaca; */
  cursor: pointer;
  transition: background-color 0.8s, border-color 0.8s, color 0.8s;
}

.radio-button.pass {
  color: #409eff;
}

.radio-button.fail {
  color: #f56c6c;
  /* border-color: #bfbfbf; */
}

.radio-button:hover {
  background-color: #f0f0f0;
}

.radio-button.pass:hover {
  background-color: #e6f7ff;
  /* border-color: #409EFF; */
  border: 0.5px solid #409eff;
}

.radio-button.fail:hover {
  background-color: #ffe6e6;
  border: 0.5px solid #f56c6c;
}

.radio-button.active.pass {
  background-color: #409eff;
  color: white;
  /* border-color: #409EFF; */
}

.radio-button.active.fail {
  background-color: #f56c6c;
  color: white;
  /* border-color: #F56C6C; */
}

.radio-input {
  display: none;
}
.current-approval {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* justify-content: space-evenly; */
}
.addressInfo {
  width: 36%;
  color: #606266;
  line-height: 30px;
  margin: 20px 0 0 30px;
  box-sizing: border-box;
}

.addressInfo h1 {
  font-size: 2em;
  line-height: 50px;
  cursor: pointer;
}
.changeAddress:hover {
  color: #409eff;
}
/* ==========地址侧边栏============ */
.custom-drawer-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.back-button {
  color: #666;
  font-size: 22px;
  margin-left: 15px;
}

.address-operation {
  width: 28%;

  /* display: flex; */
  /* justify-content: space-between; */
  display: none;
}
.address-container .el-radio:hover .address-operation {
  display: block;
}
.address-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.address-details {
  width: 72%;
  line-height: 25px;
  margin-left: 10px;
  flex-grow: 1;
  padding: 10px 0;
}

.applypostInfo p {
  line-height: 30px;
  font-size: 14px;
}

.btm-addPostBtn {
  width: 100%;
  padding-top: 15px;
  margin-bottom: 50px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.postInfo-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.postInfo-container .el-input__inner,
.postInfo-container .el-cascader__label,
.postInfo-container .el-cascader__input {
  border: none !important;
  box-shadow: none !important;
  background-color: #a7a7a713;
  border-radius: 10px;
}

.postInfo-container .el-input__inner:focus,
.postInfo-container .el-cascader__label:focus,
.postInfo-container .el-cascader__input:focus {
  border: 1px solid #409eff !important;
}
.addPostBtn {
  width: 90%;
}

/* ==========地址侧边栏============ */
</style>