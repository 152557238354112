<template>
  <div class="head-box">
    <div class="head-logo">
      <img src="https://yuan-house.oss-cn-shenzhen.aliyuncs.com/logo.png" />
      <img
        src="https://yuan-house.oss-cn-shenzhen.aliyuncs.com/company-name.png"/>
    </div>
    <!-- 下面的数据依赖于vuex，所以先进行判断 -->
    <ul v-if="businessArr.length > 0">
      <li :class="isChecked('clientHome')" @click="navigateTo('clientHome')">
        首页
      </li>
      <li :class="isChecked('clientBusinessDetail')" @click="navigateTo('clientBusinessDetail',{ labelType: 1, labelId: businessArr[0].labelId })">
       业务体系
      </li>
      <li  :class="isChecked('clientCaseArticleList')" @click="navigateTo('clientCaseArticleList',{ labelType: 2, labelId: caseArr[0].labelId })">
       案例推荐
      </li>
      <li :class="isChecked('clientArticleList', { labelType: 3})" @click="navigateTo('clientArticleList', { labelType: 3, labelId: newsArr[0].labelId })">
        新闻动态
      </li>
      <li :class="isChecked('clientArticleList',{ labelType: 4})" @click="navigateTo( 'clientArticleList',{ labelType: 4, labelId: knowledgeArr[0].labelId })">
        知识分享
      </li>
      <li :class="isChecked('clientContactOurDetail')" @click="navigateTo('clientContactOurDetail',{ labelType: 5, labelId:18})">
       联系我们
      </li>
    </ul>
    <div class="search-login">
      <input v-if="false" type="text" placeholder="搜索" />
      <i v-if="false" class="iconfont search-font">&#xe692;</i>
      <!-- <a href="#">登录</a> -->
	  <el-button class="loginBtn" @click="jumpLogin()" type="primary" round>登 录</el-button>
      <!-- <router-link :to="{ name: 'login' }">登录</router-link> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {getHomeData} from "@/api/home_data"
export default {
  methods: {
	// isActive(routeName){{
	// 	console.log("当前路由"+this.$route.name)
	// }},
	// 执行滑动到指定页面操作
    scrollToAnchor(anchor) {
      const element = document.getElementById(anchor);
      if (element) {
        // 计算目标元素距离页面顶部的偏移量
        const offsetTop = element.offsetTop - 115;
        // 使用 window.scrollTo 方法滚动到目标位置
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth", // 平滑滚动
        });
      }
    },
	// 跳转首页关于我们页面
    jumpAboutOur(anchor) {
      if (this.$route.name !== "clientHome") {
        this.$router.push({ name: "clientHome" }).then(() => {
          // 在页面跳转完成后执行滑动网页操作
          this.scrollToAnchor(anchor);
        });
      }
      this.scrollToAnchor(anchor);
    },
    
    navigateTo(routeName, query = {}){
      this.$router.push({ name: routeName, query: query })
    },

    //设置头部导航标签的选中效果
    isChecked(pathName, query) {
      if (pathName === this.$route.name) {
        if (query && query?.labelType != this.$route.query.labelType) {
          return '';
        }
        return 'heade_title_check';
      }
      return '';
    },

// 跳转登录页
jumpLogin(){
	// this.$router.push({ name: "clientHome" })
	this.$router.push({name:'login'})
}
  },
  computed: {
    ...mapState({
      caseArr: (state) => state.home_data.homeData.caseArr,
      newsArr: (state) => state.home_data.homeData.newsArr,
      knowledgeArr: (state) => state.home_data.homeData.knowledgeArr,
      businessArr: (state) => state.home_data.homeData.businessArr,
      ContactOurArr: (state) => state.home_data.homeData.ContactOurArr,
    }),
  },
//   created() {
// 	console.log("执行")
// 	const homeData = this.$store.state.home_data.homeData;
// 	console.log(homeData)
//     // 如果浏览器本地有值，而store没值 则从浏览器取值赋值给store
//     if (sessionStorage.getItem("homeData")) {
//       try {
//         const parsedData = JSON.parse(sessionStorage.getItem("homeData"));
//         this.$store.commit("setHomeData", parsedData);
// 		console.log("往vux添加数据")
// 		console.log(this.$store.state.home_data.homeData)
//       } catch (error) {
//         console.error("Error parsing sessionStorage data:", error);
//       }
//     }

//     // 在页面刷新时将 Vuex 中的信息保存到 sessionStorage 中
//     window.addEventListener("beforeunload", () => {
//       try {
        
//         if(true){
// 			sessionStorage.setItem("homeData", JSON.stringify(homeData));
// 		}
//       } catch (error) {
//         console.error("Error saving data to sessionStorage:", error);
//       }
//     });

// 	// 如果store没数据则发请求
// 	// homeData = this.$store.state.home_data.homeData;
// 	if(!homeData || !homeData.newsArr[0] || !homeData.knowledgeArr[0]){
// 		sessionStorage.removeItem("homeData")
// 		// this.$store.commit("setHomeData",{})
// 		getHomeData().then((response) =>{
//         console.log("首页数据")
// 		// 将首页数据保存在vuex
//         console.log(response.data)
// 		this.$store.commit("setBusinessArrData",response.data.data.business)
// 		this.businessArr = response.data.data.business
// 		this.$store.commit("setHomeArticleListData",response.data.data.articles)
//         console.log(this.knowledgeArr)
//         console.log(this.newsArr)
//     })
// 	}
   

//   },
created() {
    this.homeData = this.$store.state.home_data.homeData;

    // 如果浏览器本地有值，而 store 没值，则从浏览器取值赋值给 store
    if (sessionStorage.getItem("homeData") && !this.homeData) {
        try {
            const parsedData = JSON.parse(sessionStorage.getItem("homeData"));
            this.$store.commit("setHomeData", parsedData);
        } catch (error) {
            console.error("Error parsing sessionStorage data:", error);
        }
    }

    // 在页面刷新时将 Vuex 中的信息保存到 sessionStorage 中
    window.addEventListener("beforeunload", () => {
        try {
            if (this.homeData) {
                sessionStorage.setItem("homeData", JSON.stringify(this.homeData));
            }else{
				// sessionStorage.removeItem("homeData");
			}
        } catch (error) {
            console.error("Error saving data to sessionStorage:", error);
        }
    });

    // 如果 store 没数据则发请求
	this.homeData = this.$store.state.home_data.homeData;
	// 功能需测试
    if ( !this.homeData.newsArr.length || !this.homeData.knowledgeArr.length) {
        // sessionStorage.removeItem("homeData");
        // this.$store.commit("setHomeData",{})
        getHomeData().then((response) => {
            // 将首页数据保存在 Vuex
            this.$store.commit("setBusinessArrData", response.data.data.business);
			this.$store.commit("setcaseArrData", response.data.data.caseArticles);
            // this.businessArr = response.data.data.business;
            this.$store.commit("setHomeArticleListData", response.data.data.articles);
        });
    }
}
};
</script>

<style>
.loginBtn{
  background-color: #4e6ef2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}
.heade_title_check{
  border-bottom: solid 3px #4e6ef2;
	background-color: #1947ff11;
	font-weight: 550;
}
</style>