import http from "../utils/request"




// 查询我的证书列表
export function queryMyCertificateList(data){
    return http({
        method:'GET',
        url:'/certificate/my_certificate',
        params:data
    })
}

// 查询所有证书列表
export function queryAllCertificate(data){
    return http({
        method:'POST',
        url:'/certificate/all_certificate',
        data:data
    })
}

// 添加证书
export function addCertificate(data){
    return http({
        method:'POST',
        url:'/certificate/addCertificate',
        data:data
    })
}

// 切换证书状态
export function switchCertificateState(data){
    return http({
        method:'GET',
        url:'/certificate/switchState',
        params:data
    })
}



// 删除单个证书
export function deleteCertificate(data = {}){
    return http({
        method:'GET',
        url:'/certificate/deleteCertificate',
        params:data
    })
}

// 批量删除证书
export function batchDeleteUser(data = {}){
    return http({
        method:'POST',
        url:'/admin/batchDeleteUser',
        data
    })
}