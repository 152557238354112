<template>
  <div>
    <section id="contact-our"></section>
	<!-- 联系我们 -->
	<div class="contact-our">
		<div class="contact-info">
			<span>电话：13133716583&emsp;&emsp; 18607949427</span><br/>
        	<span>邮编：334001</span><br/>
			<span>网址：www.huaxugczx.com</span><br/>
			<span>地址：<a href="https://map.baidu.com/search/%E6%B1%9F%E8%A5%BF%E7%9C%81%E4%B8%8A%E9%A5%B6%E5%B8%82%E9%AB%98%E9%93%81%E7%BB%8F%E6%B5%8E%E8%AF%95%E9%AA%8C%E5%8C%BA%E5%87%A4%E5%87%B0%E4%B8%9C%E5%A4%A7%E9%81%9316%E5%8F%B7%E9%A5%B6%E5%95%86%E6%80%BB%E9%83%A8%E5%9F%BA%E5%9C%B07%E5%B9%A216%E6%A5%BC/@13137609.997992016,3290320.965,17.34z?querytype=s&da_src=shareurl&wd=%E6%B1%9F%E8%A5%BF%E7%9C%81%E4%B8%8A%E9%A5%B6%E5%B8%82%E9%AB%98%E9%93%81%E7%BB%8F%E6%B5%8E%E8%AF%95%E9%AA%8C%E5%8C%BA%E5%87%A4%E5%87%B0%E4%B8%9C%E5%A4%A7%E9%81%9316%E5%8F%B7%E9%A5%B6%E5%95%86%E6%80%BB%E9%83%A8%E5%9F%BA%E5%9C%B07%E5%B9%A216%E6%A5%BC&c=364&src=0&pn=0&sug=0&l=19&b=(13136861.125,3290170.01;13137625.125,3290545.01)&from=webmap&biz_forward=%7B%22scaler%22:2,%22styles%22:%22pl%22%7D&device_ratio=2">江西省上饶市高铁经济试验区凤凰东大道16号饶商总部基地7幢16楼</a></span>
		</div>
		<!-- <div class="buttom-navigation">
			<ul class="navigation-first">
				<li class="navigation-first-li">
					<ul>
						<li>业务体系</li>
						<li>全过程造价管理</li>
						<li>招标代理</li>
						<li>工程监理</li>
						<li>预算评审</li>
						<li>结算审查</li>
						<li>全过程工程咨询</li>
					</ul>
				</li>
				<li><ul>
						<li>案例推荐</li>
						<li>案例推荐</li>
						<li>案例推荐</li>

					</ul></li>
				<li><ul>
						<li>新闻动态</li>
					</ul></li>
				<li><ul>
						<li>知识分享</li>
					</ul></li>
				<li>
					<ul>
						<li>联系我们</li>
					</ul>
				</li>
			</ul>
		</div> -->
		<!-- <div class="follow"></div>		 -->
	</div>
	
	<!-- 备案 -->
	<div class="record">
	<span>Copyright &copy; 2024 江西省华旭工程咨询有限公司 版权所有&emsp;&emsp;</span>
	<span><a href="https://beian.miit.gov.cn/" target="_blank">赣ICP备2024032886号-1</a></span>
	</div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.contact-our{
	color: #fff;
	height: 250px;
	width: 100%;
	padding: 0 40px;
	margin-top: 80px;
	/* background-color: #1d1d1d; */
	background-color: #375eab;
	display: flex;
	flex-wrap: wrap;
	/* justify-content: center; */
	/* justify-content: space-around; */
}
.contact-info{
	width: 45%;
	height: 100%;
	/* background-color: aqua; */
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.navigation-first{
	height: 100%;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	/* flex-direction: column; */
	/* align-content: space-around; */
	justify-content: space-around;
	/* background-color: #fff; */
}
/* .navigation-first li{
margin-top: 13px;
width: 115px;

} */
.navigation-first ul li{
margin-top: 13px;
width: 115px;
/* text-align: center; */
}
.buttom-navigation{
	width: 55%;
	height: 100%;
	/* background-color: red; */
}
.buttom-navigation ul {
  /* list-style: none;
  padding: 0;
  margin: 0;
  display: flex; */
}
.buttom-navigation li{

	list-style: none;
	/* flex: 1; */
  /* padding: 20px; */
  
}
.follow{
	width: 45%;
	height: 100%;
	/* background-color: aqua; */
}
.contact-info span{
	display: block;
	line-height: 30px;
	color:  #fff;
}
.contact-info a{
	color:  #fff;
	/* color:  #c1c1c1; */
}
.contact-info a:hover{
	color:  #000000;
	border-bottom: solid 1px #000000;;
}
.record{
	height: 60px;
	width: 100%;
	background-color: #1e428b;
	display: flex;
	justify-content: center;
	align-items: center;
}
.record span{
	color: #9d9d9d;
	
}
.record a{
	color: #9d9d9d;

}
</style>