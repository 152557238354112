<template>
    <div style="">
      <div class="head-menu" ref="menu">
        <ul>
          <!-- <el-badge :value="3"> -->
          <li
            @click="selectItem('CA_WaitApprovalList', 'CA_WaitApprovalList')"
            :class="{ 'menu-select': selectedRouteName === 'CA_WaitApprovalList' }"
            class="one"
            ref="CA_WaitApprovalList"
          >
            待审批
          </li>
        <!-- </el-badge> -->
        <!-- <el-badge :value="3"> -->
          <li
            @click="selectItem('CA_WaitPostList', 'CA_WaitPostList')"
            :class="{ 'menu-select': selectedRouteName === 'CA_WaitPostList' }"
            ref="CA_WaitPostList"
          >
            待寄出
          </li>
        <!-- </el-badge>
          <el-badge :value="3"> -->
            <li
              @click="selectItem('CA_WaitDepositList', 'CA_WaitDepositList')"
              :class="{ 'menu-select': selectedRouteName === 'CA_WaitDepositList' }"
              ref="CA_WaitDepositList"
            >
              待入库
            </li>
          <!-- </el-badge> -->
            <li
              @click="selectItem('CA_AllApprovalList', 'CA_AllApprovalList')"
              :class="{ 'menu-select': selectedRouteName === 'CA_AllApprovalList' }"
              ref="CA_AllApprovalList"
            >
              全部审批
            </li>
          <div class="indicator" :style="{ left: indicatorLeft + 'px' }"></div>
        </ul>
      </div>
      <router-view></router-view>
    </div>
  </template>
    
    <script>
  export default {
    data() {
      return {
        selectedRouteName:  this.$route.name,
        indicatorLeft: 0,
        activeName: 'second'
      };
    },
    methods: {
      handleClick(tab, event) {
          console.log(tab, event);
        },
        sliderMove(refName){
            // setTimeout(()=>{
                const menuRect = this.$refs.menu.getBoundingClientRect();
        const liRect = this.$refs[refName].getBoundingClientRect();
        this.indicatorLeft = liRect.left - menuRect.left + (liRect.right-liRect.left)/2 -15;
            // })
      },
      selectItem(refName, routeName) {
        // event为事件对象
        this.selectedRouteName = routeName;
        this.$router.push({ name: routeName });
        this.sliderMove(refName);
      },
  
    },
    mounted() {
      this.$nextTick(() => {
        setTimeout(()=>{
          // routeName与refName是一致的
          this.sliderMove(this.selectedRouteName)
        },500)
        //再调用一次，防止某些组件加载较慢
        setTimeout(()=>{
          // routeName与refName是一致的
          this.sliderMove(this.selectedRouteName)
        },2500)
      });
    },
    
  };
  </script>
    
    <style scoped>
    /* .one{
      position: relative;
    } */
  .head-menu {
    width: 100%;
    height: 50px;
  }
  .head-menu ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #8685852a;
    position: relative;
  }
  .head-menu li {
    width: 88px;
    /* position: relative; */
    /* background-color: aqua; */
    text-align: center;
    list-style: none;
    cursor: pointer;
    font-size: 17px;
  }
  .menu-select {
    /* transform: 3s; */
    font-weight: bold;
    font-size: 22px !important;
  }
  .indicator {
    position: absolute;
    bottom: 0;
    width: 30px;
    height: 3px;
    background-color: #000;
    transition: left 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  
  /* .custom-tabs .el-tabs__nav{
    width: 100% !important;
    display: flex !important;
    justify-content: space-around !important;
  } */
  </style>