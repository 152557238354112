// vuex数据
// 管理侧边栏数据
export default{
    state: {
        // 控制菜单是否收缩
        isCollapse:false,
        // 面包屑数据 类似与菜单的数据
        tabsList:[
            {
                authUrl: "/",
                authName: "首页",
                authDesc: "/home",
                icon: "s-home",
                authId: 1,
              },
        ]
    },
    mutations: {
        // state 为vue传来的对象
        // 控制菜单栏是否收缩
        collapseMenu(state){
            state.isCollapse = !state.isCollapse
        },
        // 增加面包屑数据方法
        selectMenu(state,val){
            // 面包屑功能：1.首页是固定的 2.当点击面包屑时，如果面包屑数组里有数据，
            console.log(val)
            // 如果不为home，则执行面包屑添加操作
            if(val.authDesc !== 'home'){
                // 返回当前数组满足条件的索引下标  如果不存在将会返回-1
               const index = state.tabsList.findIndex(item => item.authDesc === val.authDesc)
            //    面包屑中没有当前的菜单栏，执行添加面包屑操作
               if(index === -1){
                state.tabsList.push(val)
               }
               if(index !== -1){
                // 执行删除当前下标后面部分的数组操作
                state.tabsList = state.tabsList.slice(0,index+1)
                console.log(state.tabsList)
            }
            }
        },

        // 删除面包屑数据
        clickBread(state,val){
            const index = state.tabsList.findIndex(item => item.authDesc === val.authDesc)
            //获取到当前点击面包屑在面包屑数组的下标  
            if(index !== -1){
                // 执行删除当前下标后面部分的数组操作
                state.tabsList = state.tabsList.slice(0,index+1)
                console.log(state.tabsList)
            }
        }
    }
}