export default{
    state: {
        homeData:{
            newsArr:[],
            knowledgeArr:[],
            businessArr:[],
            caseArr:[],
        },
    },
    mutations: {

        
        // 设置articleArr数据
        setNewsArrData(state,val){
            state.homeData.newsArr = val
        },
        // 设置business数据
        setBusinessArrData(state,val){
            state.homeData.businessArr = val
        },
        // 设置caseArr数据
        setcaseArrData(state,val){
            state.homeData.caseArr = val
        },
        // 设置ContactOurArr数据
        setContactOurArrData(state,val){
            state.homeData.ContactOurArr = val
        },
        setHomeArticleListData(state,val){
            // push方法要当心，防止出现push多次，所以在操作前要将对象置空
           state.homeData.newsArr = []
           state.homeData.knowledgeArr = []
            val.forEach(item =>{
                // 对日期格式化
                item.createTime = item.createTime.split(' ')[0]
                // 对文章进行分类
                if (item.labelType === 3) {  
                    state.homeData.newsArr.push(item);  
                } else if (item.labelType === 4) {  
                    state.homeData.knowledgeArr.push(item);   
                }
            })
        },
        // 设置knowledgeArr数据
        setKnowledgeArrData(state,val){
            state.homeData.knowledgeArr = val
        },

        // 设置setHomeData数据
        setHomeData(state,val){
            state.homeData = val
        }
        
    }
}