<template>
  <div style="height: 100%; width: 100%" >
    <el-table :data="applyData" style="width: 100%">
      <el-table-column label="申请编号" prop="applyId"> </el-table-column>
      <el-table-column label="申请人" prop="userName"> </el-table-column>
      <el-table-column width="270" label="证书名称" prop="name">
        <template slot-scope="scope">
          <span
            style="line-height: 28px"
            v-for="(name, index) in certificateInfo(scope.row.certificateList)"
            :key="index"
            >{{ name }}<br
          /></span>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="approvalState">
        <template slot-scope="scope">
          <el-tooltip
            effect="dark"
            content="Top Left 提示文字"
            placement="top-start"
          >
            <el-tag :type="ststeTagType(scope.row.approvalState)">{{
              stateFormat(scope.row.applyState)
            }}</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="申请时间" prop="createTime"> </el-table-column>
      <el-table-column prop="create_time" label="操作" width="270">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="approvelApply(scope.row.applyId)">审批</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: flex-end; align-items: center">
      <span>共{{ total }}条</span>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        :total="total"
        @current-change="handerPage"
      >
      </el-pagination>
    </div>
    <!-- <el-dialog
  title="审批申请"
  :visible.sync="approvelDialogVisible"
  width="90%"
  :before-close="handleClose">
  <span>这是一段信息</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="approvelDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="approvelDialogVisible = false">确 定</el-button>
  </span>
</el-dialog> -->
  </div>
</template>

<script>
import { findCertificateApplyBrief,findCerApplyByApplyId } from "@/api/certificate_apply";
import { findCertificateTypeList } from "@/api/certificateType";
import { findCertificateSpecialtyList } from "@/api/certificateSpecialty";
import { Message } from "element-ui";
import { mapState } from "vuex";
export default {
  data() {
    return {
      applyData: [],
      applyStateList: ["0"],
      total: 0, //总条数
      page: {
        pageSize: 6,
        pageNum: 1,
      },
      certificateApply: {
      },
      approvelDialogVisible:false
    };
  },
  methods: {
    ststeTagType(State) {
      switch (State) {
        case "0":
          return "info";
        case "1":
          return "warning";
        case "2":
          return "success";
        case "3":
          return "danger";
        case "4":
          return "warning";
        case "5":
          return "success";
        case "6":
          return "success";
        case "7":
          return "info";
        default:
          return "";
      }
    },
    stateFormat(applyState) {
      switch (applyState) {
        case "0":
          return "未审核";
        case "1":
          return "审核中";
        case "2":
          return "审核通过 等待邮寄";
        case "3":
          return "审核不通过";
        case "4":
          return "已邮寄,待退还";
        case "5":
          return "已退还";
        case "6":
          return "已完成";
        case "7":
          return "已撤销";
      }
    },
    timeformat(row, column) {
      if (!row.returnTime) {
        return "-";
      }
      return row.returnTime;
      // console.log('row',row)
    },
    handleMouseOver() {},

    getStateimg(state) {
      switch (state) {
        case "0":
          return "https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/0_status.png";
        case "1":
          return "https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/1_status.png";
        case "2":
          return "https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/2_status.png";
      }
    },
    // 分页按钮点击 val为页码
    handerPage(val) {
      this.page.pageNum = val
      this.findCertificateApplyBriefFun(this.page, this.applyStateList,this.certificateApply);
    },
// 审批申请按钮
approvelApply(applyId){
// 根据applyId查询申请的所有信息
console.log(applyId)

// this.approvelDialogVisible = true

const routePath = {
        name: "approvalDetail",
        query: { applyId: applyId},
      };
      this.$router.push(routePath);
},
    // 复制邮寄信息
    copyAddress(postInfo) {
      console.log("postInfo", postInfo);
      const copyPostInfo =
        "收件人：" +
        postInfo.userName +
        "\n手机号码：" +
        postInfo.userPhone +
        "\n所在地区：" +
        postInfo.province +
        postInfo.city +
        postInfo.street +
        "\n详细地址：" +
        postInfo.detailAddress;
      if (navigator.clipboard) {
        // 使用 Clipboard API 复制文字到剪贴板
        navigator.clipboard
          .writeText(copyPostInfo)
          .then(() => {
            this.$notify({
              title: "成功!",
              message: "已复制地址到剪贴板",
              type: "success",
            });
          })
          .catch((err) => {
            console.error("复制失败:", err);
            this.$notify({
              title: "失败!",
              message: "复制地址失败",
              type: "warning",
            });
            // this.$message.error('复制失败');
          });
      } else {
        // 兼容处理：使用 textarea 和 document.execCommand('copy')
        const textArea = document.createElement("textarea");
        textArea.value = copyPostInfo;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand("copy");
          // this.$message.success('地址已复制到剪贴板');
        } catch (err) {
          console.error("复制失败:", err);
          // this.$message.error('复制失败');
        }
        document.body.removeChild(textArea);
      }
    },
    // 初始化申请列表
    findCertificateApplyBriefFun(page,applyStateList,certificateApply) {
      findCertificateApplyBrief({
        page,
        applyStateList,
        certificateApply
      }).then((response) => {
        if (response.data.success) {
          console.log("data", response.data.data);
          this.applyData = response.data.data.resultList;
          this.total = response.data.data.totalNum;
        }
      });
    },
    // certificateInfo(certificateList){
    // }
  },
  mounted() {
    // 初始化申请列表
    this.findCertificateApplyBriefFun(
      this.page,
      this.applyStateList,
      this.certificateApply,
      
    );

    if (this.certificateTypeList.length == 0) {
      findCertificateSpecialtyList().then((response) => {
        if (response.data.success) {
          this.$store.commit("setCertificateSpecialtyData", response.data.data);
        } else {
          Message.error(response.data.message);
        }
      });
      // 查询证书类别列表
      findCertificateTypeList().then((response) => {
        if (response.data.success) {
          this.$store.commit("setCertificateTypeData", response.data.data);
        } else {
          Message.error(response.data.message);
        }
      });
    }
  },
  computed: {
    ...mapState({
      //证书类型列表
      certificateTypeList: (state) => state.label_data.certificateTypeList,
      //证书专业列表postInfo
      certificateSpecialtyList: (state) =>
        state.label_data.certificateSpecialtyList,
    }),

    // 计算属性，certificateList可能为数组或certificate对象
    // 这个函数根据certificate的certificateSpecialtyId和certificateTypeId返回对应的类型和专业名称
    certificateInfo() {
      return function (certificateList) {
        const result = [];

        const processCertificate = (certificate) => {
          const specialty = this.certificateSpecialtyList.find(
            (item) =>
              item.certificateSpecialtyId == certificate.certificateSpecialtyId
          );
          const type = this.certificateTypeList.find(
            (item) => item.certificateTypeId == certificate.certificateTypeId
          );
          const level =
            certificate.certificateLevel == ""
              ? ""
              : certificate.certificateLevel == "1"
              ? "一级"
              : "二级";
          if (specialty && type) {
            result.push(
              `${level} ${type.typeName} [${specialty.specialtyName}]`
            );
          }
        };

        if (!Array.isArray(certificateList)) {
          processCertificate.call(this, certificateList);
        } else {
          certificateList.forEach(processCertificate.bind(this));
        }

        return result;
      };
    },
  },
};
</script>

<style scoped>
</style>