<template>
  <!-- 首页主页面  响应式布局 -->
<div class="main-view">
    <!-- 首页顶部数据展示 -->
  <el-row>
    <el-col  :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
      <el-card class="box-card">
        <div class="top-care-item">
          <div class="card-topmore">
            <i class="el-icon-more"></i>
          </div>
          <div class="show-topdata">
            <div class="left-icon">
              <i class="el-icon-s-data"></i>
            </div>
            <div class="right-data">
              <h3>2346</h3>
              <span>进行中项目</span>
            </div>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col  :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
      <el-card class="box-card">
        <div class="top-care-item">
          <div class="card-topmore">
            <i class="el-icon-more"></i>
          </div>
          <div class="show-topdata">
            <div class="left-icon">
              <i class="el-icon-s-data"></i>
            </div>
            <div class="right-data">
              <h3>2346</h3>
              <span>当月已完成</span>
            </div>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col  :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
      <el-card class="box-card">
        <div class="top-care-item">
          <div class="card-topmore">
            <i class="el-icon-more"></i>
          </div>
          <div class="show-topdata">
            <div class="left-icon">
              <i class="el-icon-s-data"></i>
            </div>
            <div class="right-data">
              <h3>2346</h3>
              <span>当月总项目</span>
            </div>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col  :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
      <el-card class="box-card">
        <div class="top-care-item">
          <div class="card-topmore">
            <i class="el-icon-more"></i>
          </div>
          <div class="show-topdata">
            <div class="left-icon">
              <i class="el-icon-s-data"></i>
            </div>
            <div class="right-data">
              <h3>2346</h3>
              <span>总项目</span>
            </div>
          </div>
        </div>
      </el-card>
    </el-col>
  </el-row>
  <!-- 中间图标展示区 -->
  <!-- <el-row :gutter="10">
  <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
    <el-card class="box-card">
        <div class="top-care-item">
          <div class="card-topmore">
            <i class="el-icon-more"></i>
          </div>
          <div class="show-topdata">
            <div class="left-icon">
              <i class="el-icon-s-data"></i>
            </div>
            <div class="right-data">
              <h3>2346</h3>
              <span>当月项目数</span>
            </div>
          </div>
        </div>
      </el-card>
  </el-col>
</el-row> -->
</div>
</template>

<script>
import {getData} from '../api'
export default {
  data(){
    return{

    }
  },
mounted(){
  // 拿到返回的数据
  // getData().then((data) =>{
  //   console.log(data)
  // })
}
};
</script>

<style>
.main-view{
  background-color: #f7f6fb;
}
.el-card{
  margin: 10px 20px;
  padding: 15px 0;
  border-radius: 8px;
}
.el-card__header{
  border: none;
}
.top-care-item{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.card-topmore{
  /* display: flex; */
  /* justify-content:flex-end; */
  position: relative;
  background-color: rgb(141, 245, 96);
}
.card-topmore i{
  position: absolute;
  right: 0;
  top: -10px;
}
.show-topdata{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 24%;
  /* background-color: aqua; */
}
.left-icon i{
  background-color: #f2ecfb;
  padding: 20px;
  font-size: 24px;
  margin-right:30px ;
  border-radius: 50%;
}
.right-data h3{
  font-size: 30px;
  margin: 0;
}
</style>