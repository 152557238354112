import Vue from 'vue'
import Vuex from 'vuex'
import tab from './tab'
import userinfo from './userinfo'
import client_aside from './client_aside'
import home_data from './home_data'
import label_data from './label_data'
Vue.use(Vuex)

// 创建vuex实例

export default new Vuex.Store({
    modules:{
        tab,
        userinfo,
        client_aside,
        home_data, 
        label_data
    }
})