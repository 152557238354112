<template>
  <!-- 我的证书列表 -->
  <div class="manage">
    <div class="mange-header">
      <div style="width: 500px">
        <el-button type="primary" @click="addCertificate"
          ><i class="el-icon-plus">&nbsp;</i>上传我的证书</el-button
        >
      </div>
      <!-- form搜索区 -->
      <el-form style="display: flex; align-items: center" :model="selectForm">
        <!-- <el-form-item> -->
        <el-form-item style="width: 150px; margin-right: 20px">
          <el-select
            v-model="selectForm.certificateLevel"
            clearable
            placeholder="选择证书等级"
          >
            <!-- <el-option label="空" value=""></el-option> -->
            <el-option label="一级" value="1"></el-option>
            <el-option label="二级" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 150px; margin-right: 20px">
          <el-select
            v-model="selectForm.certificateTypeId"
            clearable
            placeholder="选择证书类别"
            @change="updateTypeInSpecialty(selectForm.certificateTypeId)"
          >
            <el-option
              v-for="item in certificateTypeList"
              :key="item.certificateTypeId"
              :label="item.typeName"
              :value="item.certificateTypeId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 150px; margin-right: 20px">
          <el-select
            v-model="selectForm.certificateSpecialtyId"
            clearable
            placeholder="选择证书专业"
          >
            <el-option
              v-for="item in typeInSpecialty"
              :key="item.certificateSpecialtyId"
              :label="item.specialtyName"
              :value="item.certificateSpecialtyId"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="onSubmit()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- el-table会帮我们自动遍历展示tableData数据，但要注意 每列的prop=""要与tableData相应字段对应   -->
    <el-table
      v-if="dataIsSuccess"
      :data="certificateListData"
      style="width: 100%"
      height="90%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="45"> </el-table-column>
      <el-table-column prop="fileUrl" label="证书图片" width="100">
        <!-- fileUrl -->
        <template slot-scope="scope">
          <img
            class="certificate-img"
            :src="
              scope.row.fileUrl
                ? scope.row.fileUrl
                : 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'
            "
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="certificateName"
        :sortable="true"
        label="名称"
        style="width: 10%"
      ></el-table-column>
      <el-table-column
        prop="specialtyName"
        :sortable="true"
        label="专业"
        style="width: 10%"
      >
      </el-table-column>
      <el-table-column
        prop="certificateNumber"
        label="编号"
        style="width: 10%"
      ></el-table-column>
      <el-table-column
        prop="certificateState"
        :sortable="true"
        label="证书状态"
        style="width: 10%"
        :formatter="certificateFormat"
      >
        <template slot-scope="scope">
          <el-tag :type="tagType(scope.row.certificateState)">{{
            certificateFormat(scope.row)
          }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="validityTime" label="有效期" style="width: 10%">
      </el-table-column>
      <el-table-column prop="addr" label="操作" width="270" fixed="right">
        <!-- slot-scope="scope" 定义了插槽的作用域时 在插槽内部就可以通过 scope 对象来访问到父组件中的数据 这个scope 对象是vue传来的  -->
        <template slot-scope="scope">
          <el-button style="" size="mini" @click="handleCheckImg(scope.row.id)"
            >查看</el-button
          >
          <el-button
            type="warning"
            plain
            style=""
            size="mini"
            @click="handleSwitchState(scope.row)"
            >{{
              scope.row.certificateState === "3" ? "启用" : "禁用"
            }}</el-button
          >
          <el-button type="danger" size="mini" @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: flex-end; align-items: center">
      <span>共{{ total }}条</span>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        @current-change="handerPage"
      >
      </el-pagination>
    </div>

    <!-- =================================添加证书提示框=============================== -->
    <el-dialog
      title="上传我的证书"
      :visible.sync="addCertificateDialogVisible"
      width="50%"
    >
      <!-- =============================表单================================= -->
      <el-form class="uploadCertificate" label-position="top">
        <el-form-item
          :label="`上传证书扫描件(第一张将设为封面，最多上传5张图片) ${String.fromCharCode(
            160
          )}${fileList.length}/5`"
          style="width: 865px"
        >
          <el-upload
            class="upload-demo"
            ref="upload"
            style="margin: 15px 0 0 30px"
            action="https://huaxugczx.com:8666/huaxu-company/file/upload_file"
            list-type="picture-card"
            :on-success="uploadSucess"
            :before-upload="uploadBefore"
            :file-list="fileList"
            :auto-upload="true"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <div class="image-container">
                <img :src="file.url" alt="" />
              </div>
              <span class="el-upload-list__item-name">{{ file.name }}</span>
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file.url)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>

                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file, fileList)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <el-form
        :rules="rules"
        ref="form"
        :model="form"
        label-width="100px"
        style="display: flex"
      >
        <el-form-item label="证书等级:">
          <!-- <el-input placeholder="请输入姓名" v-model="form.userName"></el-input> -->
          <el-select
            v-model="form.certificateLevel"
            clearable
            placeholder="请选择"
          >
            <el-option label="一级" value="1"></el-option>
            <el-option label="二级" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="证书类别:">
          <el-select
            v-model="form.certificateTypeId"
            clearable
            placeholder="请选择"
            @change="updateTypeInSpecialty(form.certificateTypeId)"
          >
            <el-option
              v-for="item in certificateTypeList"
              :key="item.certificateTypeId"
              :label="item.typeName"
              :value="item.certificateTypeId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="证书专业:">
          <el-select
            v-model="form.certificateSpecialtyId"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in typeInSpecialty"
              :key="item.certificateSpecialtyId"
              :label="item.specialtyName"
              :value="item.certificateSpecialtyId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="证书编号:">
          <el-input v-model="form.certificateNumber"></el-input>
        </el-form-item>
        <el-form-item label="有效期:">
          <el-date-picker
            v-model="form.validityTime"
            unlink-panels
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <!-- =============================表单================================= -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeCertificateDialog()">取 消</el-button>
        <el-button type="primary" @click="submit()">提交审核</el-button>
      </span>
    </el-dialog>
    <!-- =================================添加证书提示框=============================== -->
    <!-- ========================= 查看大图提示框========================= -->
    <el-dialog :visible.sync="dialogVisible">
      <div  class="certificateDialog">
        <div style="display: flex;">
        <el-pagination  layout="prev, pager, next" :total="showFileList.length" :current-page="checkPage"	 :page-size="1" @current-change="handerPageByCertificate"> </el-pagination>
          <el-link type="primary" style="margin-left: 50px" @click.native="download()">下载当前页</el-link>
        </div>
      <img width="100%" :src="dialogImageUrl" alt="" />
      </div>

    </el-dialog>
    <!-- ========================= 查看大图提示框========================= -->
    <!-- =================================确认删除提示框=============================== -->
    <el-dialog
      title="确定删除证书吗？"
      :visible.sync="deleteUserDialogVisible"
      width="500px"
    >
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteUserDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteUserFun()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- =================================确认删除提示框=============================== -->
  </div>
</template>
  
  <script>
import {
  queryMyCertificateList,
  addCertificate,
  switchCertificateState,
  deleteCertificate,
} from "@/api/certificate";
import { findCertificateTypeList } from "@/api/certificateType";
import { findCertificateSpecialtyList } from "@/api/certificateSpecialty";
import { getCertificateImg } from "@/api/file";
import {uploadFileFun} from "@/utils/downloadFileUtil"
import { Message } from "element-ui";
// 使用elementui的from 配置输入规则：
// 1.from组件为rules传入规则 liuyuan@huaxu
// 2.在要配置规则的form-item项 加上属性：prop="region"
export default {
  data() {
    return {
      dataIsSuccess: false, //数据是否加载好
      dialogVisible: false, //查看大图提示框
      dialogImageUrl: "",
      addCertificateDialogVisible: false, //添加证书弹窗
      // updateUserDialogVisible: false, //更改用户角色弹窗
      deleteUserDialogVisible: false, //确认删除提示框
      downloadFile :{},//当前下载file
      fileList: [], //用于保存上传证书图片列表
      showFileList: [], //用于保存展示证书图片列表
      checkPage:1,//查看证书图片当前的页面
      selectForm: {
        certificateLevel: "",
        certificateTypeId: "",
        certificateSpecialtyId: "",
      }, //搜索条件表单

      total: 0, //总条数
      pageSize: 6, //每页显示的条数
      currentPage: 1, //当前页数
      selectCertificate: {}, //用于保存当前选中证书信息
      certificateTypeList: [], //证书类型列表
      certificateSpecialtyList: [], //证书专业列表
      typeInSpecialty: [], //下拉类型框时，对应的专业列表
      form: {
        certificateLevel: "",
        certificateTypeId: "",
        certificateSpecialtyId: "",
        certificateNumber: "",
        validityTime: "",
        startTime: "",
        endTime: "",
        certificateFileList: [],
      },
      certificateListData: {},
      rules: {
        userName: [
          // required: true 是否必填
          { required: true, message: "请输入人员姓名" },
        ],
        userSex: [
          // required: true 是否必填
          { required: true, message: "请选择性别", trigger: "blur" },
        ],
        phone: [
          // required: true 是否必填
          { required: true, message: "请输入联系方式" },
        ],
        roleId: [
          // required: true 是否必填
          { required: true, message: "请选择职位", trigger: "blur" },
        ],
        userState: [
          // required: true 是否必填
          { required: true, message: "请选择", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    certificateFormat(row) {
      // 0审核中 1正常可申请 2已寄出（不可申请） 3已禁用（不可申请）
      switch (row.certificateState) {
        case "0":
          return "审核中";
        case "1":
          return "可调动";
        case "2":
          return "已调动";
        case "3":
          return "已禁用";
      }
      // return row.certificateState === '1' ? '普通管理员' : '测试管理员';
    },

    findCertificateTypeRequest() {
      // 查询证书类别列表
      findCertificateTypeList()
        .then((response) => {
          if (response.data.success) {
            this.certificateTypeList = response.data.data;
          } else {
            Message.error(response.data.message);
          }
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
    },

    // 查询证书专业列表
    findCertificateSpecialtyRequest() {
      findCertificateSpecialtyList().then((response) => {
        if (response.data.success) {
          this.certificateSpecialtyList = response.data.data;
        } else {
          Message.error(response.data.message);
        }
      });
    },

    // 当证书类型下拉框改变时触发
    updateTypeInSpecialty(selectedTypeId) {
      // 清除证书专业下拉框的选中元素
      if (this.selectForm.certificateSpecialtyId) {
        this.selectForm.certificateSpecialtyId = "";
      }
      if (this.form.certificateSpecialtyId) {
        this.form.certificateSpecialtyId = "";
      }

      // this.selectForm.certificateSpecialtyId = "";
      // 证书类型菜单选中元素时，根据证书类型id来确定证书专业列表
      this.typeInSpecialty = this.certificateSpecialtyList.filter(
        (item) => item.certificateTypeId === selectedTypeId + ""
      );
    },

    // 上传成功后
    uploadSucess(response, file, fileList) {
      this.fileList = fileList;
      // 每次文件上传后将文件的id保存
      this.form.certificateFileList.push(response.data);
      console.log("文件列表");
      console.log(this.form.certificateFileList);
    },
    // 上传前触发的函数，在这里进行判断，如果文件个数达到五个则移除上传框
    uploadBefore(file) {
      if (this.fileList.length >= 4) {
        var element = document.querySelector(".uploadCertificate .el-upload");

        // 如果找到了元素，就隐藏并设置其position属性为absolute
        if (element) {
          element.style.display = "none";
          element.style.position = "fixed"; // 或者 'fixed'
        }
      }
    },
    // 移除文件（待检验）
    handleRemove(file, fileList) {
      setTimeout(() => {
        // 在移除文件时，如果文件列表小于5就让上传框显现
        if (this.fileList.length <= 5) {
          var element = document.querySelector(".uploadCertificate .el-upload");
          if (element) {
            element.style.removeProperty("display");
            element.style.removeProperty("position");
          }
        }
      }, 500);
      console.log("文件移除前的列表");
      console.log(this.fileList);
      // 移除按钮被点击，从fileList中移除当前文件(这个数组将是影响当前展示列表）)
      this.fileList = this.fileList.filter(
        (item) => item.response.data !== file.response.data
      );

      // 从this.processFrom.fileNameList移除（这个数组将是影响数据库）
      this.form.certificateFileList = this.form.certificateFileList.filter(
        (item) => item !== file.response.data
      );
      console.log("文件移除后的列表");
      console.log(this.fileList);
    },

    // 查看图片
    handlePictureCardPreview(fileUrl) {
      this.dialogImageUrl = fileUrl;
      this.dialogVisible = true;
    },

    // 复选框选中时触发
    handleSelectionChange(val) {
      this.SelectionChangeArr = val;
    },
    // 点击新增证书
    addCertificate() {
      // 对表单移除内容和校验结果
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      // 弹出提示框
      this.selectForm = {};
      this.addCertificateDialogVisible = true;
      this.typeInSpecialty = [];
    },
    // 用户关闭添加证书表单
    closeCertificateDialog() {
      this.addCertificateDialogVisible = false;
    },
    // 用户提交表单，先判断是否全部填写完,如果通过规则valid = true
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 验证成功，关闭弹窗
          console.log("添加证书",this.form)
          console.log("证书类型列表",this.certificateTypeList)
          console.log("证书专业列表",this.certificateSpecialtyList)
          addCertificate(this.form)
            .then((response) => {
              if (response.data.success) {
          this.addCertificateDialogVisible = false;
                Message.success(response.data.message);
                this.queryMyCertificateFun(
                  this.pageSize,
                  this.currentPage,
                  this.selectForm.certificateLevel,
                  this.selectForm.certificateTypeId,
                  this.selectForm.certificateSpecialtyId
                );
              } else {
                Message.error(response.data.message);
              }
            })
            .catch((error) => {
              console.error(
                "There has been a problem with your fetch operation:",
                error
              );
            });
        }
      });
    },

    queryMyCertificateFun(
      pageSize,
      pageNum,
      certificateLevel,
      certificateTypeId,
      certificateSpecialtyId
    ) {
      // 分页搜索证书列表操作
      queryMyCertificateList({
        pageSize,
        pageNum,
        certificateLevel,
        certificateTypeId,
        certificateSpecialtyId,
      }).then((response) => {
        if (response.data.success) {
          console.log("证书列表");
          console.log(response.data);
          this.dataIsSuccess = true;
          this.total = response.data.data.totalNum;
          this.certificateListData = response.data.data.resultList;

          this.certificateListData.forEach((item) => {
            // 把0或1变为布尔值用于switch开关的控制
            // item.certificateState === '1' ? item.certificateState = true : item.certificateState = false
            item.validityTime = item.startTime + "至" + item.endTime;
            // 把证书级别和证书类型整合成证书名字
            item.certificateName =
              item.certificateLevel == "1"
                ? "一级" + item.typeName
                : item.certificateLevel == "2"
                ? "二级" + item.typeName
                : "" + item.typeName;
          });
        }
      });
    },
    // 查看按钮点击时执行
    handleCheckImg(id) {
      getCertificateImg({ certificateId: id }).then((response) => {
        if (response.data.success) {
          this.showFileList = response.data.data;
          console.log(this.showFileList);
          this.handlePictureCardPreview(response.data.data[0].fileUrl);
          this.downloadFile = response.data.data[0]
          // Message.success(response.data.message);
        } else {
          Message.error(response.data.message);
        }
      });
    },
    // 删除按钮点击时执行
    handleDelete(row) {
      // 证书当前状态不能被修改
      if (row.certificateState == "0") {
        Message.error("当前证书审核中，暂时不能删除");
        return;
      }
      if (row.certificateState == "2") {
        Message.error("当前证书已被调动，暂时不能删除");
        return;
      }
      this.selectCertificate = row;
      this.deleteUserDialogVisible = true;
    },

    // 删除单个证书确认按钮
    deleteUserFun() {
      deleteCertificate({ id: this.selectCertificate.id })
        .then((response) => {
          if (response.data.success) {
            Message.success(response.data.message);
            // 刷新数据
          } else {
            Message.error(response.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      this.deleteUserDialogVisible = false;
    },

    // 点击切换证书状态
    handleSwitchState(row) {
      // 证书当前状态不能被修改
      if (row.certificateState == "0") {
        Message.error("当前证书审核中，状态暂时不能修改");
        return;
      }
      if (row.certificateState == "2") {
        Message.error("当前证书已被调动，状态暂时不能修改");
        return;
      }
      // 切换状态前从布尔值换为0或1
      switchCertificateState({
        id: row.id,
        certificateState: row.certificateState === "1" ? "3" : "1",
      }).then((response) => {
        if (response.data.success) {
          row.certificateState = row.certificateState === "1" ? "3" : "1";
          Message.success(response.data.message);
        } else {
          Message.error(response.data.message);
        }
      });
    },
    // 分页点击时执行 val为页码
    handerPage(val) {
      this.queryMyCertificateFun(
        this.pageSize,
        val,
        this.selectForm.certificateLevel,
        this.selectForm.certificateTypeId,
        this.selectForm.certificateSpecialtyId
      );
    },
    // 证书图片展示弹出框被点击分页
    handerPageByCertificate(val){
      this.dialogImageUrl = this.showFileList[val-1].fileUrl
      this.downloadFile =  this.showFileList[val-1]
      this.checkPage = 1
    },
    // 搜索
    onSubmit() {
      this.queryMyCertificateFun(
        this.pageSize,
        this.currentPage,
        this.selectForm.certificateLevel,
        this.selectForm.certificateTypeId,
        this.selectForm.certificateSpecialtyId
      );
    },

    // 通过日期对象来构建日期字符串
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从 0 开始，需要加 1，并且保证两位数
      const day = String(date.getDate()).padStart(2, "0"); // 保证两位数

      return `${year}-${month}-${day}`;
    },
    tagType(certificateState) {
      switch (certificateState) {
        case "0":
          return "info";
        case "1":
          return "success";
        case "2":
          return "warning";
        case "3":
          return "danger";
        default:
          return "";
      }
    },

    // 下载当前证书图片
    download(){
      uploadFileFun(this.downloadFile)
      console.log(this.downloadFile)
    }
  },
  mounted() {
    this.queryMyCertificateFun(
      this.pageSize,
      this.currentPage,
      this.selectForm.certificateLevel,
      this.selectForm.certificateTypeId,
      this.selectForm.certificateSpecialtyId
    );
    // 查询证书类型列表
    this.findCertificateTypeRequest();
    // 查询证书专业列表
    this.findCertificateSpecialtyRequest();
  },
  computed: {
    // 0审核中 1正常可申请 2已寄出（不可申请） 3已禁用（不可申请）
    // return this.scope.row.certificateState === '1' ? 'success' : ''
  },
  watch: {
    addCertificateDialogVisible(newVal, oldVal) {
      // 当 addCertificateDialogVisible 属性的值发生变化时，会触发这个方法
      // 当添加证书弹窗关闭时，要进行一些数据清除操作
      if (!newVal) {
        this.form = {};
        this.typeInSpecialty = [];
        this.selectForm = {};
        this.fileList = []
      }
      // 当添加证书弹窗开启时，要进行一些数据清除操作
      if (newVal) {
        this.typeInSpecialty = [];
        this.selectForm = {};
        // this.form = {};
      }
    },
    // "form.certificateFileList": function (newVal, oldVal) {
    //   console.log("文件列表发生变化" + newVal.length);
    // },

    // 监听form.validityTime属性，为这个有效期对象格式化后拆分为开始日期和结束日期
    "form.validityTime": function (newVal, oldVal) {
      if (newVal !== undefined && newVal !== null && newVal.length === 2) {
        this.form.startTime = this.formatDate(newVal[0]);
        this.form.endTime = this.formatDate(newVal[1]);
      }
    },
  },
};
</script>
  
  <style>
.certificate-img {
  width: 100%; /* 图片宽度为容器的100% */
  height: 75px;
  object-fit: cover; /* 图片内容将覆盖整个容器，同时保持其宽高比 */
}
.manage {
  height: 90%;
}
.mange-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.el-table .warning-row {
  background: rgb(254, 249, 249);
}
.el-table .success-row {
  background: #f0f9eb;
}
.el-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.el-form-item {
  width: 48%;
  margin-top: 10px;
}
.el-table__row {
  font-size: 16px;
}
.el-pagination {
  margin-left: 50px;
  text-align: right;
}
.cell {
  text-align: center;
}
.el-form-item {
  width: auto;
}
.uploadCertificate {
  width: 100%;
}
.uploadCertificate .el-form-item__label {
  padding: 0 0 0 37px;
}
.certificateDialog{
  display: flex;
  justify-content: center;
  flex-wrap:wrap;
}
</style>