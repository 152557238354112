import http from "../utils/request"

// /role_list
// 查询角色列表
export function findRoleList(data = {}){
    return http({
        method:'GET',
        url:'/role/role_list',
        data
    })
}

