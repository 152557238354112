<template>
  			<div id="asideContactFrame" class="asideContactFrame-new">
				
				<div class="left-button-box">
					<div>
						<div class="customer-service-btn asideContactBtn" @click="toggleSidebar('chat')">
							<i class="iconfont icon-rengongkefu"></i>
						</div>
						<div class="customer-service-btn asideContactBtn" @click="toggleSidebar('leave-message')">
							<i class="iconfont icon-liuyan"></i>
						</div>
					</div>
				</div>
				<div class="right-chat-container right-aside">
					<!-- ===========侧边在线客服=============== -->
						<template v-if="asideContact == 'chat'">
					<div class="chat-header">
						<span class="chat-title">江西省华旭工程咨询有限公司-接待客服</span>
						<span class="isOnline">离线</span>
					</div>
					<div class="chat-messages-body">
						
						<div class="chat-messages-item">
							<div class="chat-head-logo">
								<img src="@/assets/imgs/customer-service.png"/>
							</div>
							<!-- <div class="chat-messages service-message">
								<span class="chat-messages-text">在线客服功能正在维护中,如有疑问您可以在留言窗口留言,我们会第一时间联系您。</span>
							</div> -->
							<div class="message left-message">在线客服功能正在维护中,如有疑问您可以在留言窗口留言,我们会第一时间联系您。</div>
						</div>
						
						
						<!-- <div class="chat-messages-item chat-guest-item"> -->
							<!-- ---------------老------------ -->
							<!-- <div class="chat-messages guest-message">
								<span class="chat-messages-text">消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息消息</span>
							</div> -->
							<!-- ---------------老------------ -->
							<!-- <div class="message right-message">在线客服功能正在维护中,如有疑问您可以在留言窗口留言,我们会第一时间联系您。</div>
							<div class="chat-head-logo">
								<img src="imgs/20240306153414.jpg"/>
							</div> -->
						<!-- </div>			 -->
					</div>
					<div class="chat-input">
						
						<textarea class="scrollable-textarea" placeholder="在此输入文字信息..."></textarea>
						<div class="send-button">发&nbsp;送</div>
						<!-- <input  type="text" placeholder="在此文本框输入内容..." /> -->
						
					</div>
				</template>
					<!-- ===========侧边在线客服=============== -->
					
					<!-- ----------侧边留言框----------- -->
					<template v-if="asideContact == 'leave-message'">
						<div class="leave-message-container">
							<el-form :model="leaveMessageForm" :rules="rules" ref="leaveMessageForm" class="leaveMessageForm" label-width="80px">
								<div style="text-align: center;width: 100%;margin-top:40px;padding-left: 70px"> 如果您有什么疑问，请在此处留言，<br/>我们会尽快与您联系</div>
								<el-form-item style="width: 100%;margin:12px 0" label="称呼">
									<el-input placeholder="请输入您的称呼" v-model="leaveMessageForm.name"></el-input>
								</el-form-item>
								<el-form-item style="width: 100%;margin: 12px 0" label="联系方式" prop="contactInformation">
									<el-input placeholder="输入联系方式，以便我们能第一时间联系您" v-model="leaveMessageForm.contactInformation"></el-input>
								</el-form-item>
								<el-form-item style="width: 100%;margin: 12px 0" label="邮箱" prop="email">
									<el-input placeholder="用于接收回复邮件" v-model="leaveMessageForm.email"></el-input>
								</el-form-item>
								<el-form-item style="width: 100%; margin: 12px 0" label="留言信息" prop="message">
           							<textarea ref="leaveMessageTextarea" class="leave-message-textarea" @blur="verifyMessage" v-model="leaveMessageForm.message" placeholder="在此输入您的留言信息..."></textarea>
									   <!-- <el-input placeholder="请输入您的称呼" v-model="leaveMessageForm.message"></el-input> -->
    							</el-form-item>
								<el-form-item>
									<el-button @click="cancel()">取消</el-button>
									<el-button type="primary" @click="submitLeaveMessageForm()">提交</el-button>
								</el-form-item>
							</el-form>
						</div>
					</template>
					<!-- ----------侧边留言框----------- -->
				</div>
<!-- 				<div class="leave-message-container right-aside">
					
				</div> -->
			</div>
</template>

<script>
import {addLeaverMessage} from "@/api/leaver_message"


export default {
	data(){
		return{
			asideContact:'',
			isAsideOpen:false,
			contactInformationFormat:/^(?:\+?86-?)?1\d{10}|(?:\d{3,4}-?\d{7,8})(?:-\d+)?$/,
			emailFormat:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			leaveMessageForm:{
				name:'',
				contactInformation:'',//联系方式
				email:'',
				message:''
			},
			rules:{
				
				message: [{ required: true, message: '请输入留言信息',  trigger: 'blur' }],
				contactInformation:[{pattern:/^(?:(?:(?:(?:\+|00)86)?1\d{10})|(?:(?:\d{3}-)?\d{8}|^(?:\d{4}-)?\d{7,8})(?:-\d+)?)$/,message:'如需填入，请输入有效的联系方式', trigger: 'blur'}],
				email: [	
							{ pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: '如需填入，请输入有效的邮箱地址', trigger: 'blur' }
						]
			}
		}
	},
    methods:{
		// 侧边栏留言取消按钮
		cancel(){
			// console.log("取消点击"+this.isAsideOpen)
			this.toggleSidebar('leave-message')
			// this.isAsideOpen = false
		},

		// 验证侧边框信息输入栏是否输入信息
		verifyMessage(){
			if(this.leaveMessageForm.message == ''){
				this.$refs.leaveMessageTextarea.style.border = '1px solid red';
			}else{
				this.$refs.leaveMessageTextarea.style.border = '1px solid #000';
			}
		},

		// 提交留言表单信息
		submitLeaveMessageForm(){
			this.$refs.leaveMessageForm.validate((valid) =>{
				// 联系方式和邮箱输入框比较特殊，可以为空，但是填了的话必须按规则输入，所以这里进行下判断
				if(this.leaveMessageForm.contactInformation){
					valid = this.contactInformationFormat.test(this.leaveMessageForm.contactInformation)
				}
				if(this.leaveMessageForm.email){
					valid = this.emailFormat.test(this.leaveMessageForm.email)
				}


				if(valid){
					this.$refs.leaveMessageTextarea.style.border = '1px solid #000';
					// 验证成功
					console.log(this.leaveMessageForm)
					addLeaverMessage(this.leaveMessageForm).then((response) =>{
						// console.log(response.data)
						if(response.data.success){

							this.$notify({
								title: '成功!',
								message: response.data.message,
								type: 'success'
							});
						}else{
							this.$notify({
								title: '失败!',
								message: response.data.message,
								type: 'warning'
							});
						}	
					})
					setTimeout(()=>{
					// 清空表单，关闭侧边栏
					this.leaveMessageForm = {}
					this.toggleSidebar('leave-message')
					},2000)
				}else{
					// 输入框验证不通过
					// console.log(valid)
					// this.$refs.leaveMessageTextarea.style.border = '1px solid red';
				}
			})
		},
		// 侧边栏按钮
		// 如果点击相同的按钮则收起侧边栏，如果不是，则切换
        toggleSidebar(asideContact) {
			if(!this.isAsideOpen){
				const asideContactFrame = document.getElementById('asideContactFrame');  
			    asideContactFrame.classList.toggle('new-show');  
				this.isAsideOpen = true
				this.asideContact = asideContact
			}else if(asideContact == this.asideContact){
				const asideContactFrame = document.getElementById('asideContactFrame');  
			    asideContactFrame.classList.toggle('new-show');  
				this.isAsideOpen = false
			}else{
				this.asideContact = asideContact
			}
		}

			
    }
}
</script>

<style>
.leaveMessageForm{
	/* height: 100%; */
	padding-right: 10px;
	width: 100%;
	background-color: #fff;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}
.asideContactFrame-new{
	width: 470px;
	height: 100%;
	display: flex;
	position: fixed;
	transition: right 0.3s ease; 
	bottom: 0;
	right: -390px;
	z-index: 999;
}
.asideContactBtn{
	width: 50px;
	height: 50px;
	margin-bottom: 20px;
	background-color: #4e6ef2;  
	color: #fff;  
	border-radius: 50%;  
	display: flex;  
	justify-content: center;  
	align-items: center;  
	font-size: 24px;  
	cursor: pointer;  
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4); /* 阴影效果 */
    transition: box-shadow 0.2s ease; /* 过渡效果 */
	z-index: 1000;
}
.asideContactBtn i{
	font-size: 30px;
}
.left-button-box{
	height: 100%;
	width: 80px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.right-aside{
	width: 390px;
	height: 100%;
	background-color: #fff;
	border: 0.5px solid #d9d9d9;
	border-radius: 15px;
	overflow: hidden;
	box-shadow: 15px 0 30px 5px rgba(0, 0, 0, 0.5);
}
.right-chat-container{
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	/* display: none; */
}
/* 在线客服框 */
.chat-container{
	width: 100%;
}
 .chat-header {
	 height: 60px;
    background-color: #fff;
    color: #000000;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
	justify-content: center;
  }
  .chat-title{
	  font-weight: bold;
	  line-height: 30px;
  }
  .isOnline{
	  font-size: 14px;
	  color: #646464;
  }
  .chat-messages-body{
    overflow-y: auto;
	
    height: 100%;
	
	background-color: #eeeeee;
  }
  
  /*  滚动条宽度滚动条高度*/
  .chat-messages-body::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }
  .leave-message-textarea::-webkit-scrollbar {
    width: 5px; /* 滚动条宽度 */
    height: 4px; /* 滚动条高度 */
  }
  .chat-messages-item{
	  width: 100%;
	  display: flex;
	  margin: 15px 0;
  }
  .chat-guest-item{
	  justify-content: flex-end;
  }
  /* .chat-service-item{
	  
  } */
  .service-message{
	  background-color: #ffffff;
  }
  .guest-message{
	  background-color: #c4c4ff;
  }
  .chat-messages{
  	  max-width: 240px;
	  line-height: 24px;
	  color: #000;
	  padding: 10px;
  	  border-radius: 8px;
  }
  .message {
  color: #000000;
  font-size: 15px;
  line-height: 22px;
  /* 232 */
  max-width: 28ch;
}
.right-message {
	--b: 0.8em; /* base */
  --h: 0.4em; /* height */

  --p: 25px; /* triangle position (0%:top 100%:bottom) */
  --r: 0.5em; /* the radius */
  --c: #c9e7ff;
  padding: 0.6em;
  border-radius: var(--r)/var(--r) min(var(--r),var(--p) - var(--b)/2) min(var(--r),100% - var(--p) - var(--b)/2) var(--r);
  clip-path: polygon(100% 100%,0 100%,0 0,100% 0,
    100% max(0%  ,var(--p) - var(--b)/2),
    calc(100% + var(--h)) var(--p),
    100% min(100%,var(--p) + var(--b)/2));
  background: var(--c);
  border-image: conic-gradient(var(--c) 0 0) fill 0/
    calc(var(--p) - var(--b)/2) 0 calc(100% - var(--p) - var(--b)/2) var(--r)/
    0 var(--h) 0 0;
}
.left-message {
  /* triangle dimension */
  --b: 0.8em; /* base */
  --h: 0.4em; /* height */

  --p: 25px; /* triangle position (0%:top 100%:bottom) */
  --r: 0.5em; /* the radius */
  --c: #ffffff;

  padding: 0.6em;
  border-radius: var(--r)/min(var(--r),var(--p) - var(--b)/2) var(--r) var(--r) min(var(--r),100% - var(--p) - var(--b)/2);
  clip-path: polygon(0 100%,100% 100%,100% 0,0 0,
    0 max(0%  ,var(--p) - var(--b)/2),
    calc(-1*var(--h)) var(--p),
    0 min(100%,var(--p) + var(--b)/2));
  background: var(--c);
  border-image: conic-gradient(var(--c) 0 0) fill 0/
    calc(var(--p) - var(--b)/2) var(--r) calc(100% - var(--p) - var(--b)/2) 0/
    0 0 0 var(--h);
}
  .chat-head-logo{
	  width: 50px;
	  height: 50px;
	  margin: 0 10px;
	  background-color: #fff;
	  border-radius: 5px;
	  overflow: hidden;
  }
  .chat-head-logo img{
	  width: 50px;
  }
  .chat-messages-text{
	  max-width: 200px !important;
  }
  .chat-input{
	  width: 100%;
	  max-height: 180px;
	  min-height: 70px;
	  padding: 10px;
	  /* 鼠标不可点击 */
	  pointer-events: none;
	  cursor: not-allowed;
	  background-color: #fff;
	  display: flex;
  }
  
  .scrollable-textarea {
	width: 70%;
	min-height: 40px;
	line-height: 20px;
    border: none; 
    padding: 5px; 
    resize: none; 
    overflow: auto;
	 /* 显示滚动条 */
	background-color: #ebebeb;
	border-radius: 5px;
  }
  /* 移除输入框在获取焦点时，边框变粗的样式 */
  .scrollable-textarea:focus{
	  outline: none !important;
  }
  /* 滚动条宽度  滚动条高度*/
  .scrollable-textarea::-webkit-scrollbar {
    width: 5px; 
    height: 4px;
  }
  
  /* 滚动条拖动手柄颜色 /* 拖动手柄圆角 */
  .scrollable-textarea::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 4px; 
  }
  
  .send-button {
	  width: 40px;
	  height: 20px;
	  position: absolute;
	  bottom: 10px;
	  right: 10px;
	background-color: #9f9f9f;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
  }


.asideContactFrame-new.new-show {  
    right: 0;  
}  

.leave-message-container{
	height: 100%;
	width:100%;
	display: flex;
	align-items: center;
}
.leave-message-textarea{
	width: 96%;
	min-height: 390px;
	line-height: 20px;
    /* border: none;  */
    padding: 5px; 
    resize: none; 
    overflow: auto;
	font-size: 16px !important;
	 /* 显示滚动条 */
	/* background-color: #ebebeb; */
	border-radius: 5px;
}

</style>