<template>
  <!--  -->
    <div class="manage">
      <div class="mange-header">
        <div style="width: 500px;">
          <el-button type="primary"  @click="addUser"><i class="el-icon-plus">&nbsp;</i>添加分公司</el-button>
         <el-button type="warning"><i class="el-icon-download">&nbsp;</i>导出</el-button>
          <el-button type="danger" @click="batchDeleteUserDialog()"><i class="el-icon-delete">&nbsp;</i>批量删除</el-button>
        </div>
        <!-- form搜索区 -->
        <el-form style="width: 600px;display: flex;align-items: center;"  :model="userForm">
          <!-- <el-form-item> -->
            
            <el-form-item style="width: 120px;margin-right: 20px;">
            <el-select v-model="selectForm.userRole"  placeholder="选择地区">
              <el-option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="width: 120px;margin-right: 20px;">
            <el-select v-model="selectForm.userState"  placeholder="选择状态">
              <!-- <el-option label="空" value=""></el-option> -->
              <el-option label="启用" value="1"></el-option>
              <el-option label="禁用" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input style="width: 180px;margin-right: 20px;" placeholder="请输入名称(模糊搜索)" v-model="selectForm.userName"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit()">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- el-table会帮我们自动遍历展示tableData数据，但要注意 每列的prop=""要与tableData相应字段对应   -->
      <el-table
      v-if="dataIsSuccess"
        :data="companyListData"
        style="width: 100%"
        height="90%"
        @selection-change="handleSelectionChange"
      >
      <el-table-column
        type="selection"
        width="45">
      </el-table-column>
        <el-table-column prop="headImg" label="头像" style="width: 10%;">
          <img class="user-head-img" src="@/assets/20240306153414.jpg" />
        </el-table-column>
        <el-table-column prop="userName" :sortable=true label="姓名" style="width: 10%"></el-table-column>
        <el-table-column prop="sex" :sortable=true label="性别" style="width: 10%" :formatter="sexFormat"></el-table-column>
        <el-table-column prop="companyShortName" :sortable=true label="公司" style="width: 10%"> </el-table-column>   
        <el-table-column prop="userType" :sortable=true label="类型" style="width: 10%" :formatter="typeFormat">
          <template slot-scope="scope">
            <el-tag :type="scope.row.userType === '1' ? 'success' : ''">{{scope.row.userType === '1' ? '系统管理员' : '测试管理员'}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="account" :sortable=true label="账号" style="width: 10%" >
        </el-table-column>
        <el-table-column prop="userState" :sortable=true label="状态" style="width: 10%">
          <template slot-scope="scope">
            <el-switch
                  v-model="scope.row.userStateBoolean"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="正常"
                  inactive-text="禁用"
                  :active-value="true"
                  :inactive-value="false"
                  @change="handleSwitchState(scope.row)"
                />
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机" style="width: 10%"> </el-table-column>
             
        <el-table-column prop="getName" label="添加人" style="width: 10%">
        </el-table-column>
        <el-table-column prop="addr" label="操作" width="270" fixed="right">
          <!-- slot-scope="scope" 定义了插槽的作用域时 在插槽内部就可以通过 scope 对象来访问到父组件中的数据 这个scope 对象是vue传来的  -->
          <template slot-scope="scope">
            <el-button style="" size="mini" @click="handleEdit(scope.row)" >编辑</el-button>
            <!-- <el-button type="warning" plain style="" size="mini" @click="handleSwitchState(scope.row)">{{ scope.row.userState === '1' ? '禁用' : '启用' }}</el-button> -->
            <el-button type="danger" size="mini" @click="handleDelete(scope.row)">删除</el-button>
            <el-button type="primary" size="mini" @click="updateUserRow(scope.row)" icon="el-icon-user"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex;justify-content: flex-end;align-items: center;"> 
        <span>共{{total}}条</span>
        <el-pagination background layout="prev, pager, next" :current-page="currentPage" :page-size="pageSize" :total="total" @current-change="handerPage">
      </el-pagination>
     </div>
  
  
    </div>
  </template>
  
  <script>
  import { queryCompanyList} from "@/api/company";
  import { Message } from "element-ui";
  // 使用elementui的from 配置输入规则：
  // 1.from组件为rules传入规则 liuyuan@huaxu
  // 2.在要配置规则的form-item项 加上属性：prop="region"
  export default {
    data() {
      return {
        companyListData:[],
        dataIsSuccess:false,//数据是否加载好
        addUserDialogVisible: false,//添加用户弹窗
        updateUserDialogVisible:false,//更改用户角色弹窗
        deleteUserDialogVisible:false,//确认删除提示框
        batchDeleteUserDialogVisible:false,//批量删除操作
        SelectionChangeArr:[],//用户多选框
        selectForm:{
          userState:"",
          userRole:"",
          userName:""
        },//搜索条件表单
        UserRowList:{},//所有的权限列表
        total: 0, //总条数
        pageSize:10,//每页显示的条数
        currentPage:1,//当前页数
        selectUser:{},//table当前选中用户，用于保存当前用户信息
        roleForm:{
          roleId:""
        },
        roleList:{},
        form: {
          userName: "",
          userSex: "",
          phone: "",
          useridNumber: "",
          bankCardId: "",
          userAddress: "",
          roleId: "",
          userRemarks: "",
          userState:""
        },
        userForm: {
          name: "",
        },
        companyListData:{},
        rules: {
          userName: [
            // required: true 是否必填
            { required: true, message: "请输入人员姓名" },
          ],
          userSex: [
            // required: true 是否必填
            { required: true, message: "请选择性别", trigger: "blur" },
          ],
         
        },
      };
    },
    methods: {
      // table数据格式化
      sexFormat(row){
        console.log(row)
        return row.sex === '1' ? '男' : '女';
      },
      stateFormat(row){
        return row.userState === '1' ? '正常' : '禁用';
      },
      typeFormat(row){
        return row.roleId === '1' ? '普通管理员' : '测试管理员';
      },
      // 根据table的状态，分配相应的class
      tableRowClassName({ row, rowIndex }){
        if (row.userState == "0") {
          // return "warning-row";
        }
        return "";
      },

   
          
      // 复选框选中时触发
      handleSelectionChange(val){
        this.SelectionChangeArr = val;
      },
          // 点击新增用户
      addUser() {
        // 对表单移除内容和校验结果
        if (this.$refs.form !== undefined) {
          this.$refs.form.resetFields();
        }
        // 弹出提示框
        this.addUserDialogVisible = true;
      },
      // 用户提交表单，先判断是否全部填写完,如果通过规则valid = true
      submit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            // 验证成功，关闭弹窗
            this.addUserDialogVisible = false;
  
            addUser(this.form).then((response) => {
              if(response.data.success){
                Message.success(response.data.message);
              }else{
                Message.error(response.data.message);
              }
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
          }
        });
      },
  
     queryCompanyListFun(pageSize,pageNum){
            // 分页搜索列表操作
            queryCompanyList({ pageSize:pageSize,pageNum:pageNum}).then((response) => {
              console.log("管理员列表")
              console.log(response.data)
        if(response.data.success){
          console.log("管理员列表")
          console.log(response.data)
          // this.dataIsSuccess = true
          // this.total = response.data.data.totalNum
          // this.companyListData = response.data.data.resultList

          // 为数组添加一个userStateBoolean布尔值数据，用于switch开关的控制
      //     this.companyListData.forEach(user => {
      //     user.userStateBoolean = user.userState === '1';
      // });
        }else{
          Cookie.remove("token");
          Message.error("失败，请刷新浏览器");
        }
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
      },
      // 编辑按钮点击时执行
      handleEdit(row) {
        // console.log(row)
      },
      // 删除按钮点击时执行
      handleDelete(row){
        this.selectUser = row
        this.deleteUserDialogVisible = true
        console.log("好像点了确定")
      },
  
  
          // 删除单个用户确认按钮
          deleteUserFun(){
            deleteUser({account:this.selectUser.account}).then((response) =>{
              if(response.data.success){
                  Message.success(response.data.message);
                    // 刷新数据
                    this.queryUsrtListFun(this.pageSize,this.currentPage,this.selectForm.userName,this.selectForm.userRole,this.selectForm.userState);
              }else{
                  Message.error(response.data.message);
              }
            })
            .catch((error) => {
                console.error(error);
        });
        this.deleteUserDialogVisible = false
      },
  
      batchDeleteUserDialog(){
        if(this.getSelectUsersId.length == 0){
          Message.error("还未选择用户");
        }else{
          this.batchDeleteUserDialogVisible = true
        }
      },
  
      // 批量删除
      batchDeleteUserFun(){
      // else{
          batchDeleteUser(this.getSelectUsersId).then((response) =>{
          if(response.data.success){
                  Message.success(response.data.message);
                // 刷新数据
                this.queryUsrtListFun(this.pageSize,this.currentPage,this.selectForm.userName,this.selectForm.userRole,this.selectForm.userState);
              }else{
                Message.error(response.data.message);
              }
        })
        this.batchDeleteUserDialogVisible = false
       
      },
  
   
      // 分页点击时执行 val为页码
      handerPage(val) {
        this.queryUsrtListFun(this.pageSize,val,this.selectForm.userName,this.selectForm.userRole,this.selectForm.userState);
        console.log(val);
      },
      // 搜索
      onSubmit() {
        console.log("开始条件查询")
        console.log(this.selectForm)
        this.queryUsrtListFun(this.pageSize,this.currentPage,this.selectForm.userName,this.selectForm.userRole,this.selectForm.userState);
      },
    },
    mounted(){
        this.queryCompanyListFun(this.pageSize,this.currentPage);
    },
    computed:{
      getSelectUsersId(){
        return this.SelectionChangeArr.map(item =>{
          return item.account
        })
      },
        // 动态返回一个计算属性
  userStateBoolean(row) {
    return (row) => ({
      get() {
        return row.userState === '1';
      },
      set(value) {
        row.userState = value ? '1' : '0';
      }
    });
  }
    }
  };
  </script>
  
  <style>
  .user-head-img{
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }
  .manage {
    height: 90%;
  }
  .mange-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .el-table .warning-row {
    background: rgb(254, 249, 249);
  }
  .el-table .success-row {
    background: #f0f9eb;
  }
  .el-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .el-form-item {
    width: 48%;
    margin-top: 10px;
  }
  .el-table__row {
    font-size: 16px;
  }
  .el-pagination {
    margin-left: 50px;
    text-align: right;
  }
  .cell {
    text-align: center;
  }
  .el-form-item {
    width: auto;
  }
  </style>