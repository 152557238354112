<template>
  <div class="articleBody" >
    <div class="titleBox">
    <h1>{{ article.title }}</h1>
    <div class="time-source">
        <span>{{article.createTime}}&emsp;来源：{{ article.source }}</span>
    </div>
    </div>
    <!-- <hr/> -->
    <div v-html="article.content"></div>
    <!-- <template v-html="article.content"></template> -->
  </div>
</template>

<script>
import {queryArticleInfoById} from "@/api/article"
export default {
    data(){
        return{
            article:{}
        }
    },
    created() {
        queryArticleInfoById({ArticleId:this.$route.query.ArticleId}).then((response) =>{
            if(response.data.success){
                this.article = response.data.data
            }
        })
},

}
</script>

<style scoped>

.articleBody{
    width: 100%;
    min-height: 500px;
    color: #333333;
    /* background-color: red ; */
}
.titleBox{
    width: 100%;
    padding-top: 35px;
    margin-bottom: 30px;
    border-bottom:0.5px solid #eae7e7;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.titleBox h1{
    /* padding: 20px; */
    /* text-align: center; */
    margin: 0; 
    padding: 0 30px;
    /* font-size: 0.45rem; */
    /* width: 95%; */
    /* width: 100px; */
    /* background-color: red; */
    text-align: center;
    line-height: 50px;
    /* color: #333333; */
    /* background-color: red; */
}
.time-source{
width: 100%;
display: flex;
flex-direction: column;
justify-content: end;
/* background-color: red; */
/* margin-top: 20px; */
}
.time-source span{
    line-height: 45px;
}
</style>