<template>
  <div class="manage">
    <div class="mange-header">
      <div style="width: 500px;">
        <el-button type="primary"  @click="addUser"><i class="el-icon-plus">&nbsp;</i>新增</el-button>
       <el-button type="warning"><i class="el-icon-download">&nbsp;</i>导出</el-button>
       <!-- <div> -->
        <el-button type="danger" @click="batchDeleteUserDialog()"><i class="el-icon-delete">&nbsp;</i>批量删除</el-button>
       <!-- </div> -->
      </div>

      <!-- form搜索区 -->
      <el-form style="width: 600px;display: flex;align-items: center;"  :model="userForm">
        <!-- <el-form-item> -->
          <el-form-item style="width: 120px;margin-right: 20px;">
          <el-select v-model="selectForm.userState"  placeholder="选择状态">
            <!-- <el-option label="空" value=""></el-option> -->
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="0"></el-option>
          </el-select>
        </el-form-item>
          <el-form-item style="width: 120px;margin-right: 20px;">
          <el-select v-model="selectForm.userRole"  placeholder="选择角色">
            <!-- <el-option label="空" value=""></el-option> -->
            <el-option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId"></el-option>
            </el-select>
          </el-form-item>
        <!-- </el-form-item> -->
        <el-form-item>
          <el-input style="width: 180px;margin-right: 20px;" placeholder="请输入名字(模糊搜索)" v-model="selectForm.userName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- el-table会帮我们自动遍历展示tableData数据，但要注意 每列的prop=""要与tableData相应字段对应   -->
    <el-table
    v-if="dataIsSuccess"
      :data="userListData"
      style="width: 100%"
      height="90%"
      @selection-change="handleSelectionChange"
    >
    <el-table-column
      type="selection"
      width="45">
    </el-table-column>
      <el-table-column prop="headImg" label="头像" style="width: 10%;">
        <img class="user-head-img" src="@/assets/20240306153414.jpg" />
      </el-table-column>
      <el-table-column prop="userName" :sortable=true label="姓名" style="width: 10%"></el-table-column>
      <el-table-column prop="sex" :sortable=true label="性别" style="width: 10%" :formatter="sexFormat"></el-table-column>
      <el-table-column prop="account" :sortable=true label="账号" style="width: 10%" >
      </el-table-column>
      <el-table-column prop="userState" :sortable=true label="状态" style="width: 10%">
        <template slot-scope="scope">
          <el-switch
                  v-model="scope.row.userState"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="正常"
                  inactive-text="禁用"
                  :active-value="true"
                  :inactive-value="false"
                  @change="handleSwitchState(scope.row)"
                />
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="手机" style="width: 10%"> </el-table-column>      
      <el-table-column prop="getName" label="添加人" style="width: 10%">
      </el-table-column>
      
      <el-table-column prop="addr" label="操作" width="270" fixed="right">
        <!-- slot-scope="scope" 定义了插槽的作用域时 在插槽内部就可以通过 scope 对象来访问到父组件中的数据 这个scope 对象是vue传来的  -->
        <template slot-scope="scope">
          <el-button style="" size="mini" @click="handleEdit(scope.row)" >编辑</el-button>
          <!-- <el-button type="warning" plain style="" size="mini" @click="handleSwitchState(scope.row)">{{ scope.row.userState === '1' ? '禁用' : '启用' }}</el-button> -->
          <el-button type="danger" size="mini" @click="handleDelete(scope.row)">删除</el-button>
          <el-button type="primary" size="mini" @click="updateUserRow(scope.row)" icon="el-icon-user"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex;justify-content: flex-end;align-items: center;"> 
      <span>共{{total}}条</span>
      <el-pagination background layout="prev, pager, next" :current-page="currentPage" :page-size="pageSize" :total="total" @current-change="handerPage">
    </el-pagination>
   </div>


    <!-- =================================添加用户提示框=============================== -->
    <el-dialog title="添加人员" :visible.sync="addUserDialogVisible" width="50%">
      <!-- =============================表单================================= -->
      <el-form :rules="rules" ref="form" :model="form" label-width="90px">
        <el-form-item prop="userName" label="姓 名:">
          <el-input placeholder="请输入姓名" v-model="form.userName"></el-input>
        </el-form-item>
        <el-form-item prop="userSex" label="性 别:">
          <el-select v-model="form.userSex" placeholder="请选择">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="phone" label="手机号:">
          <el-input placeholder="请输手机号" v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item prop="roleId" label="账户类型:">
          <el-select v-model="form.roleId" placeholder="请选择">
            <el-option label="管理员" value="1"></el-option>
            <el-option label="员工" value="2"></el-option>
            <el-option label="普通用户" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份证号:">
          <el-input v-model="form.useridNumber"></el-input>
        </el-form-item>

        <el-form-item label="银行卡号:">
          <el-input v-model="form.bankCardId"></el-input>
        </el-form-item>
        <el-form-item prop="userState" label="是否启用:">
          <el-select v-model="form.userState" placeholder="请选择">
            <el-option label="是" value="0"></el-option>
            <el-option label="否" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="家庭住址:">
          <el-input v-model="form.userAddress"></el-input>
        </el-form-item>

        <el-form-item label="备注:">
          <el-input v-model="form.userRemarks"></el-input>
        </el-form-item>
      </el-form>
      <!-- =============================表单================================= -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addUserDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- =================================添加用户提示框=============================== -->
    <!-- =================================更改用户权限提示框=============================== -->
    <el-dialog title="更改权限" :visible.sync="updateUserDialogVisible" width="500px">
      <el-form style="display: flex;flex-direction: column;">
        <el-form-item label="姓名:">
          <el-input  :value="selectUser.userName" :disabled=true></el-input>
        </el-form-item>
        <el-form-item  prop="userState" label="请选择更改的角色:">
          <el-select v-model="roleForm.roleId"  placeholder="请选择">
            <el-option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateUserDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUserRole()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- =================================更改用户权限提示框=============================== -->
    <!-- =================================确认删除提示框=============================== -->
    <el-dialog  title="确定删除吗？" :visible.sync="deleteUserDialogVisible" width="500px">
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteUserDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteUserFun()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- =================================确认删除提示框=============================== -->
        <!-- =================================批量确认删除提示框=============================== -->
        <el-dialog  title="正在执行批量删除操作，确定删除吗？" :visible.sync="batchDeleteUserDialogVisible" width="500px">
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchDeleteUserDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="batchDeleteUserFun()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- =================================批量确认删除提示框=============================== -->
  </div>
</template>

<script>
import { queryUserList,addUser,switchState,updateUserRole,deleteUser,batchDeleteUser} from "@/api/customerUserInfo";
import {findRoleList} from  "@/api/role"
import { Message } from "element-ui";
// 使用elementui的from 配置输入规则：
// 1.from组件为rules传入规则 liuyuan@huaxu
// 2.在要配置规则的form-item项 加上属性：prop="region"
export default {
  data() {
    return {
      dataIsSuccess:false,//数据是否加载好
      addUserDialogVisible: false,//添加用户弹窗
      updateUserDialogVisible:false,//更改用户角色弹窗
      deleteUserDialogVisible:false,//确认删除提示框
      batchDeleteUserDialogVisible:false,//批量删除操作
      SelectionChangeArr:[],//用户多选框
      selectForm:{
        userState:"",
        userRole:"",
        userName:""
      },//搜索条件表单
      UserRowList:{},//所有的权限列表
      total: 0, //总条数
      pageSize:10,//每页显示的条数
      currentPage:1,//当前页数
      selectUser:{},//table当前选中用户，用于保存当前用户信息
      roleForm:{
        roleId:""
      },
      roleList:{},
      form: {
        userName: "",
        userSex: "",
        phone: "",
        useridNumber: "",
        bankCardId: "",
        userAddress: "",
        roleId: "",
        userRemarks: "",
        userState:""
      },
      userForm: {
        name: "",
      },
      userListData:{},
      rules: {
        userName: [
          // required: true 是否必填
          { required: true, message: "请输入人员姓名" },
        ],
        userSex: [
          // required: true 是否必填
          { required: true, message: "请选择性别", trigger: "blur" },
        ],
        phone: [
          // required: true 是否必填
          { required: true, message: "请输入联系方式" },
        ],
        roleId: [
          // required: true 是否必填
          { required: true, message: "请选择职位", trigger: "blur" },
        ],
        userState: [
          // required: true 是否必填
          { required: true, message: "请选择", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // table数据格式化
    sexFormat(row){
      return row.userSex === '0' ? '男' : '女';
    },
    stateFormat(row){
      return row.userState === '1' ? '正常' : '禁用';
    },
    roleFormat(row){
      return row.roleId === '1' ? '管理员' : row.roleId === '2' ? '员工' : '普通用户';
    },
    // 根据table的状态，分配相应的class
    tableRowClassName({ row, rowIndex }){
      if (row.userState == "0") {
        // return "warning-row";
      }
      return "";
    },
    // 更改用户角色按钮被点击
    updateUserRow(user){
      this.selectUser = user
      this.roleForm.roleId= user.roleId == '1' ? "管理员" : user.roleId == '2' ? '员工' : '普通用户'
      this.updateUserDialogVisible = true
      
    },
    findRoleLisrRequest(){
            // 查询角色列表
            findRoleList().then((response) => {
        if(response.data.success){
          this.roleList = response.data.data
        }else{
          Message.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      })
    },
        // 用户修改角色确定按钮
        submitUserRole(){
      // console.log(this.selectUser.roleId+"==========="+this.roleForm.roleId)
      // if(!this.roleForm.roleId == this.selectUser.roleId){
        // console.log("用户发出修改角色请求")
        // this.selectUser.roleId = this.roleForm.roleId
        // 发出修改用户角色请求
        updateUserRole({userId:this.selectUser.userId,userRole:this.roleForm.roleId}).then((response) => {
            if(response.data.success){
              console.log

              // this.selectUser.roleId = this.roleForm.roleId
              Message.success(response.data.message);
              this.updateUserDialogVisible = false
              // 刷新数据
              
             this.queryUsrtListFun(this.pageSize,this.currentPage,this.selectForm.userName,this.selectForm.userRole,this.selectForm.userState);
            }else{
              this.updateUserDialogVisible = false
              Message.error(response.data.message);
            }
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
      // }

    },



    // 复选框选中时触发
    handleSelectionChange(val){
      this.SelectionChangeArr = val;
      

    },
        // 点击新增用户
    addUser() {
      // 对表单移除内容和校验结果
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      // 弹出提示框
      this.addUserDialogVisible = true;
    },
    // 用户提交表单，先判断是否全部填写完,如果通过规则valid = true
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 验证成功，关闭弹窗
          this.addUserDialogVisible = false;

          addUser(this.form).then((response) => {
            if(response.data.success){
              Message.success(response.data.message);
            }else{
              Message.error(response.data.message);
            }
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });



        }
      });
    },



    queryUsrtListFun(pageSize,pageNum,userName,roleId,userState){
          // 分页搜索用户列表操作
    queryUserList({ pageSize,pageNum,userName,roleId,userState})
    .then((response) => {
      if(response.data.success){
        console.log("员工列表数据")
        console.log(response.data)
        this.dataIsSuccess = true
        this.total = response.data.data.totalNum
        this.userListData = response.data.data.resultList
        // 为数组添加一个userStateBoolean布尔值数据，用于switch开关的控制
        this.userListData.forEach(user => {
          user.userState === '1' ? user.userState = true : user.userState = false
      });
      }else{
        Cookie.remove("token");
        Message.error("失败，请刷新浏览器");
      }
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
    },
    // 编辑按钮点击时执行
    handleEdit(row) {
      // console.log(row)
    },
    // 删除按钮点击时执行
    handleDelete(row){
      this.selectUser = row
      this.deleteUserDialogVisible = true
      console.log("好像点了确定")
    },


        // 删除单个用户确认按钮
        deleteUserFun(){
          deleteUser({account:this.selectUser.account}).then((response) =>{
            if(response.data.success){
                Message.success(response.data.message);
                  // 刷新数据
                  this.queryUsrtListFun(this.pageSize,this.currentPage,this.selectForm.userName,this.selectForm.userRole,this.selectForm.userState);
            }else{
                Message.error(response.data.message);
            }
          })
          .catch((error) => {
              console.error(error);
      });
      this.deleteUserDialogVisible = false
    },
    batchDeleteUserDialog(){
      if(this.getSelectUsersAccount.length == 0){
        Message.error("还未选择用户");
      }else{
        this.batchDeleteUserDialogVisible = true
      }
    },

    // 批量删除用户
    batchDeleteUserFun(){
    // else{
        batchDeleteUser(this.getSelectUsersAccount).then((response) =>{
        if(response.data.success){
                Message.success(response.data.message);
              // 刷新数据
              this.queryUsrtListFun(this.pageSize,this.currentPage,this.selectForm.userName,this.selectForm.userRole,this.selectForm.userState);
            }else{
              Message.error(response.data.message);
            }
      })
      this.batchDeleteUserDialogVisible = false
     
    },

    // 点击切换用户状态
    handleSwitchState(row){
      switchState({account:row.account,userState:row.userState ? '1' : '0'}).then((response) => {
        if(response.data.success){
          Message.success(response.data.message);
    }else{
      Message.error(response.data.message);
    }

      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
    },
    // 分页点击时执行 val为页码
    handerPage(val) {
      this.queryUsrtListFun(this.pageSize,val,this.selectForm.userName,this.selectForm.userRole,this.selectForm.userState);
      console.log(val);
    },
    // 搜索
    onSubmit() {
      console.log("开始条件查询")
      console.log(this.selectForm)
      this.queryUsrtListFun(this.pageSize,this.currentPage,this.selectForm.userName,this.selectForm.userRole,this.selectForm.userState);
    },
  },
  mounted(){
    console.log(this.userListData)
    // if(this.userListData == null){
      this.queryUsrtListFun(this.pageSize,this.currentPage,this.selectForm.userName,this.selectForm.userRole,this.selectForm.userState);
      // 查询角色列表
      this.findRoleLisrRequest()
    // }
    
  },
  computed:{
    getSelectUsersAccount(){
      return this.SelectionChangeArr.map(item =>{
        return item.account
      })
    }
  }
};
</script>

<style>
.manage {
  height: 90%;
}
.mange-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.el-table .warning-row {
  background: rgb(254, 249, 249);
}
.el-table .success-row {
  background: #f0f9eb;
}
.el-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.el-form-item {
  width: 48%;
  margin-top: 10px;
}
.el-table__row {
  font-size: 16px;
}
.el-pagination {
  margin-left: 50px;
  text-align: right;
}
.cell {
  text-align: center;
}
.el-form-item {
  width: auto;
}
</style>