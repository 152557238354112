// 证书申请
import http from "../utils/request"

// 创建证书申请记录
export  function createCertificateApply(data){
   return http({
        method:'Post',
        url:'/apply_certificate/create_apply',
        data:data
    })
}

// 查询审批中证书申请记录
export  function findCertificateApply(data = {}){
    return http({
         method:'POST',
         url:'/apply_certificate/select_apply',
         data:data
     })
 }

 // 审批证书申请
export  function approvalCertificateApply(data = {}){
    return http({
         method:'POST',
         url:'/apply_certificate/approval_apply',
         data:data
     })
 }


 // 根据applyId查询该申请的所有信息
export  function findCerApplyByApplyId(data = {}){
    return http({
         method:'POST',
         url:'/apply_certificate/query_apply',
         data:data
     })
 }
 // 查询审批中证书申请记录,只包含申请记录
export  function findCertificateApplyBrief(data = {}){
    return http({
         method:'POST',
         url:'/apply_certificate/select_apply_brief',
         data:data
     })
 }


 // 删除、申请记录
export function delCertificateApply(data = {}){
    return http({
         method:'GET',
         url:'/apply_certificate/delete_apply',
         params:data
     })
 }

  // 撤销申请记录
export function revokeCertificateApply(data = {}){
    return http({
         method:'GET',
         url:'/apply_certificate/revoke_apply',
         params:data
     })
 }

 // 证书退还
 export  function changeApplyState(data = {}){
    return http({
         method:'POST',
         url:'/apply_certificate/change_apply_state',
         data:data
     })
 }

//  查询待寄出待入库证书申请
 export  function findMyCerApplyPendingOperations(data = {}){
    return http({
         method:'POST',
         url:'/apply_certificate/select_await_apply',
         data:data
     })
 }

 // 查询待寄出待入库证书申请详情信息
 export  function getShipmentApplyDetails(data = {}){
    return http({
         method:'POST',
         url:'/apply_certificate/shipment_apply',
         data:data
     })
 }