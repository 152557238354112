import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import store from './store'
import Cookie from 'js-cookie'
// import * as echarts from 'echarts'
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

import Prism from 'prismjs';
VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});

Vue.use(VueMarkdownEditor);
Vue.use(VueRouter)
Vue.config.productionTip = false

// 按需引入
import {Button,timeline,Loading ,RadioButton,PageHeader,MessageBox,timelineItem,Image,Tabs,Descriptions,DescriptionsItem,Tooltip,TabPane,Drawer,Radio,RadioGroup,Link,Badge,Cascader,Switch ,Carousel,carouselItem,Notification,Empty,Timeline,TimelineItem,Steps,Step,TimePicker,Upload,DatePicker,Alert,Tag,Pagination,Table,TableColumn,Option,Select,FormItem,Input,Form,Dialog,Breadcrumb,BreadcrumbItem,Card,Row,Col,Dropdown,DropdownMenu,DropdownItem,Container,Aside,Header,Main,Menu,Submenu,menuItemGroup,menuItem} from "element-ui"

Vue.prototype.$notify = Notification;
Vue.prototype.$confirm = MessageBox.confirm
Vue.use(Loading )

Vue.component(Carousel.name,Carousel)
// Vue.component(loading.name,loading)
Vue.component(RadioButton.name,RadioButton)
Vue.component(PageHeader.name,PageHeader)
Vue.component(timeline.name,timeline)
Vue.component(timelineItem.name,timelineItem)
Vue.component(Image.name,Image)
Vue.component(Descriptions.name,Descriptions)
Vue.component(DescriptionsItem.name,DescriptionsItem)
Vue.component(Tooltip.name,Tooltip)
Vue.component(Tabs.name,Tabs)
Vue.component(TabPane.name,TabPane)
Vue.component(RadioGroup.name,RadioGroup)
Vue.component(Radio.name,Radio)
Vue.component(Drawer.name,Drawer)
Vue.component(Link.name,Link)
Vue.component(Badge.name,Badge)
Vue.component(Cascader.name,Cascader)
Vue.component(Switch.name,Switch)
Vue.component(carouselItem.name,carouselItem)
Vue.component(Notification.name,Notification)
Vue.component(Upload.name,Upload)
Vue.component(Empty.name,Empty)
Vue.component(Timeline.name,Timeline)
Vue.component(TimelineItem.name,TimelineItem)
Vue.component(Steps.name,Steps)
Vue.component(Step.name,Step)
Vue.component(TimePicker.name,TimePicker)
Vue.component(Container.name,Container)
Vue.component(Aside.name,Aside)
Vue.component(Header.name,Header)
Vue.component(Main.name,Main)
Vue.component(Menu.name,Menu)
Vue.component(Submenu.name,Submenu)
Vue.component(menuItemGroup.name,menuItemGroup)
Vue.component(menuItem.name,menuItem)
Vue.component(Button.name,Button)
Vue.component(DropdownMenu.name,DropdownMenu)
Vue.component(DropdownItem.name,DropdownItem)
Vue.component(Dropdown.name,Dropdown)
Vue.component(Row.name,Row)
Vue.component(Col.name,Col)
Vue.component(Card.name,Card)
Vue.component(Breadcrumb.name,Breadcrumb)
Vue.component(BreadcrumbItem.name,BreadcrumbItem)
Vue.component(Dialog.name,Dialog)
Vue.component(Form.name,Form)
Vue.component(FormItem.name,FormItem)
Vue.component(Input.name,Input)
Vue.component(Select.name,Select)
Vue.component(Option.name,Option)
Vue.component(TableColumn.name,TableColumn)
Vue.component(Table.name,Table)
Vue.component(Pagination.name,Pagination)
Vue.component(Alert.name,Alert)
Vue.component(Tag.name,Tag)
Vue.component(DatePicker.name,DatePicker)


 // 登录页添加cookie后需要使用全局前置路由守卫判断是否有用户信息的cookie，如果有则进行跳转首页
 // to: 即将要进入路由的目标对象 from: 当前导航正要离开的路由  next(false)：中断当前导航，next('/')跳转到其他地址 
//  router.beforeEach((to, from, next) => {
//   const token = Cookie.get('token');

//   // 如果用户尝试访问登录页或首页，并且未登录，放行，让其继续访问
//   if ((!token && to.name.includes('index')) || (!token && to.name === 'login')) {
//     next();
//   } 
//   // // 如果用户已登录，并且尝试访问首页，重定向到首页
//   else if (token && to.name.includes('index')) {
//     next({ name: 'home' });
//   } 
//   // // 如果用户未登录，并且尝试访问除了登录页和首页以外的页面，重定向到首页
//   else if (!token && !to.name.includes('index') && to.name !== 'login') {
//     next({ name: 'index/client_home' });
//   } 
//   // // 其他情况放行
//   else {
//     next();
//   }
// });

router.beforeEach((to, from, next) => {
  const token = Cookie.get('token');

  // 如果用户已登录
  if (token) {
    // 如果访问的是前台页面，跳转到后台首页
    if (to.name.includes('client')) {
      next({ name: 'home' });
    } else {
      next(); // 已登录但访问的是后台页面，放行
    }
  } else {
    // 如果用户未登录且访问的是后台页面或登录页面，跳转到前台首页
    if (!to.name.includes('client') && to.name !== 'login') {
      next({ name: 'clientHome' });
    } else {
      next(); // 未登录但访问的是前台页面或登录页，放行
    }
  }
});



new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
