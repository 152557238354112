import Vue from "vue"
import VueRouter from "vue-router"

import Main from "../views/Main"
import Home from "../views/Home.vue"
import personList from "../views/backstage/user/personList.vue"
import caseList from "../views/caseList.vue"
import newCase from "../views/newCase.vue"
import PageOne from "../views/PageOne"
import PageTwo from "../views/PageTwo"
import CarouselChart from "../views/CarouselChart"
import Login from "../views/Login"
import Index from "../views/index.vue"
import LeaveMessage from "../views/LeaveMessage.vue"
import OnlineServer from "../views/OnlineServer.vue"
import client_home from "../views/client/home.vue"
import client_show from "../views/client/show.vue"
import article_list from "../views/client/ArticleList.vue"
import clientArticleDetail from "../views/client/clientArticleDetail.vue"
import clientBusinessDetail from "../views/client/clientBusinessDetail.vue"
import CaseArticleList  from "@/views/client/CaseArticleList.vue"
import clientContactOurDetail from "@/views/client/clientContactOurDetail.vue"
import clientCaseArticleDetail from "@/views/client/clientCaseArticleDetail.vue"
import adminList from "@/views/backstage/user/personList.vue"
import customerList from "@/views/backstage/user/customerList.vue"
import staffList from "@/views/backstage/user/staffList.vue"
import companyList from "@/views/backstage/list/companyList.vue"
import myCertificateList from "@/views/backstage/list/myCertificateList.vue"
import applyTransfer from "@/views/backstage/applyTransfer.vue"
import CertificateList from "@/views/backstage/list/CertificateList"
import AT_AllApply from "@/views/backstage/list/AT_AllApply.vue"
import AT_ApprovalList from "@/views/backstage/list/AT_ApprovalList.vue"
import AT_ReturnedList from "@/views/backstage/list/AT_ReturnedList.vue"
import certificateApproval from "@/views/backstage/certificateApproval.vue"
import CA_AllApprovalList from "@/views/backstage/list/CA_AllApprovalList.vue"
import CA_WaitApprovalList from "@/views/backstage/list/CA_WaitApprovalList.vue"
import CA_WaitDepositList from "@/views/backstage/list/CA_WaitDepositList.vue"
import CA_WaitPostList from "@/views/backstage/list/CA_WaitPostList"
import approvalDetail from "@/views/backstage/detail/approvalDetail.vue"
import certificateRSDetail from "@/views/backstage/detail/certificateRSDetail.vue"

// 配置这段，重复点击路由将不会报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
return originalPush.call(this, location).catch(err => err)
}

// 1.创建路由组件
// 2.创建routes对象并与路由组件进行映射
// 3.创建并暴露router实例

const routes = [
    {
        path:'/',
        redirect: '/index/client_home',
    },
    {
        path:'/index',
        name:'index',
        
        component: Index,
        children: [
          { path: 'client_home', name: 'clientHome', component: client_home },
          {
            path: 'client_show',
            name: 'clientShow',
            component: client_show,
            children: [
              { path: 'article_list', name: 'clientArticleList', component: article_list },
              { path: 'clientArticleDetail', name: 'clientArticleDetail', component: clientArticleDetail },
              { path: 'clientBusinessDetail', name: 'clientBusinessDetail', component: clientBusinessDetail },
              { path: 'CaseArticleList', name: 'clientCaseArticleList', component: CaseArticleList },
              { path: 'clientContactOurDetail', name: 'clientContactOurDetail', component: clientContactOurDetail },
              { path: 'clientCaseArticleDetail', name: 'clientCaseArticleDetail', component: clientCaseArticleDetail },
            ]
          },
        ]
      },
    {
         path: '/',
         component: Main, 
        //  redirect 当路径为/  时，重定向为home，用于首页点击跳转
        //  redirect: '/home',
         children:[
            // 配置二级路由，path不用加/
            { path: 'home', name:'home', component: Home ,},//首页
            {path:'admin_list',name:'adminList',component:adminList},
            {path:'customer_list',name:'customerList',component:customerList},
            {path:'company_list',name:'companyList',component:companyList},
            {path:'staff_list',name:'staffList',component:staffList},
            { path: 'person_list', name:'personList',component: personList },//用户管理
            { path: 'case_list', name:'caseList',component: caseList },//案例管理
            { path: 'new_case', name:'newCase',component: newCase },//案例管理
            { path: 'leave_message', name:'leaveMessage',component: LeaveMessage },
            { path: 'online_server', name:'onlineServer',component: OnlineServer },
            { path: 'PageOne', name:'PageOne',component: PageOne },
            { path: 'my_certificate_list', name:'myCertificateList',component: myCertificateList },
            { path: 'approvalDetail', name:'approvalDetail', component: approvalDetail ,},//证书流程审核
            { path: 'certificateRSDetail', name:'certificateRSDetail', component: certificateRSDetail ,},//证书申请操作详情页（证书退回、寄出入库）
            { 
              path: 'apply_transfer', 
              name:'applyTransfer',
              component: applyTransfer,
              children:[
                {path:'certificate_list',name:'CertificateList',component:CertificateList},
                {path:'AT_AllApply',name:'AT_AllApply',component:AT_AllApply},
                {path:'AT_ApprovalList',name:'AT_ApprovalList',component:AT_ApprovalList},
                {path:'AT_ReturnedList',name:'AT_ReturnedList',component:AT_ReturnedList}
              ]
          },
          {
            path:'certificate_approval',
            name:'certificateApproval',
            component:certificateApproval,
            children:[
              {path:'CA_AllApprovalList',name:'CA_AllApprovalList',component:CA_AllApprovalList},
                {path:'CA_WaitApprovalList',name:'CA_WaitApprovalList',component:CA_WaitApprovalList},
                {path:'CA_WaitDepositList',name:'CA_WaitDepositList',component:CA_WaitDepositList},
                {path:'CA_WaitPostList',name:'CA_WaitPostList',component:CA_WaitPostList},
            ]
          }
         ]

    },
    {
        path:'/login',
        name:'login',
        component:Login
    },
]

export default new VueRouter({
  // mode:'history',
    routes,
    // mode:'history'
})

