<template>
    <!-- 侧边栏组件 -->
  <el-menu
    v-if="dataIsSuccess"
    default-active="0"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    :collapse="isCollapse"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b"
  >
  <div class="asideTitle">
    <img src="/logo.png"/>
    <h3>{{isCollapse ? '华旭' : '华旭后台管理系统'}}</h3>
  </div>
  
    <!-- key和index只要是唯一指定的都行 -->
    <!-- 无子菜单item
    @click="clickMenu"  :    为item添加跳转路由操作 -->
    <el-menu-item  @click="clickMenu(item)" v-for="item in noChildren" :key="item.authId" :index="item.authName" >
      <!-- 动态拼接icon的class类名 -->
      <!-- <i :class="`el-icon-${item.icon}`"></i> -->
      <i :class="['iconfont',item.icon]"></i>
      <!-- <i class="iconfont icon-wenjian"></i> -->
      <span slot="title">{{ item.authName }}</span>
    </el-menu-item>

    <!-- 有子菜单item -->
    <el-submenu v-for="item in hasChildren" :key="item.authId" :index="item.authName">
      <template slot="title">
        <!-- <i :class="`el-icon-${item.icon}`"></i> -->
        <i :class="['iconfont',item.icon]"></i>
        <span slot="title">{{ item.authName }}</span>
      </template>
      <el-menu-item-group v-for="subItem in item.childAuth" :key="subItem.authId">
        <!-- <span slot="title">分组一</span> -->
        <el-menu-item @click="clickMenu(subItem)" :index="subItem.authName">{{subItem.authName}}</el-menu-item>
      </el-menu-item-group>
    </el-submenu>


  </el-menu>
</template>

<style lang="less" scoped>
.asideTitle{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.asideTitle img{
  margin: 0 3px;
  width: 20px;
  height: 20px;
}
.el-menu-vertical-demo{
  .iconfont{
    margin-right: 10px;
    font-size: 17px;
    color: #fff;
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.c{
  background-color: #c1c0c0;
}
/* less语法 */
.el-menu {
  height: 100vh;
  h3 {
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 16px;
    font-weight: 600;
  }
}
.el-aside::-webkit-scrollbar {
  display: none;
}
</style>

<script>
import { getAuthTree } from "../api/userinfo";
export default {
  data() {
    return {
      dataIsSuccess:false,
        // 侧边栏是否收缩
    //   isCollapse: false,

      AuthTree:{},
    };
  },
  computed: {
    // 对于上面的menuDate数据，分为有子菜单和无子菜单两种，我们要对上面的两种进行分类，以便进行循环遍历菜单栏
  
    noChildren() {
      // return this.menuDate.filter((item) => !item.children);
      return this.AuthTree.filter((item) => !item.childAuth[0])
      // return this.
    },
    hasChildren() {
      return this.AuthTree.filter((item) => item.childAuth[0]);
    },
    isCollapse(){
        return this.$store.state.tab.isCollapse
    }
  },
  methods: {
    handleOpen(key, keyPath) {
    },
    handleClose(key, keyPath) {
    },
    // 点击菜单栏进行路由跳转
    clickMenu(item) {
      console.log("侧边栏被点击")
      console.log(item)
      // if(item.authUrl === '/admin_manage'){
      //   this.$router.push({path:'/user_manage',query:{userType:1}})
      //   return;
      // }
      // if(item.authUrl === '/staff_manage'){
      //   this.$router.push({path:'/user_manage',query:{userType:2}})
      //   return;
      // }
      // if(item.authUrl === '/customer_manage'){
      //   this.$router.push({path:'/user_manage',query:{userType:3}})
      //   return;
      // }

      // 【重要】 这里要对路由进行判断，防止对路由的重复跳转
      // this.$router.push(item.path)
      // 1.当前页面路由与要跳转的路由不一样才允许跳转
    // if (this.$route.path !== item.path && !(this.$route.path === '/home' && (item.path === '/'))) {
        // this.$router.push(item.authUrl)
        this.$router.push({name:item.authRouteName})
      // }
      // 2.跳转完后需要更新面包屑
      this.$store.commit('selectMenu',item)
    },
  },
  mounted() {
    // 获取权限菜单树
    getAuthTree()
      .then((response) => {
        if (response.data.success) {
          this.dataIsSuccess= true
          this.AuthTree = response.data.data
          console.log("this.AuthTree",this.AuthTree)
        }
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  },
};
</script>