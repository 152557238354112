import http from "../utils/request"


// 新建项目
export function addCase(data = {}){
    return http({
        method:'POST',
        url:'/case/add_case',
        data
    })

}


    // 查询项目列表
export function searchCase(data){
        return http({
            method:'GET',
            url:'/case/case_list',
            params:data
    })
}
    // 删除项目
    export function delCaseInfo(data){
        return http({
            method:'GET',
            url:'/case/del_case',
            params:data
    })
}