<template>
  <!-- 网页头部区域 -->
  <div class="header-container">
    <div class="left-content">
      <el-button class="fold-button" @click="handleMenu" size="mini"
        ><i class="el-icon-menu"></i
      ></el-button>
      <!-- 面包屑 -->
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="item in tags" :key="item.authId" :to="{ path: item.authUrl }"><span @click="clickBread(item)">{{ item.authName}}</span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="right-content">
      <div class="btn-box">
        <i class="el-icon-full-screen" @click="toggleFullScreen"></i>

      </div>
      
      <el-dropdown>
        <div class="dropdown-link">
          <img class="user-img" v-if="userinfo.headImg" :src="userinfo.headImg" />
          <div class="head-text" v-if="!userinfo.headImg">{{ userinfo.userName }}</div>
          <span class="userName">{{ userinfo.userName }}</span>
          
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item><i class="el-icon-user"></i>账户设置</el-dropdown-item>
          <el-dropdown-item @click.native="logout"><i class="el-icon-coordinate"></i>退出系统</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Cookie from "js-cookie";
import { Message } from "element-ui";
import { getUserinfoByToken } from "../api/userinfo";
import { logoutRequest } from "../api/login";
// import {mapState} from 'vuex'

export default {
  data() {
    return {};
  },
  methods: {


// 浏览器全屏与退出全屏
    toggleFullScreen() {
      const element = document.documentElement; // 获取整个文档根节点
      if (!document.fullscreenElement) {
        // 如果当前不是全屏状态，则请求全屏
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      } else {
        // 如果当前是全屏状态，则退出全屏
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    },

    // 控制菜单栏是否收缩
    handleMenu() {
      this.$store.commit("collapseMenu");
    },
    // 当面包屑被点击时，判断是否要减少面包屑
    clickBread(item) {
      this.$store.commit("clickBread", item);
    },
    logout() {
            // 浏览器清除token
            // Cookie.remove("token");
      // 向后端发请求，删除当前用户的token
      logoutRequest().then((response) => {
        if(response.data.success){
          Cookie.remove("token");
        }
        this.$router.push("/login");
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });      
    },
  },
  computed: {
    ...mapState({
      tags: (state) => state.tab.tabsList,
      userinfo: (state) => state.userinfo.current,
    }),
  },
  created() {
    getUserinfoByToken()
      .then((response) => {
        if (response.data.success) {
          console.log("头部获取用户信息",response.data)
          // 将用户信息保存在store
          this.$store.commit("setUserData", response.data.data);
        } else {
          Message.error("失败，请刷新浏览器");
          Cookie.remove("token");
        }
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  },
};
</script>

<style lang="less" scoped>
.dropdown-link{
  // background-color: #666;
  cursor: pointer;
  display: flex;
  align-items: center;
  .user-img {
  width: 45px;
  height: 45px;
  border-radius: 5px;
}
  .head-text{
    height: 45px;
						width: 45px;
						border-radius: 5px;
						margin-right: 18px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: #3c9cff;
						color: #fff;
						font-size: 14px;
  }
}
.fold-button {
  margin-right: 20px;
  // 透明色
  background: transparent;
  border: none;
  font-size: 24px;
}
.header-container {
  background-color: #333;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  .text {
    color: #fff;
    font-size: 14px;
    margin-left: 20px;
  }
}

.left-content {
  display: flex;
  align-items: center;
}
.right-content {
  display: flex;
  align-items: center;
  .btn-box{
    cursor: pointer;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    color: #fff;
  }
}
.userName {
  color: #fff;
  margin-right: 10px;
}
// 设置面包屑的颜色
.left-content {
  display: flex;
  align-items: center;
  /deep/.el-breadcrumb__item {
    .el-breadcrumb__inner {
      font-weight: normal;
      &.is-link {
        color: #666;
      }
    }
    &:last-child {
      .el-breadcrumb__inner {
        color: #fff;
      }
    }
  }
}
</style>