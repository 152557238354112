<template>
  <div style="display: flex; justify-content: center; flex-wrap: wrap">
    <ul class="articleList">
      <li
        v-for="item in articleArr"
        :key="item.id"
        @click="showArticleDetail(item)"
      >
        <span>{{ item.title }}</span
        ><span>{{ formatDate(item.createTime) }}</span>
      </li>
    </ul>
    <!-- <span>共{{total}}条</span> -->
    <el-pagination background layout="prev, pager, next" class="pagination" :current-page="currentPage" :page-size="pageSize" :total="total" @current-change="handerPage">
    </el-pagination>
  </div>
</template>

<script>
import { queryArticleListByCateId } from "@/api/article";
import { queryLabels, queryLabelDetail } from "@/api/aside_label";
import { mapState } from "vuex";
export default {
  data() {
    return {
      // articleArr: null,
      // newArticleArr: [],
      // knowledgeArticleArr: [],
      articleArr: [],//文章列表
      total: 0, //总条数
      pageSize:10,//每页显示的条数
      currentPage:1,//当前页数
    };
  },
  watch: {
    '$route.query': {
      handler() {
        this.fetchArticleArr();
      },
      deep: true
    }
  },
  methods: {
    // 格式化日期
    formatDate(dateString) {
      return dateString.split(" ")[0];
    },
    // 设置默认值pageSize = 10, pageNum = 1
    fetchArticleArr(pageSize = 10, pageNum = 1) {
      console.log("fetchArticleArr被调用！！")
      let labelType = this.$route.query.labelType;
      let labelId = this.$route.query.labelId;
        // 分页搜索文章列表
        queryLabelDetail({ labelType,labelId,pageSize,pageNum}).then((response) => {
          if (response.data.success) {
            console.log("分页查询文章数据")
            console.log(response.data)
            this.articleArr = response.data.data.resultList
            this.currentPage = pageNum;
            this.pageSize = pageSize;
            this.total = response.data.data.totalNum
          }
        });

    },

 // 分页点击时执行 val为页码
 handerPage(val) {
    this.fetchArticleArr(this.pageSize,val);
},

// 展示文章详情
showArticleDetail(item) {
      // 这种写法能利用vue路由进行跳转，并且会在浏览器新开窗口
      const url = this.$router.resolve({
        name: "clientArticleDetail",
        query: { ArticleId: item.id, labelType: item.labelType},
      }).href;
      
      const newWindow = window.open(url,"_blank");
      newWindow.document.title = "江西华旭-" + item.title;
      newWindow.addEventListener("load", function() {
    newWindow.document.title = "江西华旭-" + item.title;
});
    },
  },
  activated() {
    this.fetchArticleArr(this.pageSize,this.currentPage);
    
  },
  mounted() {},
  computed: {
    // articleArr(){
    // }
    //   return this.articleArrData;
    // let articleData = localStorage.getItem("ArticleArrData");
    // if(!articleData){
    //   console.log("缓存没数据")
    //   queryLabelDetail({labelType:this.$route.query.labelType}).then((response) =>{
    //     if(response.data.success){
    //       localStorage.setItem("ArticleArrData",JSON.stringify(response.data.data))
    //       return response.data.data
    //     }
    //   })
    // }else{
    // return JSON.parse(localStorage.getItem("ArticleArrData"))
    // }
    // }
  },
};
</script>

<style scoped>
.articleList {
  width: 100%;
  height: 500px;
}
.articleList li {
  /* width: 100%; */
  height: 50px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  cursor: pointer;
  /* background-color: red; */
  border-bottom: 1px dashed #cecece;
}
.articleList li:hover {
  color: #0006d4;
  background-color: #f3eded50;
}
.articleList li span:nth-child(1) {
  width: 800px;
  /* 超出内容隐藏并且用省略号表示 */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* background-color: red; */
}
.articleList li span:nth-child(2) {
  width: 10%;
}
.pagination {
  margin-top: 35px;
  /* margin: 0 !important; */
}
</style>