<template>
<div style="display: flex;justify-content: center;flex-wrap: wrap;">
  <div style="width: 100%;height: 100%;display: flex;flex-wrap: wrap;margin-top: 30px;">
    <div class="caseArticleItem" v-for="item in caseArticleArr" :key="item.id" @click="showCaseDetail(item)">
      <div class="caseArticleItemImg">
      <img :src="item.coverImg"/>
      </div>
      <span class="caseArticleTitle">{{ item.titleBreviary }}</span>
    </div>
  </div>
  <el-pagination background layout="prev, pager, next" class="pagination" :current-page="currentPage" :page-size="pageSize" :total="total" @current-change="handerPage"></el-pagination>
</div>
</template>

<script>
import {  queryLabelDetail } from "@/api/aside_label";
export default {
    data(){
        return{
        caseArticleArr:[],
        total: 0, //总条数
        pageSize:10,//每页显示的条数
        currentPage:1,//当前页数
        }
    },
    watch: {
    '$route.query': {
      handler() {
        this.fetchCaseArticleArr();
      },
      deep: true
    }
  },
  methods:{
    fetchCaseArticleArr(pageSize = 6, pageNum = 1) {
      let labelType = this.$route.query.labelType;
      let labelId = this.$route.query.labelId;
        queryLabelDetail({ labelType,labelId,pageSize,pageNum }).then((response) => {
          if (response.data.success) {
            this.caseArticleArr = response.data.data.resultList
            this.currentPage = pageNum;
            this.pageSize = pageSize;
            this.total = response.data.data.totalNum
          }
        });
    },
     // 分页点击时执行 val为页码
 handerPage(val) {
    this.fetchCaseArticleArr(this.pageSize,val);
},
// 跳转至案例详情页
showCaseDetail(item){
      const routePath = {
        name: "clientCaseArticleDetail",
        query: { ArticleId: item.id, labelType: item.labelType,labelId:item.labelId},
      };
      this.$router.push(routePath);
      console.log(item)
    },

  },
  activated() {
    this.fetchCaseArticleArr();
  },
}
</script>

<style scoped>
.caseArticleItem{
cursor: pointer;
  /* width: 30.5%; */
  /* margin-left: 22px; */
  margin: 0 0 18px 22px;
  width: 320px;
  height: 250px;
  /* background-color: red; */
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.caseArticleItemImg{
  width: 100%;
  height: 85%;
  overflow: hidden;
  /* background-color:red ; */
}
.caseArticleItem img{
  width: 100%;
	height: 100%; /* 图片高度自适应 */
	object-fit: cover; /* 图片在容器内居中显示，覆盖整个容器 */
	object-position: center; /* 图片在容器中居中显示 */
  transition: transform 0.3s ease; 
  -o-object-fit: cover;
  -o-object-position: center;
}
.caseArticleItem img:hover{
  transform: scale(1.05);
}
.caseArticleItem:hover .caseArticleTitle {
  color: rgb(43, 24, 255);
}
.caseArticleTitle{
  width: 100%;
  height: 12%;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.pagination {
  margin-top: 35px;
}
</style>