<template>
  <div v-if="business.length > 0">
    <!-- <div class="myslick">
					<div class="item">
						<span>1212</span>
					</div>
					<div class="item">
			
					</div>
					<div class="item">
			
					</div>
					<div class="item">
			
					</div>
					<div class="item">
			
					</div>
				</div> -->
    <el-carousel :interval="4000" type="card" height="300px">
      <el-carousel-item>
        <img class="slideshow" src="https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E8%BD%AE%E6%92%AD%E5%9B%BE1.png"/>
      </el-carousel-item>
      <el-carousel-item>
        <img class="slideshow" src="https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E8%BD%AE%E6%92%AD%E5%9B%BE2.png"/>
      </el-carousel-item>
      <el-carousel-item>
        <img class="slideshow" src="https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E8%BD%AE%E6%92%AD%E5%9B%BE1.png"/>
      </el-carousel-item>
    </el-carousel>
    <!-- -------------------------------关于我们------------------------------- -->
    <div class="aboutOurBox" id="aboutOur">
      <h2><i class="iconfont h2-font">&#xe604;</i>关于我们</h2>
      <div class="aboutOur">
        <div class="aboutOurText">
          <span class="aboutOurTitle">江西省华旭工程咨询有限公司</span>
          <p class="aboutOurDescribe">
            &emsp;&emsp;本公司成立于2019年，是一家专注于为客户提供全方位工程咨询服务的领先企业。业务范围包括编制项目建议书与可行性研究报告，以及投资估算、工程预算等，项目实施过程中可以完成全过程跟踪服务与工程质量监理服务，以及工程结算的评审与项目经济评价等。我们致力于通过专业的咨询和技术解决方案，帮助客户实现项目目标，提升项目价值，以及降低风险。我们的团队由经验丰富、充满创新精神的工程团队组成，具备跨行业、跨领域的专业知识和丰富的项目实践经验。
          </p>
          <div class="downloadBrochure-box">
            <div class="downloadBrochure">
              <img src="https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/pdf%E6%96%87%E4%BB%B6.png"/>
              <!-- <span></span> -->
              <a href="https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/%E5%8D%8E%E6%97%AD%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E5%86%8C.pdf">华旭企业宣传册.pdf &emsp;下载</a>
            </div>
            <!-- <a href="https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E7%B4%A0%E6%9D%90/%E5%8D%8E%E6%97%AD%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E5%86%8C.pdf">下载</a> -->
          </div>
        </div>
        <div class="aboutOurImg">
          <img src="../../assets/imgs/manageImg.jpg" />
        </div>
      </div>
    </div>
    <!-- -------------------------------关于我们------------------------------- -->
    <!-- ----------------------------- 项目 ------------------------------->
    <div class="project">
      <!-- <section id="project" class="project-selection"></section> 业务体系涵盖了项目成本管理、预算编制、招投标控制、工程量清单计量、变更管理、支付审批等方面。其核心目标是确保工程项目的成本控制、质量管理和进度执行，以实现项目的高效、经济、质量可控。-->
      <div class="project-title">
        <h2><i class="iconfont h2-font">&#xe63d;</i>业务体系</h2>
        <p style="font-size: 17px; color: #333">
          业务体系涵盖多个方面，其核心目标是确保工程项目的成本控制、质量管理和进度执行，以实现项目的高效、经济、质量可控。
        </p>
        <div class="more-public top-more">
          <router-link
            :to="{
              name: 'clientBusinessDetail',
              query: { labelType: 1, labelId: business[0].labelId },
            }"
          >
            更多
            <i class="iconfont more-font">&#xe631;</i>
          </router-link>
        </div>
      </div>
      <ul>
        <li
          v-for="item in business"
          :key="item.businessId"
          @click="showBusinessDetail(item)"
        >
          <div class="business-title">
            <!-- v-html:将&#xe631;当成html元素处理 -->
            <i class="iconfont business-font" v-html="item.icon"></i>
            <span>{{ item.title }}</span>
          </div>
          <div class="business-describe">
            {{ item.describeInfo }}
          </div>
        </li>
      </ul>
    </div>
    <!-- -----------------------------案例------------------------------ -->
    <div class="classic-case">
      <!-- <section id="classic-case" class="classic-selection"> </section> -->

      <div class="classic-title">
        <h2><i class="iconfont h2-font">&#xe648;</i>案例推荐</h2>
        <p class="">
          
        </p>
        <div class="more-public top-more">
          <router-link
              :to="{
                name: 'clientCaseArticleList',
                query: { labelType: 2, labelId: caseArr[0].labelId },
              }"
            >
              更多
              <i class="iconfont more-font">&#xe631;</i>
          </router-link>
        </div>
      </div>
      <div class="caseList">
        <!-- :class="['caseItem', caseClass(index)]"  设置css，第一个css为caseItem，第二个根据函数返回值确定 -->
        <div v-for="(item,index) in caseArr" :key="item.id" @click="showCaseDetail(item)" :class="['caseItem', caseClass(index)]">
          <img :src="item.coverImg" />
          <span class="case_name">{{item.titleBreviary}}</span>
        </div>
       
      </div>
    </div>
    <!-- ---------------------公司动态和新闻  ====================== -->
    <div class="news-and-conpany">
      <!-- <section id="conpany-dynamic"></section> -->
      <!-- <section id="news"></section> -->
      <div class="conpany-dynamic">
        <div class="buttom-title">
          <h2><i class="iconfont h2-font">&#xe612;</i>新闻动态</h2>
          <div class="more-public top-more">
            <router-link
              :to="{
                name: 'clientArticleList',
                query: { labelType: 3, labelId: news[0].labelId },
              }"
            >
              更多
              <i class="iconfont more-font">&#xe631;</i>
            </router-link>
          </div>
        </div>
        <ul>
          <li
            v-for="(item, index) in news"
            :key="item.id"
            @click="showArticleDetail(item)"
          >
            <div class="serial-number">{{ index + 1 }}</div>
            <div class="serial-text">
              <span>{{ item.title }}</span>
              <span>{{ item.createTime }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="news">
        <div class="buttom-title">
          <h2><i class="iconfont h2-font">&#xe8ae;</i>知识分享</h2>
          <div class="more-public buttom-more">
            <router-link
              :to="{
                name: 'clientArticleList',
                query: { labelType: 4, labelId: knowledge[0].labelId },
              }"
            >
              更多
              <i class="iconfont more-font">&#xe631;</i>
            </router-link>
          </div>
        </div>
        <ul>
          <li
            v-for="(item, index) in knowledge"
            :key="item.id"
            @click="showArticleDetail(item)"
          >
            <div class="serial-number">{{ index + 1 }}</div>
            <div class="serial-text">
              <span>{{ item.title }}</span>
              <span>{{ item.createTime }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getHomeData } from "@/api/home_data";
export default {
  data() {
    return {
      // newsArr:[],
      // knowledgeArr:[],
      // businessArr:[],
    };
  },
  methods: {
    // 动态计算案例推荐模块的css
    //  0   1    2    3    4    5
    // 大  小  大   小   大  小
    caseClass(index) {
      console.log(index)
      if (index % 2 === 0) {
    return 'bigCaseItem';
  } else {
    return 'smallCaseItem';
  }
  },
    formatDate(dateString) {
      return dateString.split(" ")[0];
    },
    // 跳转至案例详情页
    showCaseDetail(item){
      const routePath = {
        name: "clientCaseArticleDetail",
        query: { ArticleId: item.id, labelType: item.labelType,labelId:item.labelId},
      };
      this.$router.push(routePath);
      console.log(item)
    },
    // 跳转至展示文章详情
    showArticleDetail(item) {
      // 这种写法能利用vue路由进行跳转，并且会在浏览器新开窗口
      const url = this.$router.resolve({
        name: "clientArticleDetail",
        query: { ArticleId: item.id, labelType: item.labelType,labelId:item.labelId},
      }).href;
      window.open(url, "_blank");
    },
    // 跳转至展示业务详情
    showBusinessDetail(item) {
      const routePath = {
        name: "clientBusinessDetail",
        query: { labelType: 1, labelId: item.labelId },
      };
      this.$router.push(routePath);
    },
  },
  created() {
    // getHomeData().then((response) =>{
    //     console.log("首页数据")
    // 	// 将首页数据保存在vuex
    //     console.log(response.data)
    // 	this.$store.commit("setBusinessArrData",response.data.data.business)
    // 	this.businessArr = response.data.data.business
    // 	this.$store.commit("setHomeArticleListData",response.data.data.articles)
    //     console.log(this.knowledgeArr)
    //     console.log(this.newsArr)
    // })
  },
  computed: {
    ...mapState({
      caseArr: (state) => state.home_data.homeData.caseArr,
      news: (state) => state.home_data.homeData.newsArr,
      knowledge: (state) => state.home_data.homeData.knowledgeArr,
      business: (state) => state.home_data.homeData.businessArr,
      //     businessitem:(state) => state.client_aside.asideDeatilData.businessitem
    }),

  },
  mounted() {
    // $(function() {
    // 		$('.myslick').slick({
    // 			centerMode: true,
    // 			centerPadding: '38px',
    // 			prevArrow: '<button type="button" class="slick-prev"></button>',
    // 			nextArrow: '<button type="button" class="slick-next"></button>',
    // 			// 显示轮播图数量
    // 			slidesToShow: 3,
    // 			// 自动轮播
    // 			autoplay: true,
    // 			// 开启翻页
    // 			dots: true,
    // 			responsive: [{
    // 					// 当屏幕为768px时执行以下配置
    // 					breakpoint: 768,
    // 					settings: {
    // 						arrows: false,
    // 						centerMode: true,
    // 						centerPadding: '40px',
    // 						slidesToShow: 3
    // 					}
    // 				},
    // 				{
    // 					breakpoint: 480,
    // 					settings: {
    // 						arrows: false,
    // 						centerMode: true,
    // 						centerPadding: '40px',
    // 						slidesToShow: 1
    // 					}
    // 				}
    // 			]
    // 		});
    // 	});
    // 	document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    // 		anchor.addEventListener('click', function(e) {
    // 			e.preventDefault();
    // 			document.querySelector(this.getAttribute('href')).scrollIntoView({
    // 				behavior: 'smooth'
    // 			});
    // 		});
    // 	});
  },
};
</script>

<style>
.slideshow{
  width: 100%; /* 图片宽度占满父容器 */
    height: 100%; /* 图片高度占满父容器 */
    object-fit: cover; /* 图片等比例缩放并填充整个容器 */
}
/* el-carousel__item is-active el-carousel__item--card is-in-stage */

.el-carousel--horizontal {
  margin: 30px 0;
}
/* .el-carousel__item .is-active{
	background-color: red;
} */

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item {
  background-color: #99a9bf;
  opacity: 0.5;
}

/* .el-carousel__item:nth-child(2n+1) {
    background-color: #3e81cd;
  } */
.el-carousel__item--card.is-active {
  /* background-color: red; */
  opacity: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
    /* 上方阴影 */ 0 8px 12px rgba(0, 0, 0, 0.1),
    /* 中间阴影 */ 0 12px 18px rgba(0, 0, 0, 0.1),
    /* 底部阴影 */ 0 16px 24px rgba(0, 0, 0, 0.1); /* 更远的阴影 */
}
.business-title {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-size: 22px;
}
.business-describe {
  line-height: 35px !important;
}
.business-font {
  margin-right: 15px;
  font-size: 40px;
  color: #0006d4;
}
.downloadBrochure-box{
  width: 100%;
  display: flex;
  align-items: center;
}
.downloadBrochure-box a:hover{
  color: #0006d4;
  text-decoration: underline !important;
}
.downloadBrochure{
  width: 260px;
  height: 50px;
  margin-right: 15px;
  border-radius: 5px;
  border: 0.5px solid #47566970;
  display: flex;
  align-items: center;
  /* background-color: #475669; */
}
.downloadBrochure img{
  width: 26px;
  height: 26px;
  margin: 0 10px;
}
/* 表格整体样式 */
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: Arial, sans-serif;
  border: 1px solid #f2f2f2;
}

/* 表头样式 */
th {
  background-color: #f2f2f2;
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

/* 偶数行样式 */
tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* 单元格样式 */
td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

/* 鼠标悬停时样式 */
tr:hover {
  /* background-color: #f5f5f5; */
}

/* 鼠标悬停在表头时的样式 */
th:hover {
  /* background-color: #e0e0e0; */
}
</style>