<template>
  <div class="contant-our">
    <div class="top-logo">
      <img src="https://yuan-house.oss-cn-shenzhen.aliyuncs.com/logo.png" />
      <img
        src="https://yuan-house.oss-cn-shenzhen.aliyuncs.com/company-name.png"/>
    </div>
    <div class="contant-info">
      <span>工作时间：工作日（8：30am-17：30pm）</span><br/>
        <span>电话：18607949427&emsp;&emsp;13133716583</span><br/>
        <span>邮编：334001</span><br/>
        <span>网址：www.huaxugczx.com</span><br/>
        <span>地址：江西省上饶市高铁经济试验区凤凰东大道16号饶商总部基地7幢16楼</span>
    </div>
    <div class = "info">可按住鼠标右键控制地图旋转、修改倾斜角度。</div>
    <div ref="mapContainer" id="container"></div>
  </div>
</template>

<script>
export default {
    mounted() {
    // 在组件挂载后执行初始化地图等操作
    this.initMap();
  },
  methods: {
    initMap() {
      // 在这里调用百度地图的 API 初始化地图
      var map = new BMapGL.Map(this.$refs.mapContainer); // 创建Map实例
      map.centerAndZoom(new BMapGL.Point(118.012829, 28.490292), 18); // 初始化地图,设置中心点坐标和地图级别
      map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
      var marker1 = new BMapGL.Marker(new BMapGL.Point(118.012829, 28.490292));
      map.addOverlay(marker1);
    }
  }
};
</script>

<style scoped>
.contant-our{
    padding-top: 30px;
}
.top-logo{
    display: flex;
    align-items: center;
}
.top-logo img:nth-child(1){
width: 60px;
}
.contant-info{
    margin-top: 20px;
font-size: 16px;
line-height: 35px;
}

body,
    html,
      #container {
          overflow: hidden;
          width: 100%;
          height: 500px;
          margin: 0;
          font-family: "微软雅黑";
          position: relative;
      }
      .info {
          z-index: 96;
          width: 300px;
          position: relative;
          left: 50%;
          top:55px;
          transform: translateX(-50%);
          min-width: 18rem;
          padding: .75rem 1.25rem;
          /* position: fixed; */
          background-color: #fff;
          border-radius: .25rem;
          font-size: 14px;
          color: #666;
          box-shadow: 0 2px 6px 0 rgba(27, 142, 236, 0.5);
      }
</style>