<template>
    <div style="height: 100%;padding-top: 20px;">
        <div class="case-title">
            <img :src="caseArticle.coverImg"/>
            <span>{{ caseArticle.titleBreviary }}</span>
        </div>
        <div v-html="caseArticle.content"></div>
    </div>
</template>

<script>
import {queryCaseArticleInfoById} from "@/api/article"
export default {
    data(){
        return{
            caseArticle:{}
        }
    },
    methods:{
        queryCaseDetail(){
            this.caseArticle = {}
            queryCaseArticleInfoById({ArticleId:this.$route.query.ArticleId}).then((response) =>{
            if(response.data.success){
                console.log(response.data)
                this.caseArticle = response.data.data
            }
        })
        }
    },
    created() {
        
},

activated(){
    this.queryCaseDetail()
}

}
</script>

<style scoped>
.case-title{
    width: 70%;
    height: 420px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 auto;
}
img{
    width: 100%;
    height: 90%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}
</style>