import http from "../utils/request"

export  function getUserinfo(id){
   return http({
        method:'GET',
        url:`/user/getuserinfo/${id}`
    })
}



// 用户登录到首页，根据token获取用户信息
export function getUserinfoByToken(){
   return http({
        method:'GET',
        url:'/user/curr-user',
    })

}

// 用户登录到首页，加载侧边菜单树
export function getAuthTree(){
    return http({
        method:'GET',
        url:'/user/auth-list'
    })
}

// 查询用户列表
export function queryUserList(data = {}){
    return http({
        method:'GET',
        url:'/user/user_list',
        params:data
    })
}

// 添加用户
export function addUser(data){
    return http({
        method:'POST',
        url:'/user/addUser',
        data:data
    })
}

// 切换用户状态
export function switchState(data){
    return http({
        method:'POST',
        url:'/user/switchState',
        data
    })
}

// 更改用户角色
export function updateUserRole(data = {}){
    return http({
        method:'GET',
        url:'/user/updateUserRole',
        params:data
    })
}

// 删除单个用户
export function deleteUser(data = {}){
    return http({
        method:'GET',
        url:'/user/deleteUser',
        params:data
    })
}

// 批量删除用户
export function batchDeleteUser(data = {}){
    return http({
        method:'POST',
        url:'/user/batchDeleteUser',
        data
    })
}