<template>
  <div style="display: flex; justify-content: center">
    <el-form
      :model="caseForm"
      :rules="rules"
      ref="caseForm"
      label-width="100px"
      class="demo-ruleForm"
      style="
        display: flex;
        flex-direction: column;
        width: 85%;
        justify-content: center;
      "
    >
      <el-form-item
        label="项目名称"
        placeholder="请输入项目名称"
        prop="caseName"
        style="width: 100%"
      >
        <el-input v-model="caseForm.caseName"></el-input>
      </el-form-item>

      <el-form-item label="项目描述" prop="caseDescribe" style="width: 100%">
        <el-input
          type="textarea"
          v-model="caseForm.caseDescribe"
          :autosize="{ minRows: 5 }"
        ></el-input>
      </el-form-item>
      <div style="display: flex;justify-content: space-between;width: 100%">
      <el-form-item label="项目类型" prop="caseType" style="width: 30%;">
        <el-select v-model="caseForm.caseType" placeholder="请选择项目类型" style="width: 100%;">
          <el-option label="甲方项目" value="1"></el-option>
          <el-option label="乙方项目" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="服务类型" prop="caseType" style="width: 30%;">
        <el-select v-model="caseForm.serviceType" placeholder="请选择服务类型" style="width: 100%;">
          <!-- 土建，安装，市政，公路，水利，其他 -->
          <el-option label="土建" value="1"></el-option>
          <el-option label="安装" value="2"></el-option>
          <el-option label="市政" value="3"></el-option>
          <el-option label="公路" value="4"></el-option>
          <el-option label="水利" value="5"></el-option>
          <el-option label="其他" value="6"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="项目时间范围" style="width: 40%">
        <el-date-picker
          v-model="caseForm.dateScoped"
          type="daterange"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 100%;"
        >
        </el-date-picker>

      </el-form-item>
    </div>
      <el-form-item   style="display: flex;width: 100%">
        
        <!-- <el-upload :file-list="fileListInfo" :on-change="onChange" action="http://localhost:8080/huaxu-company/file/upload_file" list-type="picture-card" ref="upload" :auto-upload="true">
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{ file }">
           <div style="background-color: red">
            <img
              class="el-upload-list__item-thumbnail"
              style="height: 90%;"
              :src="file.url"
              alt=""
            />
            
              
            
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleDownload(file)"
              >
                <i class="el-icon-download"></i>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
              
            </span>
           </div>
           <span style="position: absolute;bottom: 0px;">{{file.name}}</span>
          </div>
          
        </el-upload> -->
        <el-upload
          style="width: 100%;"
          class="upload-demo"
          action="https://huaxugczx.com:8666/huaxu-company/file/upload_file"
          :on-preview="handlePreview"
          :on-remove="removeFile"
          :on-success="uploadSucess"
          :on-change="onChange"
          :file-list="fileList"
          :show-file-list="true"
          list-type="picture"
          :multiple="true"
          :drag="true"
        >
          <el-button  type="primary" class="upload-button"><template><i slot="default" class="el-icon-upload"></i><div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div><div class="el-upload__tip">单个文件大小不超过50MB</div></template></el-button> -->
        </el-upload>

      </el-form-item>
      <el-form-item>
        <el-button style="margin-right: 40px" type="primary" @click="submitForm()">立即创建</el-button>
        <el-button @click="resetForm()">重置</el-button>
      </el-form-item>
    </el-form>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" v-if="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
  
  <script>
import { MessageBox } from "element-ui";
import {Message} from "element-ui"
import { addCase } from "../api/case";
export default {
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      currentUid:'',//当前变动文件的uid
      fileList:[],
      fileLisBody: [],
      caseForm: {
        caseName: "",
        caseDescribe: "",
        caseType: "",
        serviceType:"",
        dateScoped: [],
        fileNameList: [],
        fileIdList: [],
      },
      // type name 
      // fileListInfo: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}],
      rules: {
        caseName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        caseDescribe: [
          { required: true, message: "请输入项目描述", trigger: "blur" },
        ],
        caseType: [
          { required: true, message: "请选择项目类型", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handlePreview(file) {
      console.log(file);
    },
    // 提交表单 确定文件
    submitForm() {
      
      this.$refs["caseForm"].validate((valid) => {
        if (valid) {

          // fileNameList 为文件的uuid
          this.caseForm.fileIdList = this.fileList.map(item =>{
        return item.response.data
      })
      console.log("this.fileIdList")
        console.log(this.caseForm.fileIdList)
          addCase(this.caseForm).then((response) => {
            if(response.data.success){
              Message.success(response.data.message);
            }
            console.log(response.data);
           
          });
          this.caseForm = {}
          this.fileList=[]
            console.log(this.fileList)
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(){
      console.log("重置")
      this.caseForm = {}
      this.fileList=[]
    },
    // 获取文件名
   getFileType(filename) {  
    const extension = filename.split('.').pop();  
    return extension.toLowerCase();  
},
    // 上传文件成功后 根据文件后缀分配预览图片
    uploadSucess(response, file, fileList){

      // 要根据文件类型，展示预览文件
      const fileType = this.getFileType(file.name);
      console.log("获取到新上传的文件类型"+this.getFileType(file.name))
      switch (fileType) {  
        case 'docx':
        case 'doc':  
            file.url = "https://huaxugczx.oss-cn-guangzhou.aliyuncs.com/material/common/img/word.png"
            break;  
        case 'pptx':
        case 'ppt':  
            file.url="https://huaxugczx.oss-cn-guangzhou.aliyuncs.com/material/common/img/ppt.png" 
            break;
        case 'pdf':  
            file.url="https://huaxugczx.oss-cn-guangzhou.aliyuncs.com/material/common/img/pdf.png"   
            break;   
        case 'xlsx':
        case 'xls':  
            file.url="https://huaxugczx.oss-cn-guangzhou.aliyuncs.com/material/common/img/excel.png"
            break; 
        case 'zip':
        case 'rar':   
        file.url = "https://huaxugczx.oss-cn-guangzhou.aliyuncs.com/material/common/img/zip.png"  
            break; 
        case 'png': 
        case 'jpg':
        case 'jpeg':
        case 'bmp':
        case 'gif':
            break;  
        case 'mp4':
            file.ur = "https://huaxugczx.oss-cn-guangzhou.aliyuncs.com/material/common/img/video.png"
            break; 
        default:  
            file.url = "https://huaxugczx.oss-cn-guangzhou.aliyuncs.com/material/common/img/others.png" 
    }
      // file.url = 
    },
    // 移除文件
    removeFile(file, fileList){
      
      this.fileList = fileList
      console.log("移除文件")
      console.log(fileList)
    },

    // resetForm(caseForm) {
    //   this.$refs[caseForm].resetFields();
    // },
    selectForm(caseForm) {
      console.log(this.caseForm.fileList);
    },
    beforeRemove(file, fileList) {
      MessageBox.confirm(`确定移除 ${file.name}？`);
    },

    // 当文件状态改变时的钩子函数 file为当前文件， fileList为文件数组
    onChange(file, fileList) {
      this.fileList = fileList
    },

    handlePictureCardPreview(file) {
      const formData = new FormData();
      formData.append("file", file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
    },
    // },
  },
  watch:{
    fileList(newVal,oldVal){
      console.log("发送变化")
      console.log(newVal)

    }
  }
};
</script>
  
  <style scoped>
  /* .upload-demo{
    display: flex;
    align-items: center;
  } */
  .el-icon-upload{
    margin-top: 0;
  }
  .upload-button{
    background-color: white;
    height: 100%;
    width: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-icon-plus{
    color: black;
    font-size: 22px;
  }
/* .upload-demo {
} */
.upload-demo {
  display: flex;
  /* justify-content: center; */
  width: 100% !important;
}

.el-upload-list__item{
margin: 0 !important;

}
.el-upload-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  /* width: 1100px !important; */
}



.el-upload-dragger{
/* width: 1000px!important; */
width: 100%;
}


.el-upload--picture{
  width: 100%;
}

</style>
<style>


</style>