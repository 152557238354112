// 向后端发请求的接口文件

import http from "../utils/request"


// 请求首页数据
export const getData = () =>{
    // 返回的是promins
    return http.get("/login/captchaImage",{responseType: "blob",})
}

// 获取验证码图片
export function getCaptchaImage(params = {}){
    return http({
        method:'GET',
        url:'/login/captchaImage',
        params,
        responseType: "blob"
    })
}

// 进行登录验证
export function login(data = {}){
    return http({
        method:'POST',
        url:'/login/verify',
        data,
    })
}

// 进行退出登录
export function logoutRequest(data = {}){
    return http({
        method:'GET',
        url:'/logoutRequest',
        data
    })
}