<template>
  <div style="height: 100%; width: 100%" >
    <el-table :data="applyData" style="width: 100%">
      <el-table-column label="申请编号" prop="applyId"> </el-table-column>
      <el-table-column label="申请人" prop="userName"> </el-table-column>
      <el-table-column width="270" label="证书名称" prop="name">
        <template slot-scope="scope">
          <span
            style="line-height: 28px"
            v-for="(name, index) in certificateInfo(scope.row.certificateList)"
            :key="index"
            >{{ name }}<br
          /></span>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="approvalState">
        <template slot-scope="scope">
          <el-tooltip
            effect="dark"
            content="Top Left 提示文字"
            placement="top-start"
          >
            <el-tag :type="ststeTagType(scope.row.approvalState)">{{
              stateFormat(scope.row.approvalState)
            }}</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="申请时间" prop="createTime"> </el-table-column>
      <el-table-column prop="create_time" label="操作" width="270">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="approvelApply(scope.row.applyId)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: flex-end; align-items: center">
      <span>共{{ total }}条</span>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        :total="total"
        @current-change="handerPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      applyData: [],
      applyStateList: ["0"],
      total: 0, //总条数
      page: {
        pageSize: 6,
        pageNum: 1,
      },
      certificateApply: {
      },
      approvelDialogVisible:false
    };
  },
}
</script>

<style>

</style>