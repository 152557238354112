<template>
 <div>
	
    <AsideContact/>
  <div class="shade">
    <div class="shade-top shade-item"></div>
    <div class="container">
        <Header/>
		<img src="https://yuan-house.oss-cn-shenzhen.aliyuncs.com/1711012548571.png" style="margin-top: 100px;"/>
				<!-- <section id="index"></section> -->
			<keep-alive>
				<router-view></router-view>
			</keep-alive>
        <ButtomContactOur/>
    </div>
    <div class="shade-info shade-item"></div>
	<div class="shade-record shade-item"></div>
  </div>

</div>
</template>


<script>
import AsideContact from '@/components/client/AsideContact.vue';
import Header from "@/components/client/Header.vue"
import ButtomContactOur from "@/components/client/ButtomContactOur.vue"
export default {
	data(){
		return{
			asideContact:'',
		}
	},
    components:{
        AsideContact,
        Header,
        ButtomContactOur
    },
};
</script>

<style>
header {
	/* 去掉内边距,!important:权重最高 */
	padding-left: 0px !important;
	/* background-color: red; */
	/* padding: 0 100px; */
	position: relative;
}

body {
	/* background-color: black; */
	margin: 0;
	padding: 0;
	font-family: Arial, Helvetica, sans-serif;
	/* background-color: #f7f7f7; */
}

* {
	margin: 0;
	padding: 0;
}


table{
	margin: 0 auto;
}

a {
	text-decoration: none !important;
	color: black;
}

.container {
	width: 1300px;
	margin: 0 auto;
	overflow: hidden;
	/* min-height: 500px; */
	/* overflow: hidden; */
	background-color: #ffffff;
}
.head-logo{
	display: flex;
	align-items: center;
	cursor: pointer;
}
.head-logo a {
	font-size: 19px;
	display: inline
}

.head-logo img:nth-child(1) {
	width: 50px;
	margin-right: 5px;
	display: inline
}

.head-logo img:nth-child(2) {
	width: 70%;
	display: inline
}
.asideContactFrame{
	
}
.head-box {
	width: 1300px;
	height: 100px;

	/* padding: 0 5%; */
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: rgba(255, 255, 255, 0);
	background-color:  #ffffff;
	/* box-shadow: 0 8px 0px rgba(0, 0, 0, 0.1); */
	/* box-shadow: 0 12px 0px rgba(0, 0, 0, 0.1); */
	position: fixed;
	top: 0;
	z-index: 200;
	 
}

.head-box ul {
	height: 100%;
	display: flex;
	align-items: center;
	margin: 0;
	/* background-color: red; */
	font-size: 18px;
}

.head-box ul li {
	height: 100%;
	width: 80px;
	padding: 0 15px;
	cursor: pointer;
	/* border-bottom: solid 3px red; */
	/* background-color: #ff4; */
	display: flex;
	justify-content: center;
	align-items: center;
}
.head-box ul a{
	cursor: pointer;
	display: flex;
	align-items: center;
	/* display: block; */
	height: 100%;
}

.head-box ul li:hover {
		/* border-bottom: solid 3px #4e6ef2; */
		background-color: #1947ff11;
	    font-weight: 550;
}

.search-login {
	margin-right: 25px;
	/* background-color: #ff4; */
}

.search-login input {
	width: 171px;
	height: 38px;
	border: solid 1px #000;
	border-radius: 15px;
	padding: 0  36px 0 10px;
	position: relative;
}

.search-login a {
	margin: 0 0 0 25px;
	font-size: 18px;
}
.search-login a:hover{
	color: red;
	font-weight: 650;
	/* margin: 0 0 0 25px; */
	/* font-size: 18px; */
}

.myslick {
	width: 100%;
	height: 400px;
	/* padding: 0 60px; */
	/* text-align: center; */
	margin-top: 60px;
	position: relative;
	padding: 0;
	/* background-color: #55ff7f; */
}

.myslick .slick-center {
	transform: scale(1.2);
	/* z-index: 99999; */
	background: color #aa0000;
}

.item {
	height: 260px;
	/* width: 590px !important; */
	margin: 0 15px;
	float: left;
	/* display: block; */
	transform: scale(0.8);
	transition: 0.5s;
	background-color: #9a9a9a;
	box-shadow: 
	        0 4px 6px rgba(0, 0, 0, 0.1), /* 上方阴影 */
	        0 8px 12px rgba(0, 0, 0, 0.1), /* 中间阴影 */
	        0 12px 18px rgba(0, 0, 0, 0.1), /* 底部阴影 */
	        0 16px 24px rgba(0, 0, 0, 0.1); /* 更远的阴影 */
}

.myslick button {
	height: 50%;
	width: 50px;
	border: none !important;

	/* background-size:90px; */
}

.slick-prev {
	position: absolute;
	right: 0;
	top: 10%;
	z-index: 100;
	background-color: transparent;
	background-image: url("https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E7%AE%AD%E5%A4%B4.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	transform: scaleX(-1);
	cursor: pointer;
	/* cursor: pointer; */
}

.slick-next {
	position: absolute;
	left: 0;
	top: 10%;
	z-index: 100;
	background-color: transparent;
	background-image: url("https://yuan-house.oss-cn-shenzhen.aliyuncs.com/%E7%AE%AD%E5%A4%B4.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	/* 手型 */
	cursor: pointer;
}

.slick-dots {
	text-align: center;
	padding: 10px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	
}

.slick-dots li {
	/* li元素水平排列 */
	display: inline-block;
	width: 10px;
	height: 10px;
	background-color: #9a9a9a;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	/* margin: 35px 5px; */
	margin: 35px 5px 0px 5px;
}

.slick-dots li button {
	display: none;
}

.slick-dots li.slick-active {
	background-color: red;
	width: 25px;
	/* margin: 20px 5px; */
	transition: 0.5s;
}
.aboutOurBox{
	padding: 0 42px;
    margin-bottom: 50px;
}
.aboutOur{
	width: 100%;
	/* padding: 0 30px; */
	display: flex;
	/* flex-wrap: wrap; */
	justify-content: space-between;

}


.aboutOurText{
	/* padding-left: 42px; */
	width: 50%;
}
.aboutOurDescribe{
	font-size: 18px;
	color: #333;
	line-height: 35px;
}
.aboutOurTitle{
	line-height: 55px;
	font-size: 24px;
}
.aboutOurImg{
	width: 50%;
	overflow: hidden;
	margin-left: 15px;
	border-radius: 5px;
	/* background-color: #34c9ff; */
}
.aboutOurImg img{
	width: 100%; /* 图片宽度为容器的100% */  
  height: 100%; /* 图片高度自动，以保持原始宽高比 */  
  object-fit: cover; /* 图片内容将覆盖整个容器，同时保持其宽高比 */  
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease; /* 添加过渡效果 */
}
.aboutOurImg img:hover{
	transform: scale(1.15);
}
.project {
	height: 50%;
}
.project-selection{
	/* height: 140px;s */
	position: absolute;
	top:450px;
	/* padding-top: 220px; */
}
.classic-selection{
	position: absolute;
	top:900px;
}

/* .project ul {
	height: 75%;
	display: flex;
	flex-wrap: wrap;

    justify-content: flex-start;
}

.project li {
	margin-right: 3%;
	width: 30%;
	height: 280px;
	background-color: #9a9a9a;
	border-radius: 15px;
	list-style: none;
} */
.project ul {
    height: 75%;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: ; */
}

.project li {
    width: calc(31% - 10px); /* 计算每个元素的宽度，减去间隙 */
    height: 160px;
	cursor: pointer;
    /* background-color: #9a9a9a; */
    border-radius: 15px;
    list-style: none;
	margin-left: 35px;
    margin-bottom: 35px; /* 设置元素之间的垂直间距 */
}

/* .project li:nth-child(1),
.project li:nth-child(2) {
    width: calc(50% - 46px); 
} */
.business-title{
	width: 100%;
	margin-bottom: 15px;
	cursor: pointer;
	/* background-color: red; */
}
.business-title span:hover{
	color: #0006d4;
}
.business-describe{
	width: 100%;
	font-size: 18px;
	line-height: 30px;
	color: #333;
	display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3; /*控制文本显示的行数 */
}
.project-title {
	/* height: 20%; */
	height: 65px;
	margin: 0 40px 35px 40px;
	/* line-height: 20%; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;

	position: relative;
	border-bottom: solid 0.5px #b3b2b26b;

}
.project-title h2 {
	position: absolute;
	top: 0;
}
.project-title p {
	position: absolute;
	bottom: 2px;
}

.more-public {
	width: 100px;
	height: 50px;
	position: absolute;
	bottom: -15px;
	text-align: center;
	line-height: 50px;
}
.top-more{
	right: 15px;
}
.buttom-more{
	right: 15px;
}
.buttom-title{
	height: 60px;
	margin: 0 20px;
	display: flex;
	position: relative;
	border-bottom: solid 1px #000;
}
.buttom-title h2{
	position: absolute;
	left: 0;
	bottom: 0;
}
.more-public a:hover {
	font-weight: 600;
	color: red;
	border-bottom: solid 1px red;
}
.case-title{
	 padding: 10px 3%;
	 line-height: 20%;
	 margin-top: 80px;
 }
.project-title-span{
	  width: 50%;
	  height: 50%;
	  font-size: 18px;
	  line-height: 18px;
	  text-overflow: ellipsis;
	  overflow: hidden;
	  margin-top: 40px;
  }
  /* =========== */
  .caseList{
        /* width: 840px; */
		width: 100%;
		/* background-color: red; */
        height: 400px;
        display: flex;
        flex-wrap: wrap;
		justify-content: space-around;
		/* position: relative; */
		overflow: hidden;
    }
    .caseItem{
        background-color: rgba(157, 157, 157, 0.33);
        width: 32.2%;
		margin: 5px;
		overflow: hidden;
		position: relative; 
		cursor: pointer;
		/* position: absolute; */
		/* position: fixed; */
    }
	.caseItem img {
	 width: 100%;
	  height: 100%; /* 图片高度自适应 */
	  object-fit: cover; /* 图片在容器内居中显示，覆盖整个容器 */
	  object-position: center; /* 图片在容器中居中显示 */
	}
	.caseItem:hover{
		.case_name{
			bottom: 0;
		}
	}
	.case_name{
		width: 100%;
		height: 40px;
		background-color: #e9e7e7ad;
		-webkit-line-clamp: 2;
		/* text-align: center; */
		/* z-index: 9; */
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		bottom: -40px; 
		left: 0;
		transition: bottom 0.4s cubic-bezier(0.77, 0, 0.175, 1);;
		/* display: ; */
	}
    .bigCaseItem{
        height: 52.8%;
    }
    .smallCaseItem{
        height: 42.8%;
    }

	.caseItem:nth-child(5){
	  /* background-color: red; */
	  transform: translateY(-17%);
	   /* position: absolute; */
	   /* top: 60%; */
	}
	.caseItem:nth-child(4),
	.caseItem:nth-child(6){
		transform: translateY(2.5%);
	}
  /* ================ */
/* .case-column{
	  width: 100%;
	  display: flex;
	  justify-content: center;
  }
.case-column-item{
	width: 23%;
	height: 400px;

	display: block;
	margin: 10px;

	padding: 0;
}

.case-column-big{
	width: 100%;
	height: 60%;
	background-color: #9a9a9a;
}
.case-column-small{
	width: 100%;
	height: 34%;
	background-color: #9a9a9a;
}
.column-small-top{
	margin-bottom: 20px;
}
.column-small-bottom{
	margin-top: 20px;
} */
.classic-case{
	margin-top: 30px;
}
.classic-title{
	height: 60px;
	margin: 0 40px 10px 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;
	position: relative;
	border-bottom: solid 0.5px #b3b2b26b;
}

.news-and-conpany{
	width: 100%;
	display: flex;
	/* background-color: red; */
	position: relative;
	margin-top: 50px;
	/* justify-content: center; */
	/* justify-content: space-evenly; */
	/* justify-content: space-around; */
}
.news-and-conpany ul{
	height: 285px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 15px;
	/* background-color: #9a9a9a; */
}
.news-and-conpany li{
	width: 100%;
	line-height: 25px;
	height: 25px !important;
	/* background-color:  #9a9a9a; */
	margin: 5px;
}
.conpany-dynamic {
	width: 45%;
	height: 100%;
	/* margin-left: 30px; */
	/* float: left; */
}
/* .conpany-dynamic li{
	height: 40px;
	width: 100%;
} */
.news{
	width: 45%;
	height: 100%;
	/* background-color: #9a9a9a; */
	position: absolute;
	right: 0;
}

.contact-info{
	width: 45%;
	height: 100%;
	/* background-color: aqua; */
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.follow{
	width: 45%;
	height: 100%;
	/* background-color: aqua; */
}
.contact-info span{
	display: block;
	line-height: 30px;
	color:  #c1c1c1;
}
.shade{
	position: absolute;
	/* top: 0; */
	width: 100%;
	/* background-color: red; */
	/* height: 2210px; */
	/* z-index: -2; */
	/* background-color: aqua; */
}
.shade-item{
	width: 100%;
	z-index: -1;
}
.shade-top{
	position: fixed;
	height: 100px;
	z-index: 200;
	/* background-color:  #ffffff; */
	box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}
.shade-info{
	height: 250px;
	/* background-color: #1d1d1d; */
	background-color: #375eab;
	/* position: relative; */
	position: absolute;
	bottom: 60px;
}
.shade-record{
	height: 60px;
	background-color: #1e428b;
	position: absolute;
	bottom: 0;
}
.serial-number{
	height: 25px;
	width:25px;
	display: flex;
	margin-right: 15px;
	justify-content: center;
	align-items: center;
	/* padding: 10px; */
	background-color: #faefe2;
	border-radius: 3px;
}
.conpany-dynamic ul li{
	font-size: 18px;
	display: flex;
	/* justify-content: space-between; */
	cursor: pointer;
	/* background-color: red; */
}
.news ul li{
	font-size: 18px;
	display: flex;
	/* justify-content: space-between; */
	cursor: pointer;
	/* background-color: red; */
}
.serial-text{
	/* background-color: red; */
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.serial-text span:nth-child(1){
	width: 400px;
	/* color: #302f2f; */
	overflow: hidden;
	white-space: nowrap;
    text-overflow: ellipsis;
}
.serial-text span:nth-child(2){
	color: #595959;
}
/* hover不会影响子元素，如果要设置子元素，加*号 */
.serial-text:hover *{
	color: #0006d4 !important;
}
/* .serial-text a:hover{
	color: #0006d4 !important;
} */

</style>