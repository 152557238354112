<template>
    <div class="manage">
      <div class="mange-header">
        <div style="width: 500px;">
          <el-button type="primary"  @click="addUser"><i class="el-icon-plus">&nbsp;</i>新增</el-button>
         <el-button type="warning"><i class="el-icon-download">&nbsp;</i>导出</el-button>
         <!-- <div> -->
          <el-button type="danger" @click="batchDeleteUserDialog()"><i class="el-icon-delete">&nbsp;</i>批量删除</el-button>
         <!-- </div> -->
        </div>
  
        <!-- form搜索区 -->
        <el-form style="width: 600px;display: flex;align-items: center;"  :model="userForm">
          <!-- <el-form-item> -->
            <el-form-item style="width: 120px;margin-right: 20px;">
            <el-select v-model="selectForm.userState"  placeholder="选择状态">
              <!-- <el-option label="空" value=""></el-option> -->
              <el-option label="" value=""></el-option>
              <el-option label="启用" value="1"></el-option>
              <el-option label="禁用" value="0"></el-option>
            </el-select>
          </el-form-item>
            <!-- <el-form-item style="width: 120px;margin-right: 20px;">
            <el-select v-model="selectForm.userRole"  placeholder="选择角色">
              <el-option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId"></el-option>
              </el-select>
            </el-form-item> -->
          <el-form-item>
            <el-input style="width: 180px;margin-right: 20px;" placeholder="请输入名字(模糊搜索)" v-model="selectForm.userName"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit()">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- el-table会帮我们自动遍历展示tableData数据，但要注意 每列的prop=""要与tableData相应字段对应   -->
      <el-table
      v-if="dataIsSuccess"
        :data="userListData"
        style="width: 100%"
        height="95%"
        @selection-change="handleSelectionChange"
      >
      <el-table-column
        type="selection"
        width="35">
      </el-table-column>
        <el-table-column prop="headImg" label="头像" width="100">
          <template slot-scope="scope">
            <img v-if="scope.row.headImg" class="user-head-img" :src="scope.row.headImg" />
            <div v-if="!scope.row.headImg" class="head-text">{{scope.row.userName}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="userName" :sortable=true label="姓名"  width="110"></el-table-column>
        <el-table-column prop="sex" :sortable=true label="性别"  width="80" :formatter="sexFormat"></el-table-column>
        <el-table-column prop="branchName" :sortable=true label="公司"  style="width: 10%"> </el-table-column>   
        <el-table-column prop="departmentName" :sortable=true label="部门"  style="width: 10%"> </el-table-column> 
        <el-table-column prop="userJob" :sortable=true label="职位"  style="width: 10%" ></el-table-column>
        <!-- <el-table-column prop="userType" :sortable=true label="角色"  style="width: 10%" >
          <template slot-scope="scope">
            <el-tag :type="scope.row.userType === '1' ? 'success' : ''">{{scope.row.userType === '1' ? '系统管理员' : '测试管理员'}}</el-tag>
          </template>
        </el-table-column> -->
        <el-table-column prop="userAccount"  label="账号"  style="width: 10%" >
        </el-table-column>
        <el-table-column prop="userState" :sortable=true label="状态"  style="width: 10%" >
          <template slot-scope="scope">
            <el-switch
                  v-model="scope.row.userState"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="正常"
                  inactive-text="禁用"
                  :active-value="true"
                  :inactive-value="false"
                  @change="handleSwitchState(scope.row)"
                />
          </template>
        </el-table-column>
        <el-table-column prop="userPhone" label="手机"  style="width: 10%"> </el-table-column>
             
        <el-table-column prop="getName" label="添加人"  style="width: 5%">
        </el-table-column>
        <el-table-column prop="addr" label="操作" width="200">
          <!-- slot-scope="scope" 定义了插槽的作用域时 在插槽内部就可以通过 scope 对象来访问到父组件中的数据 这个scope 对象是vue传来的  -->
          <template slot-scope="scope">
            <el-button style="" size="mini" @click="handleEdit(scope.row)" >编辑</el-button>
            <!-- <el-button type="warning" plain style="" size="mini" @click="handleSwitchState(scope.row)">{{ scope.row.userState === '1' ? '禁用' : '启用' }}</el-button> -->
            <el-button type="danger" size="mini" @click="handleDelete(scope.row)">删除</el-button>
            <el-button type="primary" size="mini" @click="updateUserRow(scope.row)" icon="el-icon-user"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex;justify-content: flex-end;align-items: center;"> 
        <span>共{{total}}条</span>
        <el-pagination background layout="prev, pager, next" :current-page="currentPage" :page-size="pageSize" :total="total" @current-change="handerPage">
      </el-pagination>
     </div>
  
  
      <!-- =================================添加用户提示框=============================== -->
      <el-dialog title="添加人员" :visible.sync="addUserDialogVisible" width="65%">
        <!-- =============================表单================================= -->
        <el-form :rules="rules" ref="form" :model="form" label-width="120px">
          <el-form-item prop="userName" label="姓 名:">
            <el-input placeholder="请输入姓名" v-model="form.userName"></el-input>
          </el-form-item>
          <el-form-item prop="userSex" label="性 别:">
            <el-select v-model="form.userSex" placeholder="请选择">
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="userPhone" label="手机号:">
            <el-input placeholder="请输手机号" v-model="form.userPhone"></el-input>
          </el-form-item>
          <el-form-item prop="roleId" label="账户类型:">
            <el-select v-model="form.roleId" placeholder="请选择">
              <el-option label="管理员" value="1"></el-option>
              <el-option label="员工" value="2"></el-option>
              <el-option label="普通用户" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="companyId" label="选择公司">
            <el-select v-model="form.companyId" placeholder="请选择">
              <el-option v-for="company in companyList" :key="company.id" :label="company.branchName" :value="company.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="departmentId" label="选择部门:">
            <el-select v-model="form.departmentId" placeholder="请选择">
              <el-option v-for="department in departmentList" :key="department.id" :label="department.departmentName" :value="department.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="userJob" label="岗位:">
            <el-input v-model="form.userJob"></el-input>
          </el-form-item>
          <el-form-item prop="userState" label="是否启用:">
            <el-select v-model="form.userState" placeholder="请选择">
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
          
          
          
          
  
          <el-form-item label="备注:">
            <el-input v-model="form.userRemarks"></el-input>
          </el-form-item>
        </el-form>
        <!-- =============================表单================================= -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="addUserDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit()">确 定</el-button>
        </span>
      </el-dialog>
      <!-- =================================添加用户提示框=============================== -->
      <!-- =================================更改用户权限提示框=============================== -->
      <el-dialog title="更改权限" :visible.sync="updateUserDialogVisible" width="500px">
        <el-form style="display: flex;flex-direction: column;">
          <el-form-item label="姓名:">
            <el-input  :value="selectUser.userName" :disabled=true></el-input>
          </el-form-item>
          <el-form-item  prop="userState" label="请选择更改的角色:">
            <el-select v-model="roleForm.roleId"  placeholder="请选择">
              <el-option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="updateUserDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitUserRole()">确 定</el-button>
        </span>
      </el-dialog>
      <!-- =================================更改用户权限提示框=============================== -->
      <!-- =================================确认删除提示框=============================== -->
      <el-dialog  title="确定删除吗？" :visible.sync="deleteUserDialogVisible" width="500px">
        <span slot="footer" class="dialog-footer">
          <el-button @click="deleteUserDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="deleteUserFun()">确 定</el-button>
        </span>
      </el-dialog>
      <!-- =================================确认删除提示框=============================== -->
          <!-- =================================批量确认删除提示框=============================== -->
          <el-dialog  title="正在执行批量删除操作，确定删除吗？" :visible.sync="batchDeleteUserDialogVisible" width="500px">
        <span slot="footer" class="dialog-footer">
          <el-button @click="batchDeleteUserDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="batchDeleteUserFun()">确 定</el-button>
        </span>
      </el-dialog>
      <!-- =================================批量确认删除提示框=============================== -->
    </div>
  </template>
  
  <script>
  // import { queryAdminUserList,addUser,switchAdminState,updateUserRole,deleteUser,batchDeleteUser} from "@/api/adminUserInfo";
  import { queryUserList,addUser,switchState,updateUserRole,deleteUser,batchDeleteUser} from "@/api/userinfo";
  import{queryAllCompany} from "@/api/company";
  import{queryAllDepartment} from "@/api/department"
  import {findRoleList} from  "@/api/role"
  import { Message } from "element-ui";
  // 使用elementui的from 配置输入规则：
  // 1.from组件为rules传入规则 liuyuan@huaxu
  // 2.在要配置规则的form-item项 加上属性：prop="region"
  export default {
    data() {
      return {
        dataIsSuccess:false,//数据是否加载好
        addUserDialogVisible: false,//添加用户弹窗
        updateUserDialogVisible:false,//更改用户角色弹窗
        deleteUserDialogVisible:false,//确认删除提示框
        batchDeleteUserDialogVisible:false,//批量删除操作
        SelectionChangeArr:[],//用户多选框
        selectForm:{
          userState:"",
          userRole:"",
          userName:""
        },//搜索条件表单
        UserRowList:{},//所有的权限列表
        total: 0, //总条数
        pageSize:10,//每页显示的条数
        currentPage:1,//当前页数
        selectUser:{},//table当前选中用户，用于保存当前用户信息
        roleForm:{
          roleId:""
        },
        roleList:{},
        form: {
          userName: "",
          userSex: "",
          userPhone: "",
          userJob: "",
          roleId: "",
          userRemarks: "",
          userState:"",
          departmentName:"",
          departmentId:"",
          branchName:"",
          companyId:""
        },
        userForm: {
          name: "",
        },
        // userReq:{
          page:{
            pageSize:10,
            currentPage:1
          },
          user:{
            userName:'',
            roleId:'',
            userState:''
          },
        // },
        userListData:{},
        companyList:[],
        departmentList:[],
        rules: {
          userName: [
            // required: true 是否必填
            { required: true, message: "请输入人员姓名" },
          ],
          userSex: [
            // required: true 是否必填
            { required: true, message: "请选择性别", trigger: "blur" },
          ],
          userPhone: [
            // required: true 是否必填
            { required: true, message: "请输入联系方式" },
          ],
          roleId: [
            // required: true 是否必填
            { required: true, message: "请选择职位", trigger: "blur" },
          ],
          departmentName: [
            // required: true 是否必填
            { required: true, message: "请选择部门", trigger: "blur" },
          ],
          companyId: [
            // required: true 是否必填
            { required: true, message: "请选择公司", trigger: "blur" },
          ],
          userState: [
            // required: true 是否必填
            { required: true, message: "请选择账号状态", trigger: "blur" },
          ],
          userJob: [
            // required: true 是否必填
            { required: true, message: "请输入人员岗位" },
          ],
        },
      };
    },
    methods: {
      // table数据格式化
      sexFormat(row){
        console.log(row)
        return row.sex === '1' ? '男' : '女';
      },
      stateFormat(row){
        return row.userState === '1' ? '正常' : '禁用';
      },
      typeFormat(row){
        return row.roleId === '1' ? '普通管理员' : '测试管理员';
      },
      // 根据table的状态，分配相应的class
      tableRowClassName({ row, rowIndex }){
        if (row.userState == "0") {
          // return "warning-row";
        }
        return "";
      },
      // 更改用户角色按钮被点击
      updateUserRow(user){
        this.selectUser = user
        this.roleForm.roleId= user.roleId == '1' ? "管理员" : user.roleId == '2' ? '员工' : '普通用户'
        this.updateUserDialogVisible = true
      },
      findRoleLisrRequest(){
              // 查询角色列表
              findRoleList().then((response) => {
          if(response.data.success){
            this.roleList = response.data.data
          }else{
            Message.error(response.data.message);
          }
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        })
      },
          // 用户修改角色确定按钮
          submitUserRole(){
        // console.log(this.selectUser.roleId+"==========="+this.roleForm.roleId)
        // if(!this.roleForm.roleId == this.selectUser.roleId){
          // console.log("用户发出修改角色请求")
          // this.selectUser.roleId = this.roleForm.roleId
          // 发出修改用户角色请求
          updateUserRole({userId:this.selectUser.userId,userRole:this.roleForm.roleId}).then((response) => {
              if(response.data.success){
                console.log
  
                // this.selectUser.roleId = this.roleForm.roleId
                Message.success(response.data.message);
                this.updateUserDialogVisible = false
                // 刷新数据
                
               this.queryUsrtListFun(this.pageSize,this.currentPage,this.selectForm.userName,this.selectForm.userRole,this.selectForm.userState);
              }else{
                this.updateUserDialogVisible = false
                Message.error(response.data.message);
              }
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
      },

      // 获取分公司列表
      initCompanyList(){
        queryAllCompany().then((res) =>{
          console.log("分公司数据",res)
          if(res.data.success){
            this.companyList = res.data.data
          }
        })
      },
      // 获取部门列表
      initDeparmentList(){
        queryAllDepartment().then((res)=>{
          console.log("部门列表",res.data)
          if(res.data.success){
            this.departmentList = res.data.data
          }
        })
      },
      // 复选框选中时触发
      handleSelectionChange(val){
        this.SelectionChangeArr = val;
      },
          // 点击新增用户
      addUser() {
        // 对表单移除内容和校验结果
        if (this.$refs.form !== undefined) {
          this.$refs.form.resetFields();
        }
        // 弹出提示框
        this.addUserDialogVisible = true;
      },
      // 用户提交表单，先判断是否全部填写完,如果通过规则valid = true
      submit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            // 验证成功，关闭弹窗
            this.addUserDialogVisible = false;

            console.log("this.form",this.form)
            addUser(this.form).then((response) => {
              if(response.data.success){
                Message.success(response.data.message);
              }else{
                Message.error(response.data.message);
              }
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
          }
        });
      },
  
      queryUsrtListFun(pageSize,pageNum,userName,roleId,userState){
            // 分页搜索用户列表操作
            // { pageSize,pageNum,userName,roleId,userState}
            queryUserList({ pageSize,pageNum,userName,roleId,userState})
      .then((response) => {
        if(response.data.success){
          console.log(response.data)
          console.log("管理员列表")
          this.dataIsSuccess = true
          this.total = response.data.data.totalNum
          this.userListData = response.data.data.resultList

          // 为数组添加一个userStateBoolean布尔值数据，用于switch开关的控制
          this.userListData.forEach(user => {
            user.userState === '1' ? user.userState = true : user.userState = false
          // user.userStateBoolean = user.userState === '1';
      });

        }else{
          Cookie.remove("token");
          Message.error("失败，请刷新浏览器");
        }
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
      },
      // 编辑按钮点击时执行
      handleEdit(row) {
        // console.log(row)
      },
      // 删除按钮点击时执行
      handleDelete(row){
        this.selectUser = row
        this.deleteUserDialogVisible = true
        console.log("好像点了确定")
      },
  
  
          // 删除单个用户确认按钮
          deleteUserFun(){
            console.log("准备删除用户",this.selectUser.userAccount)
            deleteUser({userId:this.selectUser.userId}).then((response) =>{
              if(response.data.success){
                  Message.success(response.data.message);
                    // 刷新数据
                    this.queryUsrtListFun(this.pageSize,this.currentPage,this.selectForm.userName,this.selectForm.userRole,this.selectForm.userState);
              }else{
                  Message.error(response.data.message);
              }
            })
            .catch((error) => {
                console.error(error);
        });
        this.deleteUserDialogVisible = false
      },
  
      batchDeleteUserDialog(){
        if(this.getSelectUsersId.length == 0){
          Message.error("还未选择用户");
        }else{
          this.batchDeleteUserDialogVisible = true
        }
      },
  
      // 批量删除用户
      batchDeleteUserFun(){
      // else{
        console.log("this.getSelectUsersId",this.getSelectUsersId)
          batchDeleteUser(this.getSelectUsersId).then((response) =>{
          if(response.data.success){
                  Message.success(response.data.message);
                // 刷新数据
                this.queryUsrtListFun(this.pageSize,this.currentPage,this.selectForm.userName,this.selectForm.userRole,this.selectForm.userState);
              }else{
                Message.error(response.data.message);
              }
        })
        this.batchDeleteUserDialogVisible = false
       
      },
  
      // 点击切换用户状态
      handleSwitchState(row){
        console.log(row)
        // 切换用户状态前把userState从布尔值换为0或1
        switchState({account:row.account,userState:row.userState ? '1' : '0'}).then((response) => {
          if(response.data.success){
            console.log(response.data)
            Message.success(response.data.message);
      }else{
        Message.error(response.data.message);
      }
  
        })
      },
      // 分页点击时执行 val为页码
      handerPage(val) {
        this.queryUsrtListFun(this.pageSize,val,this.selectForm.userName,this.selectForm.userRole,this.selectForm.userState);
        console.log(val);
      },
      // 搜索
      onSubmit() {
        console.log("开始条件查询")
        console.log(this.selectForm)
        this.queryUsrtListFun(this.pageSize,this.currentPage,this.selectForm.userName,this.selectForm.userRole,this.selectForm.userState);
      },
    },
    mounted(){
      console.log(this.userListData)
      // if(this.userListData == null){
        this.queryUsrtListFun(this.pageSize,this.currentPage,this.selectForm.userName,this.selectForm.userRole,this.selectForm.userState);
        // 查询角色列表
        this.findRoleLisrRequest()
        this.initCompanyList();
        this.initDeparmentList();
      // }
      
    },
    watch:{
      'form.companyId'(newVal,oldVal){
        console.log("公司发生变化：",newVal)
        this.companyList.forEach(item =>{
          if(item.id == newVal){
            this.form.branchName = item.branchName
            return;
          }
        })
        console.log("点击的是",this.form.branchName)

      },
      'form.departmentId'(newVal,oldVal){
        console.log("部门发生变化：",newVal)
        this.departmentList.forEach(item =>{
          if(item.id == newVal){
            this.form.departmentName = item.departmentName
            return;
          }
        })
        console.log("点击的是",this.form.departmentName)
      }
    },
    computed:{
      getSelectUsersId(){
        return this.SelectionChangeArr.map(item =>{
          return item.userId
        })
      },
        // 动态返回一个计算属性
  userStateBoolean(row) {
    return (row) => ({
      get() {
        return row.userState === '1';
      },
      set(value) {
        row.userState = value ? '1' : '0';
      }
    });
  }
    }
  };
  </script>
  
  <style>
  .user-head-img{
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }
  .manage {
    height: 90%;
    width: 100%;
  }
  .manage .cell {
    display: flex;
    justify-content: center;
  }
  .mange-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .el-table .warning-row {
    background: rgb(254, 249, 249);
  }
  .el-table .success-row {
    background: #f0f9eb;
  }
  .el-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .el-form-item {
    width: 48%;
    margin-top: 10px;
  }
  .el-table__row {
    font-size: 16px;
  }
  .el-pagination {
    margin-left: 50px;
    text-align: right;
  }
  .cell {
    text-align: center;
  }
  .el-form-item {
    width: auto;
  }
  .head-text{
    height: 50px;
    width: 50px;
    border-radius: 5px;
    font-size: 15px;
			display: flex;
			align-items: center;
			justify-content: space-around;
			background-color: #3c9cff;
			color: #fff;
  }
  </style>