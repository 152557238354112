import http from "../utils/request"

// 根据caseId查询过程列表
export function queryProcessList(data){
    return http({
        method:'GET',
        url:'/process/process_list',
        params:data
})
}

// 根据项目id新建过程
export function addProcess(data = {}){
    return http({
        method:'POST',
        url:'/process/add_process',
        data
})
}