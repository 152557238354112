<template>
  <div style="">
    <div class="head-menu" ref="menu">
      <ul>
        <li
          @click="selectItem('CertificateList', 'CertificateList')"
          :class="{ 'menu-select': selectedRouteName === 'CertificateList' }"
          ref="CertificateList"
        >
          证书列表
        </li>
        <!-- <el-badge :value="3"> -->
          <li
            @click="selectItem('AT_ApprovalList', 'AT_ApprovalList')"
            :class="{ 'menu-select': selectedRouteName === 'AT_ApprovalList' }"
            ref="AT_ApprovalList"
          >
            审批中
          </li>
        <!-- </el-badge> -->
        <!-- <el-badge :value="3"> -->
          <li
            @click="selectItem('AT_ReturnedList', 'AT_ReturnedList')"
            :class="{ 'menu-select': selectedRouteName === 'AT_ReturnedList' }"
            ref="AT_ReturnedList"
          >
            待退还
          </li>
        <!-- </el-badge> -->
        <li
          @click="selectItem('AT_AllApply', 'AT_AllApply')"
          :class="{ 'menu-select': selectedRouteName === 'AT_AllApply' }"
          ref="AT_AllApply"
        >
          全部申请
        </li>
        <div class="indicator" :style="{ left: indicatorLeft + 'px' }"></div>
      </ul>
    </div>

    <!-- <el-tabs v-model="activeName" @tab-click="handleClick" class="custom-tabs">
    <el-tab-pane label="证书列表" name="first">用户管理</el-tab-pane>
    <el-tab-pane label="审批中" name="second">配置管理</el-tab-pane>
    <el-tab-pane label="待退还" name="third">角色管理</el-tab-pane>
    <el-tab-pane label="全部申请" name="fourth">定时任务补偿</el-tab-pane>
  </el-tabs> -->
    <router-view></router-view>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      selectedRouteName:  this.$route.name,
      indicatorLeft: 0,
      activeName: 'second'
    };
  },
  methods: {
    handleClick(tab, event) {
        console.log(tab, event);
      },
      sliderMove(refName){
      const menuRect = this.$refs.menu.getBoundingClientRect();
      const liRect = this.$refs[refName].getBoundingClientRect();
      this.indicatorLeft = liRect.left - menuRect.left + (liRect.right-liRect.left)/2 -15;
    },
    selectItem(refName, routeName) {
      // event为事件对象
      this.selectedRouteName = routeName; 
      this.$router.push({ name: routeName });
      this.sliderMove(refName);
    },

  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(()=>{
        // routeName与refName是一致的
        this.sliderMove(this.selectedRouteName)
      },500)
      //再调用一次，防止某些组件加载较慢
      setTimeout(()=>{
        // routeName与refName是一致的
        this.sliderMove(this.selectedRouteName)
      },2500)
    });
  },
  
};
</script>
  
  <style scoped>
  .head-menu {
    width: 100%;
    height: 50px;
  }
  .head-menu ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #8685852a;
    position: relative;
  }
  .head-menu li {
    width: 88px;
    /* background-color: aqua; */
    text-align: center;
    list-style: none;
    cursor: pointer;
    font-size: 17px;
  }
  .menu-select {
    /* transform: 3s; */
    font-weight: bold;
    font-size: 22px !important;
  }
  .indicator {
    position: absolute;
    bottom: 0;
    width: 30px;
    height: 3px;
    background-color: #000;
    transition: left 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  }
  

/* .custom-tabs .el-tabs__nav{
  width: 100% !important;
  display: flex !important;
  justify-content: space-around !important;
} */
</style>